import React, { useContext } from 'react'
import { getAuth, signInWithRedirect, signInWithPopup } from "firebase/auth";
import { GetFireBaseProvider, SetAppStatus, SetAppErrors } from './GlobalContexts';

/**
 * Trigger a login through Firebase
 * 
 * @param {undefined} NotApplicable
 * 
 * ```
 * 
 * <Login></Login>    
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
 */

export default function Login() {

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const Provider = useContext(GetFireBaseProvider);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  // This function triggers a redirect signin > AuthProvider.js will handle the results
  // https://firebase.google.com/docs/auth/web/microsoft-oauth
  //------------------------------------------------------

    function SignIn(){

      // If Development > Pop-up
      if (process.env.REACT_APP_FIREBASE_AUTH_DOMAIN.includes('firebaseapp.com')) {

        return signInWithPopup(auth, Provider)
        .catch((error) =>{
  
          setAppStatus('failed');
          setAppErrors(error.message);
  
        });
        
      }

      // If Production > Redirect
      signInWithRedirect(auth, Provider)
      .catch((error) =>{

        setAppStatus('failed');
        setAppErrors(error.message);

      });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <button className='Primary-Button' onClick={SignIn}>Sign In</button>
    )

  //------------------------------------------------------
}