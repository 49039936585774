//================================================================
//  Component: History.js
//================================================================

//  Purpose: Show admin auditHistory table 

//  Properties:
//    - icon = <HTML> - <img> tag containing icon 
//    - title = <String> or <HTML> title of the header

//  PageHeader:
//    <PageHeader
//      icon={<img src={CreateProjectIcon} alt='Create Project Icon'></img>}
//      title={'Create Project'}
//    ></PageHeader>   

//================================================================

// Libraries
import React from 'react';

// Contexts

//  Functions
import ConvertDate from '../../../Library/ConvertDate';

// CSS
import '../../../../src/Pages/PreQualifications/PreQualifications.css'
import '../../../../src/index.css';

//images


export default function PrequalificationHistory({
  formData
}) {

  // ----------------------------------------
  //  HTML 
  // ----------------------------------------

  return (
    <div className='PrequalificationHistory-Container'>
      {
        formData.prequalificationDoc?.audithistory?.length > 0 ? 
        (
          <table className='Table-Container shadow-sm mb-4'>
          <thead>
            <tr className='text-[14px] tracking-wider bg-slate-200'>
              <th>ACTION DATE</th>
              <th>ACTIONED BY</th>
              <th>MESSAGE</th>
              <th>ACTION TYPE</th>
            </tr>
          </thead>
          <tbody>
            {
              formData.prequalificationDoc?.audithistory?.map((historyItem, index) => (
                <tr key={index}>
                  <td>{ConvertDate(historyItem?.actiondate)}</td>
                  <td>{historyItem.actionedby}<br></br>{historyItem.actionedbybusinessunit}</td>
                  <td>{historyItem.message}</td>
                  <td>{historyItem.type}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        )
        :
        (
          <div>
            No records found.
          </div>
        )
      }
    </div>
  )
}
