//================================================================
//  Component: Permissions
//================================================================

//  Purpose: This page allows global administrators to manage access for other global administrators

//  Example:
//    <Permissions></Permissions>    

//================================================================


//Libraries
import React, { useReducer, useContext, useEffect } from 'react';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components
import InviteGlobalAdminModal from './InviteGlobalAdminModal';
import RemoveGlobalAdminModal from './RemoveGlobalAdminModal';

//Functions
import QueryListener from '../../../../Library/QueryListener';
import ConvertDate from '../../../../Library/ConvertDate';

//Images
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';
import DefaultUser from '../../../../Components/Images/Icon_DefaultUser_Black.svg';
import DeleteIcon from '../../../../Components/Images/Icon_Delete_Red.svg';
import DeleteIconDisabled from '../../../../Components/Images/Icon_Delete_Grey.svg';

//CSS
import '../../AppSettings.css';


export default function Permissions() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to save the page states
    const [pageState, setPageState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        // Used to determine the state of the modal > 'pending', 'onload', 'error-fatal'
        'pageStatus': 'pending',

        // Used to store all the global admins
        'globalAdmins': [],

        // Used to store error messages for the page
        'errorMessage': '',

        // Invite Admin Modal
        'inviteModalVisible': false,

        // Remove Admin Modal
        'removeModalAdmin': undefined, // Undefined object will hide the RemoveAdminModal
      }
  );

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //When the page loads > get all the global administrators
    useEffect(() => {

      if (pageState.pageStatus !== 'pending') return;
      
      // Set up query listener for getting all global administrators 
      function onLoad(documents){

        // Update globalAdmins > Set page status to 'success
        setPageState({
          'globalAdmins': documents,
          'pageStatus': 'onload'
        });

      }
    
      function onChange(documents){
  
        // Update globalAdmins > Set page status to 'success
        setPageState({
          'globalAdmins': documents,
          'pageStatus': 'onload'
        });

      }
    
      function onError(error){

        setPageState({
          'pageStatus': 'error-fatal',
          'errorMessage': `Failed to get user information, unable to create the document listener error: ${error}`
        });

      }
    
      const unsubscribe = QueryListener('users', [
        ['roles.globalAdmin', '==', true],
      ], onLoad, onChange, onError)
    
      return () =>{
        unsubscribe();
      };
    
    // eslint-disable-next-line
    }, [pageState]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------
        
    //====================================================
    //  Pending
    //====================================================
    
    if (pageState.pageStatus === 'pending') {
      return (
          <div className='Page-Body-Container'>
              <div className='flex justify-center'>
                  <img alt='loading-circle-icon' src={LoadingIcon}></img>
              </div>
          </div>
      )
    }

    //====================================================
    //  Error-Fatal
    //====================================================

    else if (pageState.pageStatus === 'error-fatal') {
      return (
          <div className='Page-Body-Container'>
              <div className='flex flex-col text-center gap-2 my-2'>
                  <h4>Oops something went wrong!</h4>
                  
                  <p className='leading-7 mb-2'>
                      An unknown error has occurred while loading this page.
                      <br></br>
                      If the error persists, please speak reach out to your support team. 
                  </p>
  
                  <div>
                      <button className='Primary-Button' onClick={() => window.location.reload() }>Try Again</button>
                  </div>
              </div>
          </div>
      )
    }

    //====================================================
    //  Onload
    //====================================================
    
    else if (pageState.pageStatus === 'onload') {
      return (
        <div className='Page-Body-Container' >
          <div className='flex flex-col'>
  
              {/* Pane Header */}
              <div className='flex flex-row justify-between items-center mb-3'>
  
                <label className='text-[18px] font-medium m-0'>Manage Access</label>
                <button className='Primary-Button' onClick={() => setPageState({ 'inviteModalVisible': true })} >+ Invite User</button>
  
                {/* Invite User Modal */}
                <InviteGlobalAdminModal 
                  modalState={pageState}
                  setModalState={setPageState} 
                ></InviteGlobalAdminModal> 
                
              </div>
  
              {/* User Table */}
              <table className='Table-Container shadow-sm mb-4'>
                <colgroup>
                  <col span='1' style={{width: '40%'}}></col>
                  <col span='1' style={{width: '45%'}}></col>
                  <col span='1' style={{width: '15%'}}></col>
                </colgroup>
                <thead>
                  <tr className='text-[14px] tracking-wider bg-slate-200'>
                      <th>USER</th>
                      <th>DATE ADDED</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                {
                  pageState?.globalAdmins?.map((admin, index) => (
                    <tr key={index} className='border-b border-[#E0E0E0] break-all'>

                      {/* User */}
                      <td className='break-all'> 
                          <div className='GlobalAdmins-User-Row'>

                          {/* Icon */}
                          <div className='GlobalAdmins-User-Icon' >
                            <img src={DefaultUser} alt='user-profile'></img>
                          </div>

                          {/* Name */}
                          <div className='GlobalAdmins-User-Name font-medium capitalize'>
                            {admin?.givenname} {admin?.surname}
                          </div>

                          {/* Email */}
                          <div className='GlobalAdmins-User-Email'>
                            {admin?.emailaddress}
                          </div>

                        </div>
                      </td>

                      {/* Date Added */}
                      <td> 
                        {ConvertDate(admin?.created)} 
                      </td> 

                      {/* Delete Icon */}
                      <td>
                        {

                          // Prevent self deletion
                          (admin?.emailaddress === getUser.emailaddress) ? (
                            <img
                              className='w-[30px] cursor-not-allowed'
                              src={DeleteIconDisabled}
                              alt='Delete-Icon'
                            ></img>
                          ) : (
                            <img
                              className='w-[30px] cursor-pointer'
                              onClick={() => { setPageState({ 'removeModalAdmin': admin }) }}
                              src={DeleteIcon}
                              alt='Delete-Icon'
                            ></img>
                          )
                        }
                      </td>

                    </tr>
                  ))
                }
                </tbody>
              </table>

              {/* Remove Admin Modal */}
              <RemoveGlobalAdminModal 
                modalState={pageState}
                setModalState={setPageState} 
              ></RemoveGlobalAdminModal>

          </div>
        </div>
      )

    }

}
