//================================================================
//  Application: Events App 
//================================================================

//  Product Owner: Scott Wrigley
//  Created 11/04/2022

//================================================================

//Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Pages
import AppSettings from './Pages/AppSettings/AppSettings';
import CreateProject from './Pages/CreateProject/CreateProject.js';
import Home from './Pages/Home/Home.js';
import NotFound from './Pages/NotFound/NotFound.js';
import PreQualifications from './Pages/PreQualifications/PreQualifications';
import Worker from './Pages/PreQualifications/Pages/Worker';
import ProjectSettings from './Pages/ProjectSettings/ProjectSettings';

//Components
import NavHeader from './Components/NavHeader/NavHeader.js';
import Footer from './Components/Footer/Footer.js';

//Styles
import './App.css'

// eslint-disable-next-line
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return (
      <div className='App-Container'>

        {/* Nav */}
        <div className='App-Container-Nav'>
          <NavHeader></NavHeader>
        </div>

        {/* Body > Contains all pages in the app  */}
        <div className='App-Container-Body'>

          <Routes>
            <Route path='/appsettings' element={<AppSettings />}></Route>
            <Route path='/createproject' element={<CreateProject />}></Route>           
            <Route path='/home' element={<Home/>}/>
            <Route path='/' element={<Home/>}/>
            <Route path='*' element={<NotFound />}></Route>
            <Route path='/prequalifications' element={<PreQualifications/>}/>
            <Route path='/prequalifications/worker' element={<Worker/>}/>
            <Route path='/projectsettings' element={<ProjectSettings/>}/>
          </Routes>

          <Footer></Footer>

        </div>

      </div>
    )

    //------------------------------------------------------
}