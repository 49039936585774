//================================================================
//  Component: Overview
//================================================================

//  Purpose: This is the standard for creating a component

//  Properties:
//    - name = {A string, this is the persons name}
//    - message = {A string, a short message}

//  Example:
//    <Overview
//      name={"Mark Bennett"}
//      message={"stop breaking stuff!"}
//    ></Overview>    

//================================================================


//Libraries
import React, { useEffect, useReducer, useState, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../../Library/FirebaseConfig';
import { doc, updateDoc, arrayUnion, arrayRemove, getFirestore } from 'firebase/firestore';

//Contexts
import { GetUser, SetError, GetProjectSelector } from '../../../../Library/GlobalContexts';

//Functions
import getDocument from '../../../../Library/GetDocument';
import writeDocument from '../../../../Library/WriteDocument';
import queryDocument from '../../../../Library/QueryDocument';
import QueryListener from '../../../../Library/QueryListener';
import writeAuditLog from '../../../../Library/WriteAuditLog';
import deleteDocument from '../../../../Library/DeleteDocument';

//Components
import UpdateNDA from './UpdateNDA';

//Images
import IconAddGrey from '../../../../Components/Images/Icon_Add_Grey.svg';
import IconAddTeal from '../../../../Components/Images/Icon_Add_Teal.svg';
import IconDeleteRed from '../../../../Components/Images/Icon_Delete_Red.svg';
import IconInfoRequired from '../../../../Components/Images/Icon_InfoRequired_Teal.svg';

//CSS
import '../../ProjectSettings.css';


export default function Overview({
  pageStatus,
  setPageStatus,
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);
    const setError = useContext(SetError);
    const getProjectSelector = useContext(GetProjectSelector);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Show an error message next to the save button
    const [formError, setFormError] = useState(''); 

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'name': '',
        'description': '',
        'descriptionValid': true,

        // Project Owner States
        'newOwner': '',
        'newOwnerGivenname': '',
        'newOwnerSurname': '',
        'newOwnerStatus': 'onload', // 'onload', 'new-user'
        'newOwnerMessage': '',
        'owners': [],

        'location': '',
        'locationValid': true,
        // Benno ~ If we get time, I will move this to Firestore
        'passOfficeData': [
          {
            'state': 'NSW',
            'offices': [
              'Glenbrook',
              'Orchard Hills',
              'Albatross',
              'Wagga',
              'Kapooka',
              'Holsworthy',
              'Defence Plaza Sydney',
              'Kuttabul',
              'Williamtown',
              'Singleton',
              'Richmond',
            ]
          },
          {
            'state': 'VIC',
            'offices': [
              'Simpson',
              'Victoria Barracks Melbourne',
              'Puckapunyall',
              'East Sale',
              'Cerberus',
              'Williams',
              'Bandiana',
            ]
          },
          {
            'state': 'TAS',
            'offices': [
              'Derwent',
            ]
          },
          {
            'state': 'QLD',
            'offices': [
              'Gallipoli',
              'Oakey',
              'Amberley',
              'Kokoda',
              'Townsville',
              'Lavarack',
              'Cairns',

            ]
          },
          {
            'state': 'WA',
            'offices': [
              'Irwin',
              'Stirling',
              'Stirling Gangway',
              'Pearce',
              'Taylor',
            ]
          },
          {
            'state': 'NT',
            'offices': [
              'Robertson',
              'Larrakeyah',
              'Darwin National Pass Office',
              'Tindal',
            ]
          },
          {
            'state': 'SA',
            'offices': [
              'Edinburgh',
            ]
          },
          {
            'state': 'ACT',
            'offices': [
              'Russel',
              'Campbell Park',
              'RMC',
            ]
          },
        ],
        'passOfficeState': '',
        'passOfficeStateValid': true,
        'passOfficeNameFilter': [],
        'passOfficeName': '',
        'passOfficeNameValid': true,
        'passOfficeNew': '',
        'passOfficeNewValid': true,
        'dataLocation': '',
        'dataLocationValid': true,
        'disclosureFileId': '',
        'disclosureFileName': '',
        'disclosureFileUrl': '',
        'disclosureStatus': '',
        'disclosureValid': true,
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function handleChangeOwner(value) {

      if (value.length === 0) {

        return setFormData({
          'newOwner': value,
          'newOwnerAllowAdd': false
        });

      }

      // Validate emailaddress
      if (/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)) {

        return setFormData({
          'newOwner': value,
          'newOwnerStatus': 'onload',
          'newOwnerMessage': '',
          'newOwnerAllowAdd': true,
        });

      } 

      // invalid emailaddress
      setFormData({
        'newOwner': value,
        'newOwnerStatus': 'onload',
        'newOwnerMessage': '',
        'newOwnerAllowAdd': false,
      });

    }

    // Adds a Project Owner to 'formData.owners' array
    function handleAddOwner() { 

      // Validate domain --> We only allow lendlease.com email addresses
      if ('lendlease.com' !== formData.newOwner.split('@')[1]) {

        return setFormData({
          'newOwnerMessage': `Only lendlease.com email addresses are allowed.`
        });

      };

      // Check for duplicates
      const owner = formData.owners.filter((object) => object.emailaddress === formData.newOwner);
      if (owner.length > 0) {

        if (owner[0].type !== 'deleted') {

          return setFormData({
            'newOwnerMessage': `Email address already exists.`
          });

        };

      };

      // Check if user exists in Firestore 'users' collection
      return getDocument('users', formData.newOwner).then(() => {

        // Sanity check if the user was added/removed from the list
        let foundUser = false;
        formData.owners.forEach((user, index) => {

          if (user.emailaddress === formData.newOwner) {

            formData.owners[index] = {
              'emailaddress': formData.newOwner,
              'type': 'existing'
            };
            foundUser = true;

          }

        });

        // User wasn't added/removed from the list, append the 'owners' array
        if (foundUser === false) {

          formData.owners.push({
            'emailaddress': formData.newOwner,
            'type': 'existing'
          });

        };

        formData.newOwner = '';
        formData.newOwnerMessage = '';
        formData.newOwnerAllowAdd = false;
        formData.newOwnerStatus = 'onload';
        setFormData(formData);

      }).catch(() => {

        formData.newOwnerStatus = 'new-user';
        formData.newOwnerMessage = '';
        formData.newOwnerAllowAdd = false;
        setFormData(formData);

      });

    };

    // Deletes a Project Owner from 'formData.owners' array
    function handleDeleteOwner(object, index) { 

      // Skip new users
      if (object.type === 'new') {

        formData.owners.splice(index, 1)
        setFormData(formData);
        return;
      
      }

      // We need to check if the user already has the role!
      getDocument('users', object.emailaddress).then((document) => {

        // Has the role --> Add to the deleted list
        if (document.projectAdmin.includes(getProjectSelector.selectedProject?.projectid)) {

          formData.owners[index] = {
            'emailaddress': object.emailaddress,
            'type': 'deleted',
          };

        // Doesn't have the role --> Ignore
        } else {

          formData.owners.splice(index, 1);

        }

        setFormData(formData);

      }).catch(() => {

        setFormData({'owners': formData.owners.splice(index, 1)})
        return;

      });

    };

    // Handle submit
    function onSubmit(e){

      e.preventDefault();

      // OnSubmit Validation of all required fields
      let preventSubmit = false;

      const ownerCheck = formData.owners.filter((owner) => owner.type !== 'deleted');
      if (ownerCheck.length === 0) {

        preventSubmit = true;
        formData.newOwnerMessage = 'At least one project owner is required.';

      } else {

        formData.newOwnerMessage = '';

      };

      if (formData.location.length === 0) {

        preventSubmit = true;
        formData.locationValid = false;
        
      } else {

        formData.locationValid = true;

      };

      if (formData.passOfficeState.length === 0) {

        preventSubmit = true;
        formData.passOfficeStateValid = false;
        
      } else {

        formData.passOfficeStateValid = true;

      };      

      // Nominated Pass Office * ~ Requires one of two fields to be populated
      //  Option 1 ~ State & Office selected from dropdowns
      //  Option 2 ~ Office manually typed in
      let nominatedPassState = '';
      let nominatedPassOffice = '';
      if (formData.passOfficeName.length > 0) {

        formData.passOfficeNameValid = true;
        formData.passOfficeNewValid = true;
        nominatedPassState = formData.passOfficeState;
        nominatedPassOffice = formData.passOfficeName;

      } else if (formData.passOfficeNew.length > 0) {

        formData.passOfficeNameValid = true;
        formData.passOfficeNewValid = true;
        nominatedPassState = 'N/A';
        nominatedPassOffice = '';

      } else {

        preventSubmit = true;
        formData.passOfficeNameValid = false;
        formData.passOfficeNewValid = false;

      }

      if (formData.dataLocation.length === 0) {

        preventSubmit = true;
        formData.dataLocationValid = false;
        
      } else {

        formData.dataLocationValid = true;

      };

      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) {

        return setFormError('Complete the form before proceeding');

      }

      setPageStatus('pending');

      // ================================================================
      // Passed all validation checks --> write project & user documents
      //  - Create an new project document 
      //  - Create new user documents
      //  - Update 'projectAdmin' array (Ref to arrayUnion's in Firebase documentation)
      // ================================================================

      // ----------------------------------------------------------
      // 0. Prepare
      // ----------------------------------------------------------

      // Firestore Client ~ Allows the update of elements in an array
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const projectId = getProjectSelector.selectedProject?.projectid;
      const newUsers = [];
      const existingUsers = [];
      const deletedUsers = [];
      const auditingPromises = [];

      // ----------------------------------------------------------
      // 1. Extract data from 'owners' array
      // ----------------------------------------------------------

      formData.owners.forEach((object) => {

        if (object.type === 'new') {

          // Added a check to ensure Lendlease users do NOT lose their LLUser role
          let lendleaseUser = false;
          if (object.emailaddress.endsWith('@lendlease.com')) {

            lendleaseUser = true;

          }

          // Define user document
          newUsers.push({
            'emailaddress': object.emailaddress,
            'givenname': object.givenname,
            'surname': object.surname,
            'created': new Date(),
            'projectAdmin': [
              projectId
            ],
            'organisationid': '',
            'organisationname': 'Lendlease',
            'orgAdmin':[],
            'projects': [],
            'roles': {
              'globalAdmin': false,
              'lendleaseUser': lendleaseUser,
            },
            'status': 'active'
          });

          // Auditing
          const message = `${getUser.emailaddress} -- Created new project admin ${object.emailaddress} for project ${formData.name}.`;
          const types = [
            'project history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'overview',
            getUser.emailaddress,
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );

        }

        if (object.type === 'existing') {

          existingUsers.push(object.emailaddress);

          // Auditing
          const message = `${getUser.emailaddress} -- Added project admin ${object.emailaddress} to project ${formData.name}.`;
          const types = [
            'project history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'overview',
            getUser.emailaddress,
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );

        };

        if (object.type === 'deleted') {

          deletedUsers.push(object.emailaddress);

          // Auditing
          const message = `${getUser.emailaddress} -- Removed project admin ${object.emailaddress} from project ${formData.name}.`;
          const types = [
            'project history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'overview',
            getUser.emailaddress,
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );

        };
        
      });

      // ----------------------------------------------------------
      // 2. Update doc in 'projects' collection
      // ----------------------------------------------------------

      const projectDoc = {
        'description': formData.description,
        'state': formData.location,
        'nominatedpassstate': nominatedPassState,
        'nominatedpassoffice': nominatedPassOffice,
        'nominatedpassofficenew': formData.passOfficeNew,
        'disclosure': formData.disclosureFileId,
        'datalocation': formData.dataLocation,
      };
      const projectPromise = writeDocument('projects', projectId, projectDoc, true);

      // Auditing
      const message = `${getUser.emailaddress} -- Updated project ${formData.name}.`;
      const types = [
        'project history'
      ];
      const references = [
        formData.name,
        getUser.emailaddress,
        'overview',
      ];
      auditingPromises.push(
        writeAuditLog(getUser.emailaddress, message, types, references)
      );

      // ----------------------------------------------------------
      // 3. Write 'new users' to 'users' collection
      // ----------------------------------------------------------

      const newUserPromises = [];
      newUsers.forEach((document) => {

        newUserPromises.push(
          writeDocument('users', document.emailaddress, document, false)
        )

      });

      // ----------------------------------------------------------
      // 4. Merge 'existing user' roles in their 'users' collection
      // ----------------------------------------------------------

      const existingUserPromises = [];
      existingUsers.forEach((emailaddress) => {

        // https://cloud.google.com/firestore/docs/manage-data/add-data#update_elements_in_an_array
        const docRef = doc(db, 'users', emailaddress);

        existingUserPromises.push(
          updateDoc(docRef, {
            projectAdmin: arrayUnion(projectId)
          })
        );

      });

      // ----------------------------------------------------------
      // 5. Delete 'existing user' role in their 'users' collection
      // ----------------------------------------------------------

      const deletedUserPromises = [];
      deletedUsers.forEach((emailaddress) => {

        // https://cloud.google.com/firestore/docs/manage-data/add-data#update_elements_in_an_array
        const docRef = doc(db, 'users', emailaddress);

        deletedUserPromises.push(
          updateDoc(docRef, {
            projectAdmin: arrayRemove(projectId)
          })
        );

      });

      // ----------------------------------------------------------
      // 6. Settle promises & route user to 'prequalifications' page
      // ---------------------------------------------------------

      Promise.all([projectPromise, ...newUserPromises, ...existingUserPromises, ...deletedUserPromises, ...auditingPromises]).then(() => {

        if (formData.disclosureStatus === 'existing') return setPageStatus('onload');

        // Find all uploaded files and delete them
        if (formData.disclosureFileId.length === 0) {

          return queryDocument('files', [
            ['requesttype', '==', 'nondisclosure'],
            ['projectid', '==', getProjectSelector.selectedProject.projectid],
            ['status', '==', 'transferred'],
          ]).then((docs) => {

            const deletedFiles = [];
            docs.forEach((doc) => {

              deletedFiles.push(
                deleteDocument('files', doc.fileid),
              );

            });

            return Promise.all(deletedFiles).then(() => {

              setPageStatus('onload');

            });

          });

        }

        // ----------------------------------------------------------
        // 6. Write doc to files collection (for NDA)
        // ----------------------------------------------------------
  
        const fileDoc = {
          fileid: formData.disclosureFileId,
          filename: formData.disclosureFileName,
          requesttype: 'nondisclosure',
          folderpath: `${projectId}/${formData.disclosureFileId}`,
          projectid: projectId,
          organisationid: '',
          createdby: getUser.emailaddress,
          workeremail: '',
          status: 'pending transfer', 
          description: '',
          dateobtained: new Date(),
          expirydate: '',
          signedurl: formData.disclosureFileUrl,
          signedurlexpirydate: ''
        }

        // Write to 'files' collection --> reload of page is handled by 'files' useEffect
        return writeDocument('files', formData.disclosureFileId, fileDoc, true).then(() => {

          setPageStatus('onload');

        });

      }).catch(() => {

        setPageStatus('error');
        setFormError('Unable to save changes, please try again.');
    
      });

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onLoad
    //  1. Get projectid from context
    //  2. Find all projectAdmins
    //  3. Extract default field values
    //  4. Add to useReducer
    useEffect(() => {

      // Conditions
      if (getProjectSelector === undefined) return;
      if (getProjectSelector.selectedProject === undefined) return;
      if (getProjectSelector.projects.length === 0) return;
    
      queryDocument('users', [
        ['projectAdmin', 'array-contains', getProjectSelector.selectedProject.projectid],
        ['status', '==', 'active']
      ]).then((users) => {

        // Reset array
        formData.owners = [];

        // Find all 'projectAdmins'
        users.forEach((user) => {

          formData.owners.push({
            'emailaddress': user.emailaddress,
            'type': 'existing',
            'givenname': user.givenname,
            'surname': user.surname,
          });

        });

        setFormData({
          // Update all field values
          'name': getProjectSelector.selectedProject.projectname,
          'description': getProjectSelector.selectedProject.description,
          'location': getProjectSelector.selectedProject.state,
          'passOfficeState': getProjectSelector.selectedProject.nominatedpassstate,
          'passOfficeName': getProjectSelector.selectedProject.nominatedpassoffice,
          'passOfficeNameFilter': [
            getProjectSelector.selectedProject.nominatedpassoffice
          ],
          'passOfficeNew': getProjectSelector.selectedProject.nominatedpassofficenew,
          'dataLocation': getProjectSelector.selectedProject.datalocation,
          'owners': formData.owners,
        });

        setPageStatus('onload');

      }).catch(() => {

        // Benno ~ This error shouldn't be possible, so I didnt waste time on a detailed error message & state.
        setPageStatus('accessdenied');

      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProjectSelector]);


    // onLoad
    //  1. Get projectid from context
    //  2. Find Files
    //  3. Update useReducer
    useEffect(() => {

      // Conditions
      if (getProjectSelector === undefined) return;
      if (getProjectSelector.selectedProject === undefined) return;
      if (getProjectSelector.projects.length === 0) return;

      // Get files
      function onLoad(documents){

        // Select the last document
        const document = documents.at(-1);

        if (document ===  undefined) {

          return setFormData({
            'disclosureFileUrl': '',
            'disclosureFileName': '',
            'disclosureFileId': '',
            'disclosureStatus': '',
          });

        }

        setFormData({
          'disclosureFileUrl': document?.signedurl,
          'disclosureFileName':document?.filename,
          'disclosureFileId': document?.fileid,
          'disclosureStatus': 'existing',
        });

        // Load the page
        setPageStatus('onload');

      }
  
      function onChange(documents){

        // Select the last document
        const document = documents.at(-1);

        if (document === undefined) {

          return setFormData({
            'disclosureFileUrl': '',
            'disclosureFileName': '',
            'disclosureFileId': '',
            'disclosureStatus': '',
          });

        }

        setFormData({
          'disclosureFileUrl': document?.signedurl,
          'disclosureFileName': document?.filename,
          'disclosureFileId': document?.fileid,
          'disclosureStatus': 'existing',
        });

      }
  
      function onError(error){

        setPageStatus('error-fatal');
        setError(`Failed to get file information, unable to create the query listener. Error: ${error}`);  

      }
  
      const unsubscribe = QueryListener('files', [
        ['requesttype', '==', 'nondisclosure'],
        ['projectid', '==', getProjectSelector.selectedProject.projectid],
        ['status', '==', 'transferred'],
      ], onLoad, onChange, onError)
  
      return () => {
        unsubscribe();
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProjectSelector]);

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  error-fatal ~ Only possible if an onload issue occurs (firestore rules, etc)
    //------------------------------------------------------

    if (pageStatus === 'error-fatal') {

      return(
        <div className='ProjectSetting-Form'>
          Unfortunately a critical error has occured, please try refreshing the page.
        </div>
      );

    }

    //------------------------------------------------------
    //  pending
    //------------------------------------------------------

    if (pageStatus === 'pending') {

      return (

        <div className='ProjectSetting-Form'>
          <div style={{marginTop: '30px'}}>

            <button style={{cursor: 'wait', marginRight: '10px'}} className='Primary-Button' disabled>Save changes</button>
            <button style={{cursor: 'not-allowed'}} className='Secondary-Button' disabled>Discard</button>
        
          </div>
        </div>

      )

    }

    //------------------------------------------------------
    //  Default state of the component 'onload'
    //------------------------------------------------------

    return (

      <form className='flex flex-col gap-3 bg-white px-[3%] py-[2%] mt-4 rounded-sm shadow-sm' onSubmit={(e) => onSubmit(e)}>

        {/* ===================================== */}
        {/*                 Name                  */}
        {/* ===================================== */}

        <div className='ProjectSetting-Form-Row flex flex-col border-0'>

          <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0'>Name</label>
          <input
            className='Input-Field-Text-Disabled'
            type='text'
            disabled
            value={getProjectSelector.selectedProject?.projectname}
          ></input>

        </div>
        
        {/* ===================================== */}
        {/*            Description                */}
        {/* ===================================== */}

        <div className='ProjectSetting-Form-Row'>

          <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0'>Description</label>
          <textarea
            className='Input-Field-TextArea'
            disabled
            value={getProjectSelector.selectedProject?.description}
          ></textarea>

        </div>

        {/* ================================================ */}
        {/*          Project Owner(s)                        */}
        {/*  1. Allows GA's to add existing firestore users  */}
        {/*  2. Validates new users with a form              */}
        {/*  3. Saves results to useReducer                  */}
        {/* ================================================ */}

        {
          // ========================================================
          // All 'newOwnerStatus' states ---> 'EXCLUDING new-users'!
          // ========================================================

          formData.newOwnerStatus !== 'new-user' ? (

            <div className='ProjectSetting-Form-Row'>

              <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0' hidden={!getUser.roles.globalAdmin}>
                Project Owner(s) *
              </label>

                {/* ======================================= */}
                {/*    Email Address Input Field            */}
                {/* ======================================= */}

                <div className='flex flex-row gap-2 items-center mb-2' hidden={!getUser.roles.globalAdmin}>
                  <input
                    className={formData.newOwnerMessage.length === 0 ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                    type='text'
                    placeholder='Email address'
                    onChange={(e) => handleChangeOwner(e.target.value)}
                    value={formData.newOwner}
                    autoComplete='off'
                  ></input>

                  {/* ======================================= */}
                  {/*    Toggle Add Icon                      */}
                  {/*  1. Enabled                             */}
                  {/*  2. Disabled                            */}
                  {/* ======================================= */}

                  {
                    // 1. Enabled 
                    formData.newOwnerAllowAdd === true ? (

                      <img
                        style={{marginLeft: '10px'}} 
                        className='Input-Icon'
                        src={IconAddTeal} 
                        alt='add-icon'
                        onClick={() => handleAddOwner()}
                      ></img>

                    ): 

                    // 2. Enabled
                    (

                      <img
                        style={{marginLeft: '10px'}} 
                        className='Input-Icon'
                        disabled={true}
                        src={IconAddGrey} 
                        alt='add-icon-disabled'
                      ></img>

                    )
                  }

                </div>


              {/* ======================================= */}
              {/*    1. List of all Owners                */}
              {/*    2. Error messages                    */}
              {/* ======================================= */}

              <div className='ProjectSetting-Form-ProjectOwners'>

                <span className='Input-Field-Text-Error-Message'>{formData.newOwnerMessage}</span>

                {

                  formData?.owners?.map((object, index) => (

                    <div key={index} style={{display: 'flex'}}>

                      {
                        object.type !== 'deleted' && (

                          <>
                            <label className='Label-List-Item'>{object.emailaddress}</label>
                            <img 
                              hidden={!getUser.roles.globalAdmin}
                              style={{marginLeft: '10px'}} 
                              className='Input-Icon' 
                              src={IconDeleteRed} 
                              alt='delete-icon' 
                              onClick={() => handleDeleteOwner(object, index)}
                            ></img>
                          </>

                        )
                      }

                    </div>
                  
                  ))

                }
              </div>

            </div>

          ) :
          
          // ========================================================
          //  New Users Form ---> formData.newOwnerStatus === 'new-users'
          // ========================================================

          (

            <div className='ProjectSetting-Form-Row'>

              <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0'>Project Owner(s) *</label>

              <div className='New-User-Form'>

                {/* <-------------------HEADER--------------------> */}
                <div className='New-User-Header'>
                  <img src={IconInfoRequired} alt='Info-Required-Icon' width='25px'></img>
                  MORE INFORMATION REQUIRED
                </div>

                {/* <-----------------EMAIL ADDRESS---------------> */}
                <div className='New-User-Email'>
                  <p> Email Address </p>
                  <input
                    className='Input-Field-Text'
                    type='text'
                    value={formData.newOwner}
                    disabled
                    autoComplete='off'
                  ></input>
                </div>

                {/* <-----------------USER NAME------------------> */}
                <div className='New-User-Name'>
                  
                  {/* Given Name */}
                  <div>
                    <p> Given Name </p>   
                    <input
                      className='Input-Field-Text'
                      type='text'
                      placeholder='Sally'
                      value={formData.newOwnerGivenname}
                      onChange={(e) => setFormData({'newOwnerGivenname': e.target.value})}
                    ></input>
                  </div>

                  {/* Surname */}
                  <div>
                    <p> Surname </p>
                    <input
                      className='Input-Field-Text'
                      type='text'
                      placeholder='Smith'
                      value={formData.newOwnerSurname}
                      onChange={(e) => setFormData({'newOwnerSurname': e.target.value})}
                    ></input>
                  </div>

                </div>

                {/* <-----------------ADD BUTTON---------------> */}
                <div className='New-User-Buttons'>

                  {/* Disable '+ Add User' button if fields are empty */}
                  <button 
                    className='Primary-Button'
                    style={{fontSize: '13px'}}
                    disabled = {
                      formData.newOwnerGivenname.length > 0 &&
                      formData.newOwnerSurname.length > 0 ? (
                        false
                      ): (
                        true
                      )
                    }
                    onClick={() => {
                      formData.owners.push({
                        'emailaddress': formData.newOwner,
                        'type': 'new',
                        'givenname': formData.newOwnerGivenname,
                        'surname': formData.newOwnerSurname,
                      });
                      formData.newOwnerStatus = 'onload';
                      formData.newOwner = '';
                      formData.newOwnerAllowAdd = false;
                      formData.newOwnerGivenname = '';
                      formData.newOwnerSurname = '';
                      setFormData(formData);
                  }}
                  >
                    + Add User
                  </button>

                  {/* Cancel Button > Hides new user form */}
                  <button 
                    className='Secondary-Button'
                    style={{fontSize: '13px'}}
                    onClick={() => {
                      formData.newOwnerStatus = 'onload';
                      formData.newOwner = '';
                      formData.newOwnerGivenname = '';
                      formData.newOwnerSurname = '';
                      setFormData(formData);
                    }}
                  >
                    Cancel
                  </button>

                </div>
              </div>

            </div>
          
          )
        }

        {/* ===================================== */}
        {/*          Location                     */}
        {/* ===================================== */}

        <div className='ProjectSetting-Form-Row flex flex-col'>

          <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0'>Location</label>
          <select 
            className={formData.locationValid ? ('Input-Field-Select mt-0'):('Input-Field-Select-Error mt-0')}
            value={formData.location}
            disabled
          >
            <option value={formData.location}>{formData.location}</option>
          </select>

        </div>

        {/* ===================================== */}
        {/*        Nominated Pass Office          */}
        {/* ===================================== */}

        <div className='CreateProject-Form-Row flex flex-col'>

          <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0'>Nominated Pass Office *</label>

          <div>

            <select 
              className={formData.passOfficeNameValid ? ('Input-Field-Select mt-0'):('Input-Field-Select-Error mt-0')}
              onChange={(e) => setFormData({'passOfficeState': e.target.value})}
              value={formData.passOfficeState}
              onClick={(e) => {

                if (e.target.value.length < 2) return;

                // Filter the Office selector by the selected state
                const state = formData.passOfficeData.filter((object) => object.state === e.target.value);

                setFormData({
                  'passOfficeNameFilter': state[0].offices,
                  'passOfficeName': '',
                  'passOfficeNew': '',
                });

              }}
            >
              <option hidden value=''>-</option>
              {/* Map each state */}
              {
                formData.passOfficeData.map((object, index) => (

                  <option 
                    key={index} 
                    value={object.state} 
                  >
                    {object.state}
                  </option>

                ))
              }
            </select>
            
            {/* Map each office from the selected state */}
            <select
              style={{marginLeft: '10px'}}
              className={formData.passOfficeNameValid ? ('Input-Field-Select mt-0'):('Input-Field-Select-Error mt-0')}
              onChange={(e) => setFormData({'passOfficeName': e.target.value})}
              value={formData.passOfficeName}
            >
              <option hidden value=''>-</option>
              {
                formData.passOfficeNameFilter?.map((office, index) => (

                  <option key={index} value={office}>{office}</option>

                ))
              }
            </select>

            <div>
              <label style={{color: '#545454'}}>If you require an alternate pass office, please enter the name of the pass office below.</label>
              <div>
                <input
                  style={{marginBottom: '15px'}}
                  className={formData.passOfficeNewValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                  type='text'
                  placeholder='Enter pass office name'
                  onChange={(e) => setFormData({'passOfficeNew': e.target.value})}
                  value={formData.passOfficeNew}
                ></input>
              </div>

            </div>

          </div>

        </div>

        {/* ===================================== */}
        {/*           Data Location               */}
        {/* ===================================== */}

        <div className='ProjectSetting-Form-Row flex flex-col'>

          <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0'>Data Location</label>

          <select 
            className='Input-Field-Select mt-0'
            value={formData.dataLocation}
            disabled
          >
            <option value={formData.dataLocation}>{formData.dataLocation}</option>
          </select>
        
        </div>

        {/* ===================================== */}
        {/*        Non-Disclosure Agreement       */}
        {/* ===================================== */}

        <div className='ProjectSetting-Form-Row'>

          <label className='font-semibold text-[#4d4d4d] uppercase tracking-wider text-[15px] mt-0'> Non-Disclosure Agreement (Optional) </label>

          <div>
            <p className='text-[#545454] mt-0'>If you require your workers to sign a non-disclosure agreement for this project, upload a copy of it below.</p>
            
            <UpdateNDA
              formData={formData}
              setFormData={setFormData}
            ></UpdateNDA>

          </div>

        </div>

        {/* ===================================== */}
        {/*        Buttons                        */}
        {/* ===================================== */}

        {

          // Show 'Save' button
          pageStatus === 'onload' ? 
          (
            <div style={{marginTop: '30px'}}>

              <button style={{marginRight: '10px'}} className='Primary-Button' type='submit' value='submit'>Save Changes</button>
              <button className='Secondary-Button' onClick={() => window.location.reload()}>Discard</button>

            </div>
          // Show 'Error' message & try again button
          )
          : 
          (
            <div style={{marginTop: '30px'}}>

              <button style={{marginRight: '10px'}} className='Primary-Button' type='submit' value='submit'>Try again</button>
              <button className='Secondary-Button' onClick={() => window.location.reload()}>Discard</button>
              <div className='Input-Field-Text-Error-Message'>
                {formError}
              </div>

            </div>
          )

        }

    </form>

    )
  
}
