/**
 * Function to check a users access to a role
 * 
 * @param {array} requiredRoles - The list of the REQUIRED roles
 * @param {object} userRoles - Firestore user document 'roles' object
 * @param {string} filterByResource - {OPTIONAL} Look for THIS value in an array of 'roles'
 * 
 * ```
 *  const requiredRoles = ['projectOwner', 'projectOperator'];
 *  const userRoles = {
 *    "projectOwner":[
 *      "project123"
 *    ],
 *    "projectOperator":[
 *      "project123"
 *    ]
 *  };
 *  const filterByResource = "project123"
 * 
 *  const roleCheck = CheckUserRole(requiredRoles, userRoles, filterByResource);
 *  console.log('Does this user have this role?', roleCheck);
 * ```
 * 
 * Documentation
 * - N/A
 */

export default function CheckUserRole(requiredRoles, userRoles, filterByResource){
  
  //------------------------------------------------------
  //  Environment Variables
  //------------------------------------------------------

  let hasRole = false

  //------------------------------------------------------
  //  Extract the 'userRoles' for the selected resource
  //------------------------------------------------------
  

    try {
      requiredRoles.forEach(requiredRole => {

        const resources = userRoles[requiredRole]

        if(resources === filterByResource){
          hasRole = true;
        }
        
      });

    } catch (error) {}


  //------------------------------------------------------
  //  return results
  //------------------------------------------------------


    return hasRole;
  
  
  //--------------------------------------------------------

}
