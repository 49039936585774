//================================================================
//  Component: Status Label
//================================================================

//  Purpose: This is a label component displaying the status of a prequalification application

//  Properties:
//    - prequalification = {A map, this contains the prequalification status}

//  StatusLabel:
//    <StatusLabel
//      prequalification={request}
//    ></StatusLabel>    

//================================================================

//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images

//CSS
import './StatusLabel.css';


export default function StatusLabel({
  prequalification
}) { 

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div>
        {
            [
                {'name': 'In Review', 'background': '#84C1D4', 'status': 'review'}, 
                {'name': 'Requires More Info', 'background': '#E47525', 'status': 'moreinfo'}, 
                {'name': 'Pending Approval', 'background': '#E4BA25', 'status': 'pendingapproval'}, 
                {'name': 'Approved', 'background': '#61B280', 'status': 'approved'}, 
                {'name': 'Rejected', 'background': '#D85C5C', 'status': 'rejected'}, 
                {'name': 'Expired', 'background': '#C0C0C0', 'status': 'expired'}, 
            ].filter((style) => style.status === prequalification?.status).map((object, index) => (
                <label key={index} style={{backgroundColor: `${object.background}`}}  className='Status-Label'>
                    {object.name}
                </label>
            ))
        }
    </div>
  )
}
