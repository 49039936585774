//================================================================
//  Page: AppSettings 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetProjectSelector} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';


//Functions

//Images

//CSS
import './Home.css';


export default function Home() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getProjectSelector = useContext(GetProjectSelector);


  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requiredRoles={ ['lendleaseUser'] }
        requiredRolesValues={ [true] }
        status={'onload'}
        body={
          <div className='bg-[#FAFAFA] px-5 py-4 rounded-md'>
            {
              getProjectSelector.selectedProject === undefined ? (
                <p className='text-base m-0'>
                  Welcome to Lendlease DCAC Administrator Portal, to get started click <strong style={{fontWeight: 600}}>Select a Project</strong>.
                </p>
              )
              : 
              (
                <p className='text-base m-0'>
                  Welcome to Lendlease DCAC Administrator Portal, the selected project is <strong style={{fontWeight: 600}}>{getProjectSelector?.selectedProject?.projectname}</strong>.
                </p>
              )
            }
          </div>
        }
      ></PageComponent>
    )
}