//================================================================
//  Component: Organisations
//================================================================

//  Purpose: Create & manage orgs

//  Properties:
//    - selectedProject = {object, selectedProject}

//  Example:
//    <Organisations
//      selectedProject={selectedProject}
//    ></Organisations>    

//================================================================


//Libraries
import React, { useEffect, useReducer, useState, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../../Library/FirebaseConfig';
import { doc, updateDoc, arrayUnion, getFirestore } from 'firebase/firestore';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Functions
import getDocument from '../../../../Library/GetDocument';
import writeDocument from '../../../../Library/WriteDocument';
import queryListner from '../../../../Library/QueryListener';
import queryDocument from '../../../../Library/QueryDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Components
import OrganisationTableRow from './OrganisationTableRow';

//Images
import IconAddGrey from '../../../../Components/Images/Icon_Add_Grey.svg';
import IconAddTeal from '../../../../Components/Images/Icon_Add_Teal.svg';
import IconDeleteRed from '../../../../Components/Images/Icon_Delete_Red.svg';
import IconInfoRequired from '../../../../Components/Images/Icon_InfoRequired_Teal.svg';
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';

//CSS
import '../../ProjectSettings.css';


export default function Organisations({
  selectedProject
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Handles what the user will see > 'manage', 'create', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('manage');

    // Show an error message next to the save button
    const [formError, setFormError] = useState('');

  
  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {

        // Manage Orgs (pageStatus === 'manage')
        'search': '',
        'searchSelectedOrg': undefined,
        'addDisabled': true,
        'filteredOrgs': [],
        'allOrgs': [],
        'attachedOrgs': [],

        // Create Org (pageStatus === 'create')
        'name': '',
        'nameValid': true,
        'description': '',
        'descriptionValid': true,
        'abn': '',
        'abnValid': true,

        // Org Owner States
        'newOwner': '',
        'newOwnerGivenname': '',
        'newOwnerSurname': '',
        'newOwnerStatus': 'onload', // 'onload', 'new-user'
        'newOwnerMessage': '',
        'owners': [],
      }
    );

  
  //------------------------------------------------------
  //  Functions -- Search & Add organistions
  //------------------------------------------------------

    // Search for orgs
    // Benno ~ Already attached orgs are already excluded, refer to the onload by the useEffect!
    function searchByName(value) {

      formData.search = value;

      if (value === '') {

        // Reset Filter
        formData.addDisabled = true;
        formData.filteredOrgs = [];
      
      } else {

        const results = [];
        formData.allOrgs.forEach((currentOrg) => {

          // Check if 'organisationname' matches
          if (!(currentOrg.organisationname.toLowerCase().includes(value.toLowerCase()))) return;

          // Check if 'organisation' is already attached to project
          const attachedCheck = formData.attachedOrgs.filter((org) => org.organisationid === currentOrg.organisationid);
          if (attachedCheck.length > 0) return;

          results.push(currentOrg);

        });

        formData.filteredOrgs = results;

      };

      setFormData(formData);

    };

    // Function to handle adding new org to project
    function handleAddOrg(){

      if (formData.search.length === 0) return;
      if (formData.searchSelectedOrg === undefined) return;
      if (formData.searchSelectedOrg.organisationid === undefined) return;
      if (formData.addDisabled === true) return;

      // Firestore Client ~ Allows the update of elements in an array
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      // https://cloud.google.com/firestore/docs/manage-data/add-data#update_elements_in_an_array
      const docRef = doc(db, 'organisations', formData.searchSelectedOrg.organisationid);

      // Append 'organisations' array
      updateDoc(docRef, {
        projects: arrayUnion(selectedProject.projectid)
      }).then(() => {

        formData.attachedOrgs.push(formData.searchSelectedOrg);
        formData.search = '';
        formData.searchSelectedOrg = undefined;
        formData.addDisabled = true;
        
        setFormData(formData);

      }).catch((error) =>{

        console.log(error)

        // Benno-TODO ~ Figure out error handing

      });

    };

    // Function to handle adding new organisation owners 
    function handleChangeOwner(value) {

      if (value.length === 0) {

        return setFormData({
          'newOwner': value,
          'newOwnerAllowAdd': false
        });

      }

      // Validate emailaddress
      if (/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)) {
    
        return setFormData({
          'newOwner': value,
          'newOwnerStatus': 'onload',
          'newOwnerMessage': '',
          'newOwnerAllowAdd': true,
        });

      } 

      // invalid emailaddress
      setFormData({
        'newOwner': value,
        'newOwnerStatus': 'onload',
        'newOwnerMessage': '',
        'newOwnerAllowAdd': false,
      });

    }

    // Function to handle changes to the ABN field
    function handleChangeABN(value) {

      // Validate ABN > 11 numeric characters (set to 10 because there is a setState delay)
      if (formData.abn.length !== 10 || isNaN(formData.abn)) {

        formData.abn = value;
        formData.abnValid = false;
        return setFormData(formData);

      } 

      // Valid ABN
      formData.abn = value;
      formData.abnValid = true;
      setFormData(formData);

    }

  
  //------------------------------------------------------
  //  Functions -- Create Org (pageStatus === 'create')
  //------------------------------------------------------

    // Adds a Org Owner to 'formData.owners' array
    function handleAddOwner() { 

      // Check for duplicates
      const owner = formData.owners.filter((object) => object.emailaddress === formData.newOwner);
      if (owner.length > 0) {

        if (owner[0].type !== 'deleted') {

          return setFormData({
            'newOwnerMessage': 'Email address already exists.'
          });

        };

      };

      //------------------------------------------------------
      //  Get the users document
      //  1. Check if they exist (Catch add's them as a new user)
      //  2. Check if they are part an existing org
      //------------------------------------------------------

      return getDocument('users', formData.newOwner).then((userDoc) => {

        return queryDocument('organisations', [
          ['organisationid', '==', userDoc.organisationid]
        ]).then((usersOrg) => {

          // Check if the previous org was active
          if (usersOrg.length > 0 && usersOrg[0].status === 'active') {

            return setFormData({
              'newOwnerMessage': `User is already apart of organisation, '${usersOrg[0].organisationname}'`
            });

          }

          // Sanity check if the user was added/removed from the list
          let foundUser = false;
          formData.owners.forEach((user, index) => {

            if (user.emailaddress === formData.newOwner) {

              formData.owners[index] = {
                'emailaddress': formData.newOwner,
                'type': 'existing'
              };
              foundUser = true;

            }

          });

          // User wasn't added/removed from the list, append the 'owners' array
          if (foundUser === false) {

            formData.owners.push({
              'emailaddress': formData.newOwner,
              'type': 'existing'
            });

          };

          formData.newOwner = '';
          formData.newOwnerMessage = '';
          formData.newOwnerAllowAdd = false;
          formData.newOwnerStatus = 'onload';
          setFormData(formData);

        }).catch((error) => {

          // Fatal Error and shouldn't be possible if the Firestore Rules are correct
          console.log('Fatal Error with security rules!', error );

        });

      }).catch(() => {

        formData.newOwnerStatus = 'new-user';
        formData.newOwnerMessage = '';
        formData.newOwnerAllowAdd = false;
        setFormData(formData);

      });

    };

    // Org Creation onSubmit handler
    function onSubmitCreate(e){

      e.preventDefault();

      // OnSubmit Validation of all required fields
      let preventSubmit = false;

      if (formData.name.length === 0) {

        preventSubmit = true;
        formData.nameValid = false;
        
      } else {

        formData.nameValid = true;

      };

      if (formData.description.length === 0) {

        preventSubmit = true;
        formData.descriptionValid = false;
        
      } else {

        formData.descriptionValid = true;

      };

      if (formData.abn.length !== 11 || isNaN(formData.abn)) {

        preventSubmit = true;
        formData.abnValid = false;
        
      } else {

        formData.abnValid = true;

      };

      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) {

        return setFormError('Complete the form before proceeding');

      }

      // Validations have passed, proceed.
      setPageStatus('pending');

      // ================================================================
      // Passed all validation checks --> write project & user documents
      //  - Create an new project document 
      //  - Create new user documents
      //  - Update 'projectAdmin' array (Ref to arrayUnion's in Firebase documentation)
      // ================================================================

      // ----------------------------------------------------------
      // 0. Prepare
      // ----------------------------------------------------------

      // Firestore Client ~ Allows the update of elements in an array
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const orgId = `org-${Date.now()}`
      const newUsers = [];
      const existingUsers = [];
      const deletedUsers = [];
      const orgWorkers = [];
      const auditingPromises = [];

      // ----------------------------------------------------------
      // 1. Extract data from 'owners' array
      // ----------------------------------------------------------

      formData.owners.forEach((object) => {

        // Added a check to ensure Lendlease users do NOT lose their LLUser role
        let lendleaseUser = false;
        if (object.emailaddress.endsWith('@lendlease.com')) {

          lendleaseUser = true;

        }

        if (object.type === 'new') {

          // Define user document
          newUsers.push({
            'emailaddress': object.emailaddress,
            'givenname': object.givenname,
            'surname': object.surname,
            'created': new Date(),
            'projectAdmin': [],
            'organisationid': orgId,
            'organisationname': formData.name,
            'orgAdmin': [
              orgId
            ],
            'projects': [], // ADD PROJECT ID HERE 
            'roles': {
              'globalAdmin': false,
              'lendleaseUser': lendleaseUser,
            },
            'status': 'active'
          });

          // All org admins are added to the 'workers' array by default
          orgWorkers.push(
            object.emailaddress,
          );

          // Auditing
          const message = `${getUser.emailaddress} -- Created new org admin ${object.emailaddress} for organisation ${formData.name}.`;
          const types = [
            'project history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'organisations',
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );

        }

        if (object.type === 'existing') {

          existingUsers.push(object.emailaddress);

          // All org admins are added to the 'workers' array by default
          orgWorkers.push(
            object.emailaddress,
          );

          // Auditing
          const message = `${getUser.emailaddress} -- Added org admin ${object.emailaddress} to organisation ${formData.name}.`;
          const types = [
            'project history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'organisations',
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );

        };

        if (object.type === 'deleted') {

          deletedUsers.push(object.emailaddress);

          // Auditing
          const message = `${getUser.emailaddress} -- Deleted org admin ${object.emailaddress} from organisation ${formData.name}.`;
          const types = [
            'project history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'organisations',
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );

        };
        
      });

      // ----------------------------------------------------------
      // 2. Create doc in 'organisations' collection
      // ----------------------------------------------------------

      const orgDoc = {
        'organisationid': orgId,
        'organisationname': formData.name,
        'description': formData.description,
        'abn': formData.abn,
        'creationdate': new Date(),
        'status': 'active',
        'projects': [],
        'workers': orgWorkers,
      };
      const orgPromise = writeDocument('organisations', orgId, orgDoc, false);

      // Auditing
      const message = `${getUser.emailaddress} -- Organisation ${formData.name}, created by ${getUser.emailaddress}.`;
      const types = [
        'project history'
      ];
      const references = [
        formData.name,
        'organisations',
      ];
      auditingPromises.push(
        writeAuditLog(getUser.emailaddress, message, types, references)
      );

      // ----------------------------------------------------------
      // 3. Write 'new users' to 'users' collection
      // ----------------------------------------------------------

      const newUserPromises = [];
      newUsers.forEach((document) => {

        newUserPromises.push(
          writeDocument('users', document.emailaddress, document, false)
        )

      });

      // ----------------------------------------------------------
      // 4. Merge 'existing user' roles in their 'users' collection
      // ----------------------------------------------------------

      const existingUserPromises = [];
      existingUsers.forEach((emailaddress) => {

        // https://cloud.google.com/firestore/docs/manage-data/add-data#update_elements_in_an_array
        const docRef = doc(db, 'users', emailaddress);

        existingUserPromises.push(
          updateDoc(docRef, {
            orgAdmin: arrayUnion(orgId)
          })
        );

        const newdoc = {
          'organisationid': orgId,
          'organisationname': formData.name,
        }
        existingUserPromises.push(
          writeDocument('users', emailaddress, newdoc, true)
        )

      });

      // ----------------------------------------------------------
      // 5. Delete 'existing user' role in their 'users' collection
      // ----------------------------------------------------------

      const deletedUserPromises = [];
      deletedUsers.forEach((emailaddress) => {

        // We ALWAYS delete org admins/workers to PII data is removed
        const document = {
          'status': 'inactive'
        };
        deletedUserPromises.push(
          writeDocument('users', emailaddress, document, true)
        );

      });

      // ----------------------------------------------------------
      // 6. Settle promises & route user to 'prequalifications' page
      // ---------------------------------------------------------

      return Promise.all([orgPromise, ...newUserPromises, ...existingUserPromises, ...deletedUserPromises, ...auditingPromises]).then(() => {

        // Reset org form
        setFormData({
          'name': '',
          'nameValid': true,
          'description': '',
          'descriptionValid': true,
          'abn': '',
          'abnValid': true,
          'newOwner': '',
          'newOwnerGivenname': '',
          'newOwnerSurname': '',
          'newOwnerStatus': 'onload',
          'newOwnerMessage': '',
          'owners': [],
        });
        setPageStatus('manage');

      }).catch(() => {

        setFormError('Unable to save changes, please try again.');
    
      });

    };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onload
    //  1. Create a queryListener for all active orgs
    //  2. Sort results to find attached orgs to project
    //  3. Update formData.allOrgs & fromData.attachedOrgs
    useEffect(() => {

      if (selectedProject?.projectid === undefined) return;

      function onLoadChange(orgs){

        // Filter to include attached orgs
        const attachedOrgs = orgs.filter((org) => org.projects.includes(selectedProject.projectid));
        
        // Filter to NOT include attached orgs
        const allOrgs = orgs.filter((org) => !org.projects.includes(selectedProject.projectid));

        setFormData({
          'attachedOrgs': attachedOrgs,
          'allOrgs': allOrgs,
        });

      };
      
      function onError(error){

        // Benno-TODO ~ Figure out error handing

      };

      const unsubscribe = queryListner('organisations', [
        ['status', '==', 'active']
      ], onLoadChange, onLoadChange, onError);

      return () => {

        unsubscribe();
        
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    <div className='Page-Body-Container'>

      {
        pageStatus === 'manage' ? (

        // ================================================================
        //
        //                   Organisations Table
        //
        // ================================================================

        <>

          {/* ===================================================== */}
          {/*   Project Name & 'Mark Project Complete' Modal        */}
          {/* ===================================================== */}

          <div className='ProjectSetting-Header'>

            <h5>{selectedProject?.projectname}</h5>
            <button className='Primary-Button' onClick={() => setPageStatus('create')}> Create Organisation </button>

          </div>

          {/* ===================================================== */}
          {/*                     Search                            */}
          {/* ===================================================== */}

          <div className='ProjectSetting-Form-Row'>

            <div className='ProjectSetting-Search-Container'>

              <div className='ProjectSetting-Search'>
                <img src={IconSearch} alt='search-icon'></img>
                <input
                  type='text'
                  placeholder='Search for an organisation'
                  onChange={(e) => searchByName(e.target.value)}
                  value={formData.search}
                ></input>
              </div>
              <button disabled={formData.addDisabled} className='Primary-Button' onClick={() => handleAddOrg()}>+ Add</button>
              <div className='ProjectSetting-Search-List'>
                {

                  formData.filteredOrgs.length > 0 && (

                    formData.filteredOrgs.map((org, index) => (
                      <label key={index} onClick={() => {

                        formData.search = org.organisationname;
                        formData.searchSelectedOrg = org;
                        formData.addDisabled = false;
                        formData.filteredOrgs = [];
                        setFormData(formData);
                      
                      }}>
                        {org.organisationname}
                      </label>
                    ))

                  )

                }
              </div>

            </div>

            {/* ===================================================== */}
            {/*                     Org Table                         */}
            {/* ===================================================== */}

            {

              formData?.attachedOrgs?.length === 0 ? 
              (
                <div className='px-2 py-3 my-4'>
                  <h6 className='text-[#555555] m-0'>No organisations have been attached to this project.</h6>
                </div>
              ) : (

                <div className='ProjectSetting-Table'>
                  <div className='Project-Selector-Table'>
                    {
                      formData?.attachedOrgs?.map((org, index) =>(

                        <div key={index}>
                          <OrganisationTableRow
                            org={org}
                          ></OrganisationTableRow>
                        </div>

                      ))
                    }
                  </div>

                </div>

              )

            }

          </div>

        </>

        )
        : 
        pageStatus === 'create' ? (

          // ================================================================
          //
          //                   Organisations Create Form
          //
          // ================================================================

          <>
          
            <div className='ProjectSetting-Header'>

              <h5>Create Organisation</h5>
              <button className='Primary-Button' onClick={() => 
                {
                  setFormData({
                    'name': '',
                    'nameValid': true,
                    'description': '',
                    'descriptionValid': true,
                    'abn': '',
                    'abnValid': true,
                    'newOwner': '',
                    'newOwnerGivenname': '',
                    'newOwnerSurname': '',
                    'newOwnerStatus': 'onload', // 'onload', 'new-user'
                    'newOwnerMessage': '',
                    'owners': [],
                  })
                  setPageStatus('manage');
                }
              }> 
                Back 
              </button>

            </div>

            <form className='ProjectSetting-Form' onSubmit={(e) => onSubmitCreate(e)}>

              {/* ===================================== */}
              {/*                 Name                  */}
              {/* ===================================== */}
      
              <div className='ProjectSetting-Form-Row'>
                <div className='Organisation-Field-Container'>
                  
                  <label>Name *</label>
                  
                  <div>
                    <input
                      className={formData.nameValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                      type='text'
                      placeholder='Enter the organisation name'
                      onChange={(e) => setFormData({'name': e.target.value})}
                      value={formData.name}
                    ></input>

                    {
                      !formData.nameValid && 
                      <div className='Input-Field-Text-Error-Message' style={{margin: '10px 0px'}}>
                        Please enter an organisation name.
                      </div>
                    }     
                  </div>

                </div>
              </div>
              
              {/* ===================================== */}
              {/*            Description                */}
              {/* ===================================== */}
      
              <div className='ProjectSetting-Form-Row'>
                <div className='Organisation-Field-Container'>

                  <label>Description *</label>

                  <div>
                    <textarea
                      className={formData.descriptionValid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')} 
                      placeholder='Enter a short description for the organisation'
                      onChange={(e) => setFormData({'description': e.target.value})}
                      value={formData.description}
                    ></textarea>

                    {
                      !formData.descriptionValid && 
                      <div className='Input-Field-Text-Error-Message' style={{margin: '10px 0px'}}>
                        Please enter a description.
                      </div>
                    }  
                  </div>

                </div>
              </div>
      
              {/* ===================================== */}
              {/*               ABN                     */}
              {/* ===================================== */}
      
              <div className='ProjectSetting-Form-Row'>
                <div className="Organisation-Field-Container">

                  <label>ABN *</label>

                  <div>
                    <input
                      className={formData.abnValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                      type='text'
                      placeholder="Enter the organisation's ABN"
                      onChange={(e) => handleChangeABN(e.target.value)}
                      value={formData.abn}
                    ></input>
          
                    {
                      !formData.abnValid && 
                      <div className='Input-Field-Text-Error-Message' style={{margin: '10px 0px'}}>
                        Please enter a valid ABN.
                      </div>
                    }
                  </div>
                </div>
  
              </div>
      
              {/* ================================================ */}
              {/*         Organisation Owner(s)                    */}
              {/*  1. Allows GA's to add existing firestore users  */}
              {/*  2. Validates new users with a form              */}
              {/*  3. Saves results to useReducer                  */}
              {/* ================================================ */}
      
              {
                // ========================================================
                // All 'newOwnerStatus' states ---> 'EXCLUDING new-users'!
                // ========================================================
      
                formData.newOwnerStatus !== 'new-user' ? (
      
                  <div className='ProjectSetting-Form-Row'>

                    <div className='Organisation-Field-Container'>
        
                      <label>Organisation Owner(s)</label>
        
                      <div>
                        {/* ======================================= */}
                        {/*    Email Address Input Field            */}
                        {/* ======================================= */}
        
                        <div className='flex flex-row gap-2 items-center mb-2'>
                          <input
                            className={
                              formData.newOwnerMessage.length === 0 ? ('Input-Field-Text'): (
                                'Input-Field-Text-Error'
                              )
                            }
                            type='text'
                            placeholder='Email address'
                            onChange={(e) => handleChangeOwner(e.target.value)}
                            value={formData.newOwner}
                            autoComplete='off'
                          ></input>
        
                          {/* ======================================= */}
                          {/*    Toggle Add Icon                      */}
                          {/*  1. Enabled                             */}
                          {/*  1. Disabled                            */}
                          {/* ======================================= */}
        
                          {
                            formData.newOwnerAllowAdd === true ? (
        
                              <img
                                style={{marginLeft: '10px'}} 
                                className='Input-Icon'
                                src={IconAddTeal} 
                                alt='add-icon'
                                onClick={() => handleAddOwner()}
                              ></img>
        
                            ): (
        
                              <img
                                style={{marginLeft: '10px'}} 
                                className='Input-Icon'
                                disabled={true}
                                src={IconAddGrey} 
                                alt='add-icon-disabled'
                              ></img>
        
                            )
                          }
        
                        </div>
        
        
                        {/* ======================================= */}
                        {/*    1. Error messages                    */}
                        {/*    2. List of all Owners                */}
                        {/* ======================================= */}
          
                        <div className='ProjectSetting-Form-ProjectOwners'>

                          <span className='Input-Field-Text-Error-Message'>{formData.newOwnerMessage}</span>
                          {
          
                            // Show the list of current owners
                            formData?.owners?.map((object, index) => (
        
                              <div key={index} style={{display: 'flex'}}>
        
                                {
                                  object.type !== 'deleted' && (
        
                                    <>
                                      <p className='Label-List-Item' style={{marginLeft: '0px'}}>{object.emailaddress}</p>
                                      <img 
                                        style={{marginLeft: '10px'}} 
                                        className='Input-Icon' 
                                        src={IconDeleteRed} 
                                        alt='delete-icon' 
                                        onClick={() => {

                                          formData.owners.splice(index, 1)
                                          setFormData(formData);

                                        }}
                                      ></img>
                                    </>
        
                                  )
                                }
        
                              </div>
                            
                            ))
          
                          }
                        </div>

                      </div>

                    </div>
      
                  </div>
      
                ) :
                
                // ========================================================
                //  New Users Form ---> formData.newOwnerStatus === 'new-users'
                // ========================================================
      
                (
                  <div className='ProjectSetting-Form-Row'>
      
                    <div className='Organisation-Field-Container'>
                      <label>Organisation Owner(s)</label>

                      <div className='New-User-Form'>

                        {/* <-------------------HEADER--------------------> */}
                        <div className='New-User-Header'>
                          <img src={IconInfoRequired} alt='Info-Required-Icon' width='25px'></img>
                          MORE INFORMATION REQUIRED
                        </div>

                        {/* <-----------------EMAIL ADDRESS---------------> */}
                        <div className='New-User-Email'>
                          <p> Email Address </p>
                          <input
                            className='Input-Field-Text'
                            type='text'
                            value={formData.newOwner}
                            disabled
                            autoComplete='off'
                          ></input>
                        </div>
                        
                        {/* <-----------------USER NAME------------------> */}
                        <div className='New-User-Name'>

                          {/* Given Name */}
                          <div>
                            <p> Given Name </p>                            
                            <input
                              className='Input-Field-Text'
                              type='text'
                              placeholder='Sally'
                              value={formData.newOwnerGivenname}
                              onChange={(e) => setFormData({'newOwnerGivenname': e.target.value})}
                            ></input>
                          </div>
                                          
                          {/* Surname */}
                          <div>
                            <p> Surname </p>
                            <input
                              className='Input-Field-Text'
                              type='text'
                              placeholder='Smith'
                              value={formData.newOwnerSurname}
                              onChange={(e) => setFormData({'newOwnerSurname': e.target.value})}
                            ></input>
                          </div>

                        </div>
                        
                        {/* <-----------------ADD BUTTON---------------> */}
                        <div className='New-User-Buttons'>
    
                          {/* Disable '+ Add User' button if fields are empty */}
                          <button 
                            className='Primary-Button'
                            style={{fontSize: '13px'}}
                            disabled = {formData.newOwnerGivenname.length > 0 && formData.newOwnerSurname.length > 0 ? false : true}
                            onClick={() => {
        
                              formData.owners.push({
                                'emailaddress': formData.newOwner,
                                'type': 'new',
                                'givenname': formData.newOwnerGivenname,
                                'surname': formData.newOwnerSurname,
                              });
                              formData.newOwnerStatus = 'onload';
                              formData.newOwner = '';
                              formData.newOwnerAllowAdd = false;
                              formData.newOwnerGivenname = '';
                              formData.newOwnerSurname = '';
                              setFormData(formData);
        
                            }}
                          >
                            + Add User
                          </button>
                          
                          {/* Cancel Button > Hides new user form */}
                          <button 
                            className='Secondary-Button'
                            style={{fontSize: '13px'}}
                            onClick={() => {
        
                              formData.newOwnerStatus = 'onload';
                              formData.newOwner = '';
                              formData.newOwnerGivenname = '';
                              formData.newOwnerSurname = '';
                              setFormData(formData);
        
                            }}
                          >
                            Cancel
                          </button>
    
                        </div>
        
                      </div>
                    </div>

                  </div>
                )
              }
      
              <div>

                {/* Submit */}
                <button 
                  style={{marginTop: '30px'}} 
                  className='Primary-Button' 
                  type='submit' value='submit'
                >
                  Submit
                </button>
                
                {/* Cancel */}
                <button 
                  style={{marginLeft: '10px', marginTop: '30px'}} 
                  className='Secondary-Button' 
                  onClick={() => {
                    setFormData({
                      'name': '',
                      'nameValid': true,
                      'description': '',
                      'descriptionValid': true,
                      'abn': '',
                      'abnValid': true,
                      'newOwner': '',
                      'newOwnerGivenname': '',
                      'newOwnerSurname': '',
                      'newOwnerStatus': 'onload', // 'onload', 'new-user'
                      'newOwnerMessage': '',
                      'owners': [],
                    });
                    setPageStatus('manage');
                  }
                }>
                  Cancel
                </button>

              </div>

              {/* Error Message */}
              <div className='Input-Field-Text-Error-Message'>
                {formError}
              </div>
      
            </form>

          </>

        )
        : null
      }

    </div>
  )
}
