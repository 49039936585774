//================================================================
//  Page: AppSettings 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetProjectSelector } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import TabView from '../../Components/TabView/TabView';
import Permissions from './Components/Permissions/Permissions';
import Organisations from './Components/Organisations/Organisations';
import Workers from './Components/Workers/Workers';
import AuditLogs from './Components/AuditLogs/AuditLogs';

//Functions

//Images
import AppSettingsIcon from '../../Components/Images/Icon_AppSettings_Blue.svg';

//CSS
import './AppSettings.css';


export default function AppSettings() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const getProjectSelector = useContext(GetProjectSelector);
  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  //------------------------------------------------------
  //  Define variables
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      header={
        <PageHeader
          icon={<img src={AppSettingsIcon} alt='App Settings Icon'></img>}
          title={'App Settings'}
        ></PageHeader>
      }
      requiredRoles={ ['globalAdmin'] }
      requiredRolesValues={ [true] }
      status={'onload'}
      body={
        <TabView
            defaultView={1}
            oneTitle={'Permissions'}
            oneContent={                    
              <Permissions></Permissions>
            }
            oneRoute={'/appsettings?view=permissions'}
            twoTitle={'Organisations'}
            twoContent={
              <Organisations
                selectedProject={getProjectSelector?.selectedProject}
              ></Organisations>
            }
            twoRoute={'/appsettings?view=organisations'}
            threeTitle={'Workers'}
            threeContent={
              <Workers></Workers>
            }
            threeRoute={'/appsettings?view=workers'}
            fourTitle={'Audit Logs'}
            fourContent={
              <AuditLogs></AuditLogs>
            }
            fourRoute={'/appsettings?view=auditlogs'}
        ></TabView>
      }
    ></PageComponent> 
  )
}