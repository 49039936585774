//Libraries
import React, { useContext } from "react";
import { getAuth, signOut } from "firebase/auth";

//Contexts
import { SetAppStatus } from './GlobalContexts'

//Images
import SignOut from '../Components/Images/Icon_Signout_Black.svg'

/**
 * Trigger a Firebase signout on button click
 * 
 * @param {undefined} NotApplicable
 * 
 * ```
 * 
 * <Logout></Logout>    
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
 */

export default function Logout({
  title,
  setStyle
}) {

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Used to store the application status, i.e. pending, unauthenticated, authenticated, etc.
    const setAppStatus = useContext(SetAppStatus)

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#next_steps
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  // Functions
  //------------------------------------------------------

    // Used to log out the currently signed in user
    function LogOutHandler(){

      signOut(auth)
      .then(() => {

        setAppStatus("unauthenticated");

      })
      //An error happened.
      .catch(() => {

        setAppStatus("unauthenticated");

      })
    }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Navigation header sign out button
    //------------------------------------------------------

      if (setStyle === 'navbar'){
        return (
          <div className='Settings-Dropdown-Link' onClick={() => LogOutHandler()}>
            Sign Out

            <img className='Settings-Link-Icon' src={SignOut} alt="Sign Out Icon"></img>
          </div>
        )
      }
    
    //------------------------------------------------------
    //  Normal button
    //------------------------------------------------------

      else{
        return (
          <div style={{alignContent: "center"}}>
            <button className="Primary-Button" onClick={() => LogOutHandler()}>
              {title}
            </button>
          </div>
        )
      }

    //------------------------------------------------------
}