// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';
import { doc, deleteDoc } from "firebase/firestore"; 

/**
 * Deletes a document from a collection
 * 
 * @param {string} collectionId
 * @param {string} documentId
 * 
 * ```
 * const collectionId = 'users';
 * const documentId = 'mark.bennett@lendlease.com';
 * 
 * DeleteDocument(collectionId, documentId).then(() =>{
 *
 *    setRequestType('view');
 * 
 * }).catch((error) => {
 * 
 *     console.log(' - Failed to delete user document: ", error);
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/firestore/manage-data/delete-data
 */

export default async function DeleteDocument(collection, documentId){

    //Delete an existing document
    try {

      //Firestore Database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Set collection & document
      const collectionDocRef = doc(db, collection, documentId);

     // Delete the document
      return deleteDoc(collectionDocRef);

    } catch (error) {
      throw new Error(`Function DeleteDocument failed to complete, error ${error}`)
    }
}