//================================================================
//  Component: Remove Owner Modal
//================================================================

//  Purpose: Allows global administrators to remove other global administrators

//  Properties:
//    - modalState = {useState - used to toggle visibility of modal}
//    - setModalState = {A setState function - used to toggle the modal visibility}

//  Example:
//  <RemoveGlobalAdminModal 
//    modalState={pageState}
//    setModalState={setPageState} 
//  ></RemoveGlobalAdminModal>

//================================================================

//Libraries
import React, { useState, useContext } from 'react';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components

//Functions
import writeDocument from '../../../../Library/WriteDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Images
import DeleteIcon from '../../../../Components/Images/Icon_Delete_Red.svg';

//CSS
import '../../AppSettings';


export default function RemoveGlobalAdminModal({
  modalState,
  setModalState,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

  // Used to determine the status of the modal > 'onload', 'error-fatal'
  const [modalStatus, setModalStatus] = useState('onload');

  // Used to store modal error message
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Function used to delete a global administrator
  function deleteAdmin(emailaddress) {

    const document = {
      'roles': {
        'globalAdmin': false,
        'lendleaseUser': true,
      }
    };

    writeDocument('users', emailaddress, document, true)
    .then(() => {

      // Hide Modal
      setModalState({ 'removeModalAdmin': undefined });

      // Auditing
      const message = `${getUser.emailaddress} -- Removed Global Admin ${emailaddress}`;

      const types = [
        'app history'
      ];

      const references = [
        emailaddress,
        getUser.emailaddress,
        'users',
      ];

      return writeAuditLog(getUser.emailaddress, message, types, references);

    })
    .catch((error) => {
      setModalStatus('error-fatal');
      setModalErrorMessage(error);

    });

  };

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------
    
    //====================================================
    //  Modal Hidden
    //====================================================
    
    if (modalState?.removeModalAdmin === undefined) return null;

    //====================================================
    //  Error-Fatal
    //====================================================

    else if (modalStatus === 'error-fatal') {

      return (
        <div className='Modal-Background'>
          <dialog className='AppSettings-Modal-Worker'>
            <div className='flex flex-col text-center gap-3'>
                <h4>Oops something went wrong!</h4>
                
                <p className='m-0'>
                  An unknown error has occurred while attempting to delete <b className='font-semibold'>{modalState?.removeModalAdmin.emailaddress}</b>
                </p>
                <p className='m-0'>
                  <b className='font-semibold'>Message:</b> {modalErrorMessage}
                </p>
                <p className='m-0'>
                  If the error persists, please speak with your administrator. 
                </p>

                <div className='mt-2'>
                  <button className='Primary-Button' style={{ backgroundColor: '#D85C5C', borderColor: '#D85C5C' }} onClick={() => setModalState({ 'removeModalAdmin': undefined })}>Go Back</button>
                </div>
            </div>
          </dialog>
        </div>
      )
    }

    
    //====================================================
    //  Onload
    //====================================================

    else {
      return (
        <div className='Modal-Background'>
          <div className='AppSettings-Modal-Worker'>
            <div className='Action-Modal-Container'>
    
              {/* Modal Icon */}
              <div className='Action-Modal-Icon'>
                <img src={DeleteIcon} alt='Delete-Icon'></img>
              </div>
              
              {/* Modal Body */}
              <div className='Action-Modal-Body'>
    
                <h4 style={{marginTop: '14px'}}>
                  Remove Global Administrator
                </h4>
    
                <p className='m-0'> 
                  Are you sure you want to remove the global administrator role from <b className='font-semibold'>{modalState.removeModalAdmin?.emailaddress}</b>?
                </p>
    
                {/* Invite User Form */}
                <div className='Action-Modal-Body-Form'>
                  
                  {/* Submit Button */}
                  <div style={{marginTop: '20px'}} className='Button-Group'>
                    <button 
                      style={{backgroundColor: '#D85C5C', border: '1px solid #D85C5C'}}
                      className='Primary-Button' 
                      onClick={() => deleteAdmin(modalState.removeModalAdmin?.emailaddress)}
                      // Benno ~ Added type 'button' to prevent form submission
                      type='button'
                    > 
                      Remove
                    </button>
                    <button 
                      className='Secondary-Button'
                      style={{color: '#D85C5C', border: '1px solid #D85C5C'}}
                      onClick={() => setModalState({ 'removeModalAdmin': undefined })}
                      // Benno ~ Added type 'button' to prevent form submission
                      type='button'
                    >
                      Cancel
                    </button>
                  </div>
    
                </div>
    
              </div>
            </div>
          </div>
        </div>
      )
    }

}
