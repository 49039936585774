//================================================================
//  Component: Remove Owner Modal
//================================================================

//  Purpose: Allows global administrators to delete an organisation administrator from an organisation
//  Properties:
//    - deleteOwnerObject={Object, from the table row}
//    - deleteOwnerIndex={number, from the table index}
//    - handleDeleteOwner={function, generic function to delete a user}
//    - setModalVisible = {setState, used to toggle the modal visibility}

//  Example:
//    <RemoveOwnerModal
//      deleteOwnerObject={deleteOwnerObject}
//      deleteOwnerIndex={deleteOwnerIndex}
//      handleDeleteOwner={handleDeleteOwner}
//      setModalVisible={setModalVisible}
//    ></RemoveOwnerModal>    

//================================================================


//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images
import DeleteIcon from '../../../../Components/Images/Icon_Delete_Red.svg';

//CSS
import '../../AppSettings';


export default function RemoveOwnerModal({
  deleteOwnerObject,
  deleteOwnerIndex,
  handleDeleteOwner,
  setModalVisible
}) {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Modal-Background'>
      <div className='AppSettings-Modal-Worker'>
        <div className='Action-Modal-Container'>

          {/* Modal Icon */}
          <div className='Action-Modal-Icon'>
            <img src={DeleteIcon} alt='Delete-Icon'></img>
          </div>
          
          {/* Modal Body */}
          <div className='Action-Modal-Body'>

            <h4 style={{marginTop: '14px'}}>
              Remove Organisation Owner
            </h4>

            <p> 
              Are you sure you want to remove <strong>{deleteOwnerObject.emailaddress}</strong> from this organisation?
              <br></br><br></br>
              The account will be <strong>permanently deleted</strong> and cannot be recovered.
            </p>

            {/* Invite User Form */}
            <div className='Action-Modal-Body-Form'>
              
              {/* Submit Button */}
              <div style={{marginTop: '20px'}} className='Button-Group'>
                <button 
                  style={{backgroundColor: '#D85C5C', border: '1px solid #D85C5C'}}
                  className='Primary-Button' 
                  onClick={() => handleDeleteOwner(deleteOwnerObject, deleteOwnerIndex)}
                  // Benno ~ Added type 'button' to prevent form submission
                  type='button'
                > 
                  Remove
                </button>
                <button 
                  className='Secondary-Button'
                  style={{color: '#D85C5C', border: '1px solid #D85C5C'}}
                  onClick={() => setModalVisible(false)}
                  // Benno ~ Added type 'button' to prevent form submission
                  type='button'
                >
                  Cancel
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
