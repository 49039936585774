//================================================================
//  Page: Send for Security Officer Approval 
//================================================================

//  Purpose: This is a modal to send a prequalficiation application to the security office for approval

//  Example:
// <ApplicationSendforApprovalModal
//    modalVisibility={modalVisibility.sendForApprovalModalVisible}
//    setModalVisibility={setModalVisibility}
//    prequalificationDoc={formData.prequalificationDoc}
// ></ApplicationSendforApprovalModal>

//================================================================

//Libraries
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Contexts

//Components

//Functions
import WriteDocument from '../../Library/WriteDocument';

//Images
import SendForApprovalIcon from '../../Components/Images/SendForApproval_Icon.svg';

//CSS
import '../../../src/Components/ApplicationApprovalsModals/ApplicationApprovalsModals.css'

export default function ApplicationSendforApprovalModal({
  modalVisibility,
  setModalVisibility,
  prequalificationDoc,
  currentUser
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate();
  const [searchParms] = useSearchParams();
  const projectid = searchParms.get('project');

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set modal status > 'onload', 'error', 'success' - success = modal closed and back to project prequal page
  const [modalStatus, setModalStatus] = useState('onload');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  function updatePrequalificationDocStatus() {

    const newAuditHistory = {
      actionedby: currentUser.emailaddress,
      actiondate: new Date(),
      type: 'Pending Security Approval',
      message: 'Prequalification submission sent to the Security Officer for approval.'
    };

    const existingAuditHistory = prequalificationDoc.audithistory;

    existingAuditHistory.push(newAuditHistory);

    const updatedDocument = {
      'status': 'pendingapproval',
      'audithistory': existingAuditHistory
    }

    WriteDocument('prequalifications', prequalificationDoc.prequalificationid, updatedDocument, true)
      .then(() => {

        setModalStatus('success')
        navigate(`/prequalifications?project=${projectid}`)

      }).catch(() => {

        setModalStatus('error');

      })
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Keeps modal closed while not in use
  if (!modalVisibility) return null;

  // Onload state when Send for Approval option on Approve PreQualifications-DropDown selected
  if (modalStatus === 'onload') {
    return (
      <div className='Modal-Background' onClick={() => setModalVisibility({ 'sendForApprovalModalVisible': false })}>
        <div className='Send-Approval-Application-Modal-Container'>
          <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>

            {/* Modal Icon */}
            <div className='Action-Modal-Icon'>
              <img src={SendForApprovalIcon} alt='Send for Approval Icon'></img>
            </div>

            {/* Modal Text Body */}
            <div className='Action-Modal-Body'>
              <h4>Send for Security Officer Approval</h4>

              <p>Please ensure you have completed the checklist below before clicking <b>Confirm</b> to update the status of this application to Pending Approval.</p>
              <u>Checklist</u>
              <p>1. Completed the security approval form using the details on this application.</p>
              <p>2. Sent an email with the attached form to the security officer for approval.</p>

              <div className='Action-Modal-Button-Container '>
                {/* Confirm Button */}
                <button className='Primary-Button' style={{backgroundColor: '#E4BA25', borderColor: '#E4BA25'}} onClick={() => updatePrequalificationDocStatus()}> Confirm </button>

                {/* Cancel Button */}
                <button className='Secondary-Button' style={{borderColor: '#4B4B4B', color: '#4B4B4B'}} onClick={() => setModalVisibility({ 'sendForApprovalModalVisible': false })}> Cancel </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }



  // Modal Error State if WriteDocument failed
  else if (modalStatus === 'error') {
    return (
      <div className='Modal-Background' onClick={() => setModalVisibility({ 'sendForApprovalModalVisible': false })}>
        <div className='Send-Approval-Application-Modal-Container'>
          <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>

            {/* Modal Header */}
            <div className='Action-Modal-Icon'>
              <img src={SendForApprovalIcon} alt='Send for Approval Icon'></img>
            </div>

            {/* Modal Text Body */}
            <div className='Action-Modal-Body'>
              <h4>Send for Security Officer Approval</h4>

              <p>
                  Oops something went wrong!
                  <br></br>
                  There was an error processing your request.
              </p>

              <div>
                <button className='Primary-Button' style={{backgroundColor: '#E4BA25', borderColor: '#E4BA25'}} onClick={() => setModalVisibility({ 'sendForApprovalModalVisible': false })}>Go Back</button>
              </div>
            </div>

          </div>
        </div>
      </div >
    )
  }
}