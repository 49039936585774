//================================================================
//  Component: PageHeader
//================================================================

//  Purpose: Standard page header for this portal

//  Properties:
//    - icon = <HTML> - <img> tag containing icon 
//    - title = <String> or <HTML> title of the header
//    - backToPage = <Optional> <String> of page route

//  PageHeader:
//    <PageHeader
//      icon={<img src={CreateProjectIcon} alt='Create Project Icon'></img>}
//      title={'Create Project'}
//      backToPage={'/projects'}
//    ></PageHeader>   

//================================================================

//Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

//CSS
import './PageHeader.css';


export default function PageHeader({
  icon,
  title,
  backToPage,
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const navigate = useNavigate();

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='PageHeader-Container'>

      {/* Icon */}
      <div className='PageHeader-Icon'>
        {icon}
      </div>

      <div className='PageHeader-Title'>

        {/* Title */}
        {title}
        
        {/* Optional Back Button */}
        {
          backToPage !== undefined && (
            <button className='Primary-Button' onClick={() => navigate(backToPage)}>Back</button>
          )
        }
    
      </div>

    </div>
  )
}
