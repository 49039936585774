//================================================================
//  Component: Remove Worker Modal
//================================================================

//  Purpose: Allows global administrators to delete a worker or an org admin from an organisation

//  Properties:
//    - userObject = {object, users object from the table row}
//    - setModalVisible = {A setState function - used to toggle the modal visibility}

//  Example:
//    <RemoveWorkerModal
//      userObject={userObject}
//      setModalVisible={setModalVisible}
//    ></RemoveWorkerModal>    

//================================================================


//Libraries
import React, { useContext, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../../Library/FirebaseConfig';
import { doc, updateDoc, arrayRemove, getFirestore } from 'firebase/firestore';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components

//Functions
import writeDocument from '../../../../Library/WriteDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Images
import DeleteIcon from '../../../../Components/Images/Icon_Delete_Red.svg';
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';

//CSS
import '../../AppSettings.css';


export default function RemoveWorkerModal({
  formData,
  setFormData,
}) {

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

  const getUser = useContext(GetUser)

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

  // Used to determine the state of the modal > 'pending', 'onload', 'error-fatal'
  const [modalStatus, setModalStatus] = useState('onload');

  // Firebase error message
  const [modalErrors, setModalErrors] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  function handleDeleteUser(){

    setModalStatus('pending');

    const docPromises = []
    const orgUpdatePromises = []

    // If the user is a global admin/project admin > Update User Document
    if (formData.removeModalWorker?.globalAdmin === true || formData.removeModalWorker?.projectAdmin.length > 0) {

      const updatedDoc = {
        'organisationid': '',
        'organisationname': '',
        'orgAdmin': []
      }

      docPromises.push(
        writeDocument('users', formData.removeModalWorker?.emailaddress, updatedDoc, true)
      )

      // Firestore Client ~ Allows the update of elements in an array
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const orgRef = doc(db, 'organisations', formData.removeModalWorker?.organisationid);

      // Remove from workers array in org doc
      orgUpdatePromises.push(
        updateDoc(orgRef, {
          workers: arrayRemove(formData.removeModalWorker?.emailaddress)
        })
      );

    // Else worker/org admins > Change status to 'inactive'
    } else {

      // We ALWAYS delete org admins/workers to PII data is removed
      const document = {
        'status': 'inactive',
        'decommissiondate': new Date ()
      };

      docPromises.push(
        writeDocument('users', formData.removeModalWorker?.emailaddress, document, true)
      )

    }

    Promise.all([...docPromises, ...orgUpdatePromises])
    .then(() => {

      // Auditing
      const message = `${getUser.emailaddress} -- Deleted worker ${formData.removeModalWorker?.emailaddress} from organisation ${formData.removeModalWorker?.organisationname}.`;
      const types = [
        'app settings'
      ];
      const references = [
        formData.removeModalWorker?.organisationname,
        getUser.emailaddress,
        'organisations',
      ];

      writeAuditLog(getUser.emailaddress, message, types, references)
      .then(() => {
          window.location.reload();

      });

    })
    .catch((error) => {
      setModalErrors(error);
      setModalStatus('error-fatal');

    })

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (formData.removeModalWorker === undefined) return null;

    //====================================================
    //  Pending
    //====================================================

    if (modalStatus === 'pending') {

      return (
        <div className='Modal-Background'>
          <dialog className='AppSettings-Modal-Worker'>
            <div className=' flex justify-center'>
              <img alt="loading-circle-icon" src={LoadingIcon} width='200px'></img>
            </div>
          </dialog>
        </div>
      )
    }

  //====================================================
  //  Error-Fatal
  //====================================================

  else if (modalStatus === 'error-fatal') {

    return (
      <div className='Modal-Background'>
        <dialog className='AppSettings-Modal-Worker'>
          <div className='flex flex-col text-center gap-2'>
              <h4>Oops something went wrong!</h4>
              
              <p className=''>
                An unknown error has occurred while attempting to delete <strong>{formData.removeModalWorker?.emailaddress}</strong>
                <br></br><br></br>
                If the error persists, please speak with your administrator. 
              </p>
              <p > 
                <b>Message:</b> {modalErrors}
              </p>

              <div>
                <button className='Primary-Button' style={{ backgroundColor: '#D85C5C', borderColor: '#D85C5C' }} onClick={() => setFormData({ 'removeModalWorker': undefined })}>Go Back</button>
              </div>
          </div>
        </dialog>
      </div>
    )
  }

  //====================================================
  //  OnLoad
  //====================================================

  return (
    <div className='Modal-Background'>
      <div className='AppSettings-Modal-Worker'>
        <div className='Action-Modal-Container'>

          {/* Modal Icon */}
          <div className='Action-Modal-Icon'>
            <img src={DeleteIcon} alt='Delete-Icon'></img>
          </div>
          
          {/* Modal Body */}
          <div className='Action-Modal-Body'>

            <h4 style={{marginTop: '14px'}}>
              Delete Worker
            </h4>

            <p> 
              Are you sure you want to delete <strong>{formData.removeModalWorker?.emailaddress}</strong>?
            </p>
            
            <p className='m-0'>
              All PII data for this worker will be <strong>permanently deleted</strong> and cannot be recovered.
            </p>

            <p className='m-0' hidden={formData.removeModalWorker.role === 'Worker'}>
              <b> Note: </b>  This will also remove this worker's administrator role for this organisation.
            </p>

            {/* Invite User Form */}
            <div className='Action-Modal-Body-Form'>
              
              {/* Submit Button */}
              <div style={{marginTop: '20px'}} className='Button-Group'>
                <button 
                  style={{backgroundColor: '#D85C5C', border: '1px solid #D85C5C'}}
                  className='Primary-Button' 
                  onClick={() => handleDeleteUser()}
                  type='button'
                > 
                  Delete
                </button>
                <button 
                  className='Secondary-Button'
                  style={{minWidth: '100px', color: '#D85C5C', borderColor: '#D85C5C'}} 
                  onClick={() => setFormData({'removeModalWorker': undefined})}
                  type='button'
                >
                  Cancel
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
