//================================================================
//  Page: Reject Application Modal 
//================================================================

//  Purpose: This is a modal to reject a prequalification application

//  Example
// <ApplicationRejectApplicationModal
//     modalVisibility={modalVisibility.rejectModalVisible}
//     setModalVisibility={setModalVisibility}
//     prequalificationDoc={formData.prequalificationDoc}
//     currentUser={currentUser}
// ></ApplicationRejectApplicationModal>

//================================================================

//Libraries
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Contexts

//Components

//Functions
import WriteDocument from '../../Library/WriteDocument';

//Images
import RejectApplicationIcon from '../../Components/Images/Icon_Reject_Red.png';


//CSS
import '../ApplicationApprovalsModals/ApplicationApprovalsModals.css'

export default function ApplicationRejectApplicationModal({
  modalVisibility,
  setModalVisibility,
  prequalificationDoc,
  currentUser
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate();
  const [searchParms] = useSearchParams();
  const projectid = searchParms.get('project');

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set modal status > 'onload', 'error', 'success' - success = modal closed and back to project prequal page
  const [modalStatus, setModalStatus] = useState('onload');

  // Used to store optional rejection reason
  const [rejectApplicationComment, setRejectApplicationComment] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  function updatePrequalificationDocStatus() {

    const newAuditHistory = {
      actionedby: currentUser.emailaddress,
      message: rejectApplicationComment,
      actiondate: new Date(),
      type: 'Rejected'
    };

    const existingAuditHistory = prequalificationDoc.audithistory;

    existingAuditHistory.push(newAuditHistory);

    const updatedDocument = {
      'status': 'rejected',
      'audithistory': existingAuditHistory
    }


    WriteDocument('prequalifications', prequalificationDoc.prequalificationid, updatedDocument, true)
      .then(() => {

        setModalStatus('success')
        navigate(`/prequalifications?project=${projectid}`)

      }).catch(() => {

        setModalStatus('error');

      })
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Keeps modal closed while not in use
  if (!modalVisibility) return null;

  // Onload state when Send for Approval option on Approve PreQualifications-DropDown selected
  if (modalStatus === 'onload') {
    return (
      <div className='Modal-Background' onClick={() => setModalVisibility({ 'rejectModalVisible': false })}>
        <div className='Reject-Application-Modal-Container'>
          <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>

            {/* Modal Icon */}
            <div className='Action-Modal-Icon'>
              <img src={RejectApplicationIcon} alt='Reject Application Icon' width='63px'></img>
            </div>

            {/* Modal Text Body */}
            <div className='Action-Modal-Body'>
              <h4>Reject Application</h4>

              <p>Are you sure you want to reject this application?</p>
              <p style={{fontWeight: '500', marginBottom: '10px'}}>Reason for Rejection (Optional)</p>

              <textarea 
                rows='5' 
                cols='10'
                className='mb-[10px] p-[10px] border-1 border-[#D8D8D8] rounded-sm'
                onChange={(e) => setRejectApplicationComment(e.target.value)}
                style={{marginBottom:'10px', padding: '10px'}}
              ></textarea>

              <div className='Action-Modal-Button-Container'>
                {/* Reject Button */}
                <button className='Primary-Button' style={{backgroundColor: '#D85C5C', borderColor: '#D85C5C'}} onClick={() => updatePrequalificationDocStatus()}> 
                  Reject
                </button>

                {/* Cancel Button */}
                <button className='Secondary-Button' style={{borderColor: '#4B4B4B', color: '#4B4B4B'}}onClick={() => setModalVisibility({ 'rejectModalVisible': false })}> 
                  Cancel
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }

  // Modal Error State if WriteDocument failed

  else if (modalStatus === 'error') {
    return (
      <div className='Modal-Background' onClick={() => setModalVisibility({ 'rejectModalVisible': false })}>
        <div className='Reject-Application-Modal-Container'>
          <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>

            {/* Modal Icon */}
            <div className='Action-Modal-Icon'>
              <img src={RejectApplicationIcon} alt='Reject Application Icon' width='63px'></img>
            </div>

            {/* Modal Text Body */}
            <div className='Action-Modal-Body'>
              <h4>Reject Application</h4>

              <p>
                  Oops something went wrong!
                  <br></br>
                  There was an error processing your request.
              </p>

              <div className='Action-Modal-Button-Container'>
                  <button className='Primary-Button' style={{backgroundColor: '#D85C5C', borderColor: '#D85C5C'}} onClick={() => setModalVisibility({ 'rejectModalVisible': false })}>Go Back</button>
              </div>
            </div>

          </div>
        </div>
      </div >
    )
  }
}