//================================================================
//  Page: Request More Information Modal 
//================================================================

//  Purpose: This is a modal to request more information on a prequalification application

//  Example
//   <ApplicationRequestMoreInformationModal
//      modalVisibility={modalVisibility.requestMoreInfoVisible}
//      setModalVisibility={setModalVisibility}
//      prequalificationDoc={formData.prequalificationDoc}
//      currentUser={currentUser}
//   ></ApplicationRequestMoreInformationModal>

//================================================================

//Libraries
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Contexts

//Components

//Functions
import WriteDocument from '../../Library/WriteDocument';

//Images
import RequestMoreInfoIcon from '../../Components/Images/RequestMoreInfo_Icon.svg';


//CSS
import '../ApplicationApprovalsModals/ApplicationApprovalsModals.css'

export default function ApplicationRequestMoreInformationModal({
  modalVisibility,
  setModalVisibility,
  prequalificationDoc,
  currentUser
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate();
  const [searchParms] = useSearchParams();
  const projectid = searchParms.get('project');

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set modal status > 'onload', 'error', 'success' - success = modal closed and back to project prequal page
  const [modalStatus, setModalStatus] = useState('onload');

  // Used to store comments by the project admin
  const [requestMoreInfoComment, setRequestMoreInfoComment] = useState('');

  // Used to determine if the comment is valid
  const [requestMoreInfoValid, setRequestMoreInfoValid] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  function updatePrequalificationDocStatus() {

    const newAuditHistory = {
      actionedby: currentUser.emailaddress,
      message: requestMoreInfoComment,
      actiondate: new Date(),
      type: 'Requested More Information'
    };

    const existingAuditHistory = prequalificationDoc.audithistory;

    existingAuditHistory.push(newAuditHistory);

    const updatedDocument = {
      'status': 'moreinfo',
      'audithistory': existingAuditHistory
    }

    WriteDocument('prequalifications', prequalificationDoc.prequalificationid, updatedDocument, true)
    .then(() => {
      setModalStatus('success');
      navigate(`/prequalifications?project=${projectid}`);

    })
    .catch(() => {
      setModalStatus('error');

    })
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // =============================================
    //  Modal Hidden
    // =============================================

    if (!modalVisibility) return null;

    // =============================================
    //  Onload
    // =============================================

    if (modalStatus === 'onload') {
      return (
        <div className='Modal-Background' onClick={() => setModalVisibility({ 'requestMoreInfoVisible': false })}>
          <div className='Request-More-Info-Application-Modal-Container'>
            <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>

              {/* Modal Icon */}
              <div className='Action-Modal-Icon'>
                <img src={RequestMoreInfoIcon} alt='Request More Information Icon'></img>
              </div>

              {/* Modal Text Body */}
              <div className='Action-Modal-Body'>
                <h4>Request More Information</h4>

                <p>Return this prequalification to the worker for further information necessary for a decision to be made.</p>
                <p style={{marginBottom: '5px'}}>Please specify the information required.</p>
                <textarea 
                  rows='5' 
                  cols='10'
                  className='mb-[10px] p-[10px] border-1 border-[#D8D8D8] rounded-sm'
                  onChange={(e) => {
                    // Checks that the field is completed and makes button valid when it contains text
                    if (e.target.value.length > 0) {
                      setRequestMoreInfoValid(true)
                    }
                    setRequestMoreInfoComment(e.target.value)
                  }}
                  value={requestMoreInfoComment}
                ></textarea>

                <div className='Action-Modal-Button-Container '>
                  {/* Confirm Button */}
                  <button className='Primary-Button' style={{backgroundColor: '#E47525', borderColor: '#E47525'}} disabled={!requestMoreInfoValid} onClick={() => updatePrequalificationDocStatus()}> Send </button>

                  {/* Cancel Button */}
                  <button className='Secondary-Button' style={{borderColor: '#4B4B4B', color: '#4B4B4B'}} onClick={() => setModalVisibility({ 'requestMoreInfoVisible': false })}> Cancel </button>
                </div>
                
              </div>

            </div>
          </div>
        </div >
      )
    }

    // =============================================
    //  Error
    // =============================================
    else if (modalStatus === 'error') {
    return (
      <div className='Modal-Background' onClick={() => setModalVisibility({ 'requestMoreInfoVisible': false })}>
        <div className='Request-More-Info-Application-Modal-Container'>
          <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>

            {/* Modal Icon */}
            <div className='Action-Modal-Icon'>
              <img src={RequestMoreInfoIcon} alt='Request More Information Icon'></img>
            </div>

            {/* Modal Text Body */}
            <div className='Action-Modal-Body'>
              <h4>Request More Information</h4>

              <p>
                  Oops something went wrong!
                  <br></br>
                  There was an error processing your request.
              </p>

              <div className='Action-Modal-Button-Container'>
                  <button className='Primary-Button' style={{backgroundColor: '#E47525', borderColor: '#E47525'}} onClick={() => setModalVisibility({ 'requestMoreInfoVisible': false })}>Go Back</button>
              </div>
            </div>

          </div>
        </div>
      </div >
    )
    }
}