//================================================================
//  Page: ProjectSettings 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

//Contexts
import { GetUser, GetProjectSelector} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import TabView from '../../Components/TabView/TabView';
import MarkCompleteModal from './Components/Overview/MarkCompleteModal';
import Overview from './Components/Overview/Overview';
import Organisations from './Components/Organisations/Organisations';
import ProjectDelete from './Components/ProjectDelete/ProjectDelete';

//Functions

//Images
import CreateProjectIcon from '../../Components/Images/Icon_CreateProject_Blue.svg';

//CSS
import './ProjectSettings.css';
import GetDocument from '../../Library/GetDocument';


export default function ProjectSettings() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const [ searchParms ] = useSearchParams();
    const projectid = searchParms.get('project');

    const getUser = useContext(GetUser);
    const getProjectSelector = useContext(GetProjectSelector);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-other', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Toggle overview model
    const [modalVisible, setModalVisible] = useState(false);

  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onChange
    //  1. Check the user has access to the project
    useEffect(() => {

      // Conditionals
      if (projectid === undefined) return setPageStatus('accessdenied');
      if (projectid === null) return setPageStatus('accessdenied');
  
      GetDocument('projects', projectid).then(() => {

        setPageStatus('onload');

      }).catch(() => {

        setPageStatus('accessdenied');

      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProjectSelector]);

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requiredRoles={ ['globalAdmin', 'projectAdmin'] }
        requiredRolesValues={ [true, projectid] }
        status={pageStatus}
        header={
          <PageHeader
            icon={<img src={CreateProjectIcon} alt='Create Project Icon'></img>}
            title={'Project Settings'}
          ></PageHeader>
        }
        body={
          <TabView
            defaultView={1}

            // =================================================
            //                    Overview
            // =================================================

            oneTitle={'Overview'}
            oneContent={
              <div className='Page-Body-Container'>

                {/* Project Name Header */}
                <div className='ProjectSetting-Header pb-2 border-b border-b-[#D8D8D8]'>

                  <h5>{getProjectSelector?.selectedProject?.projectname}</h5>

                  {/* Mark Project Complete */}
                  {
                    getUser.roles.globalAdmin && 
                    <button className='Primary-Button' onClick={() => setModalVisible(true)}> Mark Project Complete </button>
                  }
                  {
                    modalVisible && (
                      <MarkCompleteModal
                        projectid={projectid}
                        setModalVisible={setModalVisible}
                      ></MarkCompleteModal>
                    )
                  }

                </div>

                <Overview
                  pageStatus={pageStatus}
                  setPageStatus={setPageStatus}
                ></Overview>

              </div>
            }
            oneRoute={`/projectsettings/?project=${projectid}&view=overview`}

            // =================================================
            //                    Organisations
            // =================================================

            twoTitle={'Organisations'}
            twoContent={
              <Organisations
                selectedProject={getProjectSelector?.selectedProject}
              ></Organisations>
            }
            twoRoute={`/projectsettings/?project=${projectid}&view=organisations`}

            // =================================================
            //                    Project Deletion
            // =================================================

            threeTitle={getUser.roles.globalAdmin === true ? 'Project Deletion' : undefined}
            threeContent={
              getUser.roles.globalAdmin === true && 
              (
                <ProjectDelete
                  selectedProject={getProjectSelector?.selectedProject}
                ></ProjectDelete>
              )
            }
            threeRoute={`/projectsettings/?project=${projectid}&view=projectdelete`}

          ></TabView>    
        }
      ></PageComponent>
    )
}