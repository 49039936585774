//================================================================
//  Component: Upload New NDA
//  Created by Mark Bennett/Nowshin Hassan
//================================================================

//  Purpose: This component handles the uploading of a new NDA file to a GCS bucket

//  Properties:
//  - formData = {useState, used to store file inputs for form}
//  - setFormData = {useState, used to update useState with uploaded files url}

//  Example:
//    <UploadNewNDA
//      formData={formData}
//      setFormData={setFormData}
//     ></UploadNewNDA>

//================================================================


//Libraries
import React, { useState, useContext, useEffect } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import UploadFile from '../../../Library/UploadFile';

//Images
import LoadingIcon from '../.././../Components/Images/Icon_LoadingFile_Grey.gif';

//CSS
import '../CreateProject.css';


export default function UploadNewNDA({
  formData,
  setFormData,
}) {

  //------------------------------------------------------
  //  useContext
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates & useRef
  //------------------------------------------------------

    // Handles the state of the upload > 'onchange', 'pending', 'success', 'error-fatal'
    const [uploadStatus, setUploadStatus] = useState('onchange');

    // Used to store the file URL
    const [disclosureFileUrl, setDisclosureFileUrl] = useState('');

    // Any error messages
    const [errorMessage, setErrorMessage] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // File Selector > Event Handler
    function handleChange(file){

      //------------------------------------------------------
      //  Validate incoming file
      //------------------------------------------------------

      if (!file) return setErrorMessage('Select a file');
      
      let fileType;
      try {
        fileType = file.name.split('.')
        fileType = fileType[fileType.length - 1].toLowerCase();

      } catch (error) {
        
        return setErrorMessage('Supported file types include png, jpeg, jpg and pdf.');

      }

      //------------------------------------------------------
      //  Client side validation --> Otherwise the upload MUST complete before the storage rules reject the request
      //------------------------------------------------------

      if (file.size > 20000000) return setErrorMessage('File must be smaller then 20MB');
      if (fileType !== 'png' && fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'pdf') return setErrorMessage('Supported file types include png, jpeg, jpg and pdf.');

      
      //------------------------------------------------------
      //  Passed all checks > proceed with upload
      //------------------------------------------------------

      setErrorMessage('');
      setFormData({'disclosureFileName': file.name});
      setUploadStatus('pending');

      //Upload the file to GCS
      UploadFile(`tempuploads/${getUser?.emailaddress}/${formData?.disclosureFileId}.${fileType}`, file)
      .then((url) =>{

        // Save the file URL and file name to formData
        setDisclosureFileUrl(url);
        setUploadStatus('success');

      })
      .catch((error) =>{

        setErrorMessage(error.message);
        setUploadStatus('error-fatal');

      });

    }

    // Try Again Button Handler
    function handleUploadStatus(status){

      setErrorMessage('');
      setUploadStatus(status);

    }

    // Clear Button Handler
    function clearFileSelector() {

      setDisclosureFileUrl('');
      setFormData({'disclosureFileName': ''});
      handleUploadStatus('onchange');

    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Used to determine if the error message is visible
    useEffect(() => {
      
      if(errorMessage.length === 0) {
        setFormData({'disclosureValid': true});

      } else {
        setFormData({'disclosureValid': false})

      }

    // eslint-disable-next-line 
    }, [errorMessage])
    

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  onchange
    //------------------------------------------------------

      if (uploadStatus === 'onchange') {

        return (
          <div>
            <div className='Upload-NDA-Onload-Container'>

              {/* File Input Field */}
              <input
                className={formData.disclosureValid ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                style={{padding: '0px'}}
                type='file'
                onChange={(e) => handleChange(e.target.files[0])} 
              ></input>
            
              {/* Clear Button */}
              <button className='Primary-Button' disabled={formData.disclosureFileName.length === 0 ? true : false} onClick={() => clearFileSelector()} > Clear </button>
            </div>

            {/* Error Message */}
            {!formData.disclosureValid && <label className='Upload-NDA-Error-Message' style={{padding: '15px 0px'}}>{errorMessage}</label>}

          </div>
        )

      }

    //------------------------------------------------------
    //  pending
    //------------------------------------------------------

    else if (uploadStatus === 'pending') {

      return (
        <div className='Upload-NDA-Pending-Container'>
          <img src={LoadingIcon} alt='loading-icon' width='30px' height='30px'></img>
          Uploading...
        </div>
      )

    }

    //------------------------------------------------------
    //  success
    //------------------------------------------------------

    else if (uploadStatus === 'success') {

      return(
        <div className='Upload-NDA-Success-Container'>

          {/* File Link */}
          <a href={disclosureFileUrl} target='_blank' rel='noreferrer'>{formData.disclosureFileName}</a> 

          {/* Clear Button */}
          <button className='Primary-Button' onClick={() => clearFileSelector()} > Clear </button>
        
        </div>
      )

    }

    //------------------------------------------------------
    //  error-fatal
    //------------------------------------------------------

    else if (uploadStatus === 'error-fatal') {

      return(
        <div className='Upload-NDA-Error-Container'>
          Failed to upload file, please try again.

          {/* Try Again */}
          <button className='Primary-Button' style={{width: 'fit-content'}} disabled={formData.disclosureFileName.length === 0 ? true : false} onClick={() => handleUploadStatus('onchange')}>Try Again</button>
        </div>
      )

    }

    //------------------------------------------------------
}
