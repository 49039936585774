//================================================================
//  Component: Invite User Modal
//================================================================

//  Purpose: Allows global administrators to invite other global administrators

//  Properties:
//    - setModalVisible = {A setState function - used to toggle the modal visibility}

//  Example:
//    <MarkCompleteModal
//      setModalVisible={setModalVisible}
//    ></MarkCompleteModal>    

//================================================================


//Libraries
import React, { useReducer, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, GetProjectSelector } from '../../../../Library/GlobalContexts';

//Components

//Functions
import writeDocument from '../../../../Library/WriteDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Images
import ApprovedIcon from '../../../../Components/Images/Icon_Approved_Blue.svg';

//CSS
import '../../ProjectSettings.css';


export default function MarkCompleteModal({
  projectid,
  setModalVisible
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const navigate = useNavigate();

    const getUser = useContext(GetUser);
    const getProjectSelector = useContext(GetProjectSelector);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'date': '',
        'preventSubmit': true,
        'error': '',
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Update project document
    function submitHandler(){

      const document = {
        'completeddate': formData.date,
        'status': 'complete',
      };
      writeDocument('projects', projectid, document, true).then(() => {

        // Auditing
        const message = `${getUser.emailaddress} -- Marked project ${getProjectSelector?.selectedProject?.projectname} as completed.`;
        const types = [
          'project history'
        ];
        const references = [
          getProjectSelector?.selectedProject?.projectname,
          getUser.emailaddress,
          'projects',
        ];
        return writeAuditLog(getUser.emailaddress, message, types, references).then(() => {

          getProjectSelector.selectedProject = undefined;
          navigate('/home');
    
        });

      }).catch(() => {

        setFormData({'error': 'Failed to mark project as completed, try again later.'});

      });

    };


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Modal-Background'>
      <div className='ProjectSetting-Modal'>
        <div className='Action-Modal-Container'>

          {/* Modal Icon */}
          <div className='Action-Modal-Icon'>
              <img src={ApprovedIcon} alt='Invite User Icon'></img>
          </div>
          
          {/* Modal Body */}
          <div className='Action-Modal-Body'>

            <h4 style={{marginTop: '14px'}}>
              Mark Project Complete
            </h4>

            <p>Are you sure you want to mark this project as complete?</p>
            <p>If yes, enter the completion date below.</p>

            {/* Date Picker */}
            <div className='Action-Modal-Body-Form'>
              
              <input
                type='date'
                style={{cursor: 'text'}}
                onChange={(e) => {

                  const value = e.target.value;
                  if (value === undefined || value === '') {

                    formData.date = '';
                    formData.preventSubmit = true;

                  } else {

                    formData.date = new Date(e.target.value);
                    formData.preventSubmit = false;
                    
                  };

                  setFormData(formData);

                }}
              ></input>

              {/* Submit Button */}
              <div style={{marginTop: '20px'}} className='Button-Group'>
                <button 
                  className='Primary-Button' 
                  disabled={formData.preventSubmit} 
                  onClick={() => submitHandler()}
                > 
                  Confirm 
                </button>
                <button 
                  className='Secondary-Button'
                  onClick={() => setModalVisible(false)}
                  // Benno ~ Added type 'button' to prevent form submission
                  type='button'
                >
                  Cancel
                </button>
              </div>
              <div className='Input-Field-Text-Error-Message'>
                {formData.error}
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
