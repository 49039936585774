//================================================================
//  Component: Invite Global Admin Modal
//================================================================

//  Purpose: Allows global administrators to invite other global administrators

//  Properties:
//    - modalState = {useState - used to toggle visibility of modal}
//    - setModalState = {A setState function - used to toggle the modal visibility}

//  Example:
//  <InviteGlobalAdminModal 
//    modalState={pageState}
//    setModalState={setPageState} 
//  ></InviteGlobalAdminModal> 

//================================================================

//Libraries
import React, { useContext, useState, useEffect, useReducer } from 'react';

//Contexts
import { GetUser, SetError } from '../../../../Library/GlobalContexts';

//Components

//Functions
import GetDocument from '../../../../Library/GetDocument';
import WriteDocument from '../../../../Library/WriteDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Images
import InviteUser from '../../../../Components/Images/Icon_InviteUser_Blue.svg';
import AddButton from '../../../../Components/Images/Icon_Add_Teal.svg';
import AddButtonDisabled from '../../../../Components/Images/Icon_AddDisabled_Teal.svg';
import DeleteButton from '../../../../Components/Images/Icon_Delete_Red.svg';
import InfoRequired from '../../../../Components/Images/Icon_InfoRequired_Teal.svg';

//CSS

export default function InviteGlobalAdminModal({
  modalState,
  setModalState,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Used to determine the state of the modal > 'onload', 'error-fatal'
    const [modalStatus, setModalStatus] = useState('onload');
    
    // Used to determine if the 'Send Invite' button is active
    const [inviteButtonDisabled, setInviteButtonDisabled] = useState(true);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to save the form inputs (email address, given name and surname)
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'userEmail': '',
        'userGivenName': '',
        'userSurname': '',
        'userAllowAdd': false,
        'userErrorMessage': '',
        'userStatus': 'onload', // 'onload', 'pending', 'new-user', 'duplicate-user', 'invalid',
        'users': []
      }
    );

  //------------------------------------------------------
  //  Define variables
  //------------------------------------------------------

    const allGlobalAdmins = modalState?.globalAdmins.map(admin => admin.emailaddress);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used to get values from the email address input field
    function handleChangeUser(value) {

      if (value.length === 0) {

        return setFormData({
          'userEmail': value,
          'userAllowAdd': false
        });

      }

      // Validate email address
      if (/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)) {

        return setFormData({
          'userEmail': value,
          'userStatus': 'onload',
          'userErrorMessage': '',
          'userAllowAdd': true,

        });

      } 

      // Invalid email address
      setFormData({
        'userEmail': value,
        'userStatus': 'onload',
        'userErrorMessage': '',
        'userAllowAdd': false,
      });

    }

    // Used to add a new user to a users list 
    function addUser() {

      // Check field is not empty
      if (formData?.userEmail.length === 0) {

        formData.userStatus = 'invalid';
        formData.userErrorMessage = 'Please enter an email address';
        return setFormData(formData);

      }

      // Validate domain --> We only allow lendlease.com email addresses
      if ('lendlease.com' !== formData?.userEmail.split('@')[1]) {

        formData.userStatus = 'invalid';
        formData.userErrorMessage = 'Only lendlease.com email addresses are allowed.';
        return setFormData(formData);

      }

      // Check for duplicates
      if (formData?.users.filter((object) => object.emailaddress === formData?.userEmail).length > 0) {

        formData.userStatus = 'duplicate-user';
        formData.userErrorMessage = 'This user already exists below.';
        return setFormData(formData);

      }

      // Check if user is already a global admin
      if (allGlobalAdmins?.includes(formData?.userEmail)) {

        formData.userStatus = 'duplicate-user';
        formData.userErrorMessage = 'This user already exists.';
        return setFormData(formData);

      }

      // Check if user exists in Firestore 'users' collection
      return GetDocument('users', formData.userEmail)
      .then(() => {

        formData.users.push({
          'emailaddress': formData.userEmail,
          'type': 'existing'
        });

        formData.userEmail = '';
        formData.userErrorMessage = '';
        formData.userAllowAdd = false;
        formData.userStatus = 'onload';
        setFormData(formData);

      })
      .catch(() => {

        formData.userStatus = 'new-user';
        formData.userErrorMessage = '';
        formData.userAllowAdd = false;
        setFormData(formData);

      });

    }

    // Used to delete a user from the users list
    function deleteUser(index) {

      // Remove users from the users list
      formData.users.splice(index, 1)
      setFormData(formData)

    }

    //Used to add a new user (with given name and surname) to the users list
    function addNewUser() {

      formData.users.push({
        'emailaddress': formData.userEmail,
        'type': 'new',
        'givenname': formData.userGivenName.trim(),
        'surname': formData.userSurname.trim(),
      });

      formData.userStatus = 'onload';
      formData.userEmail = '';
      formData.userAllowAdd = false;
      formData.userGivenName = '';
      formData.userSurname = '';
      setFormData(formData);

    } 

    //Used to cancel adding a new user > Hide form
    function cancelNewUser() {

      formData.userStatus = 'onload';
      formData.userEmail = '';
      formData.userGivenName = '';
      formData.userSurname = '';
      setFormData(formData);

    }

    // Used to submit the form and invite added users
    function sendInvites() {

      // ----------------------------------------------------------
      // 0. Prepare Variables
      // ----------------------------------------------------------

      const newUsers = [];
      const existingUsers = [];
      setModalStatus('pending');

      // ----------------------------------------------------------
      // 1. Extract data from 'users' array
      // ----------------------------------------------------------

      formData.users.forEach((object) => {

        // Prepare New Users
        if(object.type === 'new') {

          newUsers.push({
            'emailaddress': object.emailaddress,
            'givenname': object.givenname,
            'surname': object.surname,
            'created': new Date(),
            'projectAdmin': [],
            'organisationid': '',
            'organisationname': 'Lendlease',
            'orgAdmin': [],
            'projects': [],
            'roles': {
              'globalAdmin': true,
              'lendleaseUser': true,
            },
            'status': 'active'
          });

        }

        // Prepare Existing Users
        if (object.type === 'existing') {

          existingUsers.push({
            'emailaddress': object.emailaddress,
            'roles': {
              'globalAdmin': true,
              'lendleaseUser': true,
            }

          });

        }

      })

      // ----------------------------------------------------------
      // 2. Write 'new users' to 'users' collection
      // ----------------------------------------------------------

      const newUserPromises = [];

      newUsers.forEach((document) => {
        newUserPromises.push(
          WriteDocument('users', document.emailaddress, document, true)
        )

      });

      // ----------------------------------------------------------
      // 3. Merge 'existing user' roles in their 'users' collection
      // ----------------------------------------------------------

      const existingUserPromises = [];

      existingUsers.forEach((document) => {
        existingUserPromises.push(
          WriteDocument('users', document.emailaddress, document, true)
        )

      });


      // ----------------------------------------------------------
      // 5. Settle Promises & Close Modal
      // ---------------------------------------------------------

      Promise.all([...newUserPromises, ...existingUserPromises]).then(() => {

        // Auditing
        formData.users.forEach((document) => {

          const message = `${getUser.emailaddress} -- Added Global Admin ${document.emailaddress}`;
          const types = [
            'app history'
          ];
          const references = [
            document.emailaddress,
            getUser.emailaddress,
            'users',
          ];
          
          writeAuditLog(getUser.emailaddress, message, types, references);

        });

        setModalState({ 'inviteModalVisible': false });

      })
      .catch((error) => {

        setError(`Failed to submit form, error: ${error}`);
        setModalStatus('error-fatal');
    
      });


    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Check is users list is populated and enable 'Send Invite' button
    useEffect(() => {
      
      if (formData.users.length > 0) {
        setInviteButtonDisabled(false)

      } else {
        setInviteButtonDisabled(true)

      }

    }, [formData])

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //====================================================
    //  Modal Hidden
    //====================================================
    
    if (modalState?.inviteModalVisible === false) return null;

    //====================================================
    //  Error-Fatal
    //====================================================

    if (modalStatus === 'error-fatal') {
      return (
        <div className='Modal-Background'>
          <div className='Invite-User-Container' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '40px 80px'}}>
            
            <h4>Oops, something went wrong.</h4>
            <p>
              An error occurred while we processed your request.
              <br></br>
              If the error persists, please speak with your administrator. 
            </p>
            <p> 
              <b className='font-semibold'>Message:</b> Failed to invite global administrator - Fatal Error.
            </p>
            <button className='Primary-Button' onClick={() => setModalState({ 'inviteModalVisible': false }) }>Cancel</button>
                    
          </div>
        </div>
      )

    }

    //====================================================
    //  Onload
    //====================================================

    else {
      return (
        <div className='Modal-Background'>
          <div className='Invite-User-Container'>
            <div className='Action-Modal-Container'>
    
              {/* Modal Icon */}
              <div className='Action-Modal-Icon'>
                <img src={InviteUser} alt='Invite User Icon'></img>
              </div>
              
              {/* Modal Body */}
              <div className='Action-Modal-Body'>
    
                <h4>Invite Users</h4>
    
                <p> Invite other Lendlease administrators to this portal by entering their email address and clicking the <b style={{fontWeight: '500'}}>Send Invite</b> button below. </p>
    
                {/* ================================================ */}
                {/*              Invite User Form                    */}
                {/*  1. Allows GA's to add existing Firestore users  */}
                {/*  2. Validates new users with a form              */}
                {/*  3. Saves results to useReducer                  */}
                {/* ================================================ */}            
                
                <div className='Action-Modal-Body-Form' id='inviteglobaladmins'>
    
                  {
                    // ========================================================
                    // All 'userStatus' states ---> 'EXCLUDING new-users'!
                    // ========================================================
    
                    formData.userStatus !== 'new-user' ? (
    
                      <div>
                        <label> Email Address </label>
    
                        {/* <-------------------INPUT FIELDS-----------------> */}
                      
                        <div className='Invite-User-Email-Container'>
    
                          <input 
                            required
                            name='emailaddress'
                            type='text' 
                            className={formData.userErrorMessage.length === 0 ? 'Action-Modal-Input-Field' : 'Action-Modal-Input-Field-Error'}
                            onChange={(e) => handleChangeUser(e.target.value)}
                            value={formData.userEmail}
                            autoComplete='off'
                          ></input>
    
                          {/* <-------------------ADD BUTTON-------------------> */}
                          {
                            formData.userAllowAdd === true ? 
                              <img className='w-[35px]' src={AddButton} alt='Add' onClick={() => addUser()} ></img> //Enable Button
                            : 
                              <img className='w-[35px]' src={AddButtonDisabled} alt='Add'></img> //Disable Button
                          }
    
                        </div>

                        {/* <-----------------ERROR MESSAGE------------------> */}
                        {
                          formData.userErrorMessage.length > 0 && 
                          (
                            <>
                            <p className='Action-Modal-Input-Error-Message'>{formData.userErrorMessage}</p>
                            </>
                          )
                        }
                        
                        {/* <------------------LIST OF USERS-----------------> */}
      
                        <div className='Invite-Users-List'>
                          {formData.users?.map((user, index) =>
                            (
                            <div className='Invite-User-Label-Container' key={index}>
                              <div>{user.emailaddress}</div>
                              <img className='w-[35px] mb-2 p-0' src={DeleteButton} alt='Delete User' onClick={() => deleteUser(index)}></img>
                            </div> 
                            )
                          )}
                        </div> 
    
                        {/* <------------------SUBMIT BUTTONS-----------------> */}
                        <div className='Button-Group'>
                          <button className='Primary-Button' disabled={inviteButtonDisabled} onClick={() => sendInvites()}> Send Invite </button>
                          <button className='Secondary-Button' onClick={() => setModalState({ 'inviteModalVisible': false }) }> Cancel </button>
                        </div>
    
                      </div>
    
                    ) :
                      
                    // ========================================================
                    //  New Users Form ---> formData.userStatus === 'new-users'
                    // ========================================================
    
                    (
    
                      <div className='New-User-Form'>
                        
                        {/* <-------------------HEADER--------------------> */}
                        <div className='New-User-Header'>
                          <img src={InfoRequired} alt='Info-Required-Icon' width='25px'></img>
                          MORE INFORMATION REQUIRED
                        </div>
                        
                        {/* <-----------------EMAIL ADDRESS---------------> */}
                        <div className='New-User-Email'>
                          <p> Email Address </p>
                          <input 
                            disabled
                            name='emailaddress'
                            type='text' 
                            className='Action-Modal-Input-Field'
                            value={formData.userEmail}
                            autoComplete='off'
                           ></input>
                        </div>
                        
                        {/* <-----------------USER NAME------------------> */}
                        <div className='New-User-Name'>
    
                          {/* Given Name */}
                          <div>
                            <p> Given Name </p>
                            <input 
                              required
                              name='givenname'
                              type='text' 
                              className={formData.userErrorMessage.length === 0 ? 'Action-Modal-Input-Field' : 'Action-Modal-Input-Field-Error'}
                              onChange={(e) => setFormData({'userGivenName': e.target.value})}
                              value={formData.userGivenName}
                            ></input>
                          </div>
                                          
                          {/* Surname */}
                          <div>
                            <p> Surname </p>
                            <input 
                              required
                              name='givenname'
                              type='text' 
                              className={formData.userErrorMessage.length === 0 ? 'Action-Modal-Input-Field' : 'Action-Modal-Input-Field-Error'}
                              onChange={(e) => setFormData({'userSurname': e.target.value})}
                              value={formData.userSurname}
                            ></input>
                          </div>
                        </div>
                        
                        {/* <-----------------ADD BUTTON---------------> */}
                        <div className='New-User-Buttons'>
    
                          {/* Disable '+ Add User' button if fields are empty */}
                          <button 
                            className='Primary-Button' 
                            style={{fontSize: '13px'}} 
                            onClick={() => addNewUser()}
                            disabled={
                              formData.userGivenName.length > 0 && 
                              formData.userSurname.length > 0 &&
                              modalStatus === 'onload' ? false : true
                            }
                          > 
                            + Add User 
                          </button>
                          
                          {/* Cancel Button > Hides new user form */}
                          <button className='Secondary-Button' style={{fontSize: '13px'}} onClick={() => cancelNewUser()}> Cancel </button>
    
                        </div>
    
                      </div>
    
                    )
                  }
    
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

}
