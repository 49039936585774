//================================================================
//  Component: Invite Worker Modal
//================================================================

//  Purpose: Allows global admins to invite new workers to their organisation

//  Properties:
//    - modalState = {useState - used to toggle visibility of modal}
//    - setModalState = {A setState function - used to toggle the modal visibility}

//  Example:
//  <InviteWorkerModal
//    modalState={modalState}
//    setModalState={setModalState}
//  ></InviteWorkerModal>

//================================================================

//Librariesis to top
import React, { useContext, useState, useReducer, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../../Library/FirebaseConfig';
import { doc, updateDoc, arrayUnion, getFirestore } from 'firebase/firestore';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import QueryListener from '../../../../Library/QueryListener';
import WriteAuditLog from '../../../../Library/WriteAuditLog';
import GetDocument from '../../../../Library/GetDocument';

//Images
import InviteWorker from '../../../../Components/Images/Icon_InviteUser_Blue.svg';
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';
import IconClose from '../../../../Components/Images/Icon_Close_Grey.svg';


export default function InviteWorkerModal({
  modalState,
  setModalState
}) {

  //------------------------------------------------------
  //  useContext
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Used to determine the state of the modal > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [modalStatus, setModalStatus] = useState('pending');
    
    // Used to determine if the 'Send Invite' button is active
    const [inviteButtonDisabled, setInviteButtonDisabled] = useState(true);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to save the form inputs (email address, given name and surname)
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'emailaddress': '',
        'givenname': '',
        'surname': '',
        'subbyorg': '',
        'organisation': '',
        'invalidErrorMessage': '',
        'fatalErrorMessage': '',
        'orgSearchInput': '',
        'searchResultsCount': '',
        'allOrgs': [],
        'filteredOrgs': []
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handle search
    function handleSearch(value) {

      formData.orgSearchInput = value;

      if (value === '') {

        // Reset Filter
        formData.filteredOrgs = formData.allOrgs;
        formData.searchResultsCount = '';
      
      } else {

        const results = formData.allOrgs.filter((object) => 
          object?.organisationname?.toLowerCase()?.includes(value?.toLowerCase())
        );

        formData.filteredOrgs = results;
        formData.searchResultsCount = results.length;

      };

      setFormData(formData);

    };

    // Used to submit the form and invite added users
    function sendInvites() {

      setInviteButtonDisabled(true);
      
      // Find organisaton ID based on organisation name entered on the form
      const orgId = formData.allOrgs.find(org => org.organisationname === formData.organisation)?.organisationid;

      //------------------------------------------------------
      //  Added a check to ensure Lendlease users do NOT lose their LLUser role
      //------------------------------------------------------

      let lendleaseUser = false;
      if (formData.emailaddress.endsWith('@lendlease.com')) lendleaseUser = true;

      //------------------------------------------------------
      //  Check if worker has a user document
      //------------------------------------------------------

      GetDocument('users', formData?.emailaddress.toLowerCase(), true).then((document) => {
        
        // Existing worker in another organisation > Set status to 'error-invalid'
        if (document !== undefined && document?.organisationid.length > 0 && document?.organisationid !== orgId) {

          setFormData({ 'invalidErrorMessage': 'This worker exists in another organisation.'});
          return setModalStatus('error-invalid');

        }

        // Existing worker in same organisation > Set status to 'error-invalid'
        if (document !== undefined && document?.organisationid === orgId) {

          setFormData({ 'invalidErrorMessage': 'This worker exists in this organisation.'});
          return setModalStatus('error-invalid');

        }
        
        // For New Workers or Admins > Create/Update User Doc
        const docPromises = [];

        if (document !== undefined && (document.projectAdmin.length > 0 || document.roles.globalAdmin === true)) {

          // Existing admin > Update user doc
          const updatedDoc = {
            'organisationid': orgId,
            'organisationname': formData.organisation,
          };
  
          docPromises.push(
            WriteDocument('users', formData.emailaddress.toLowerCase(), updatedDoc, true),
          );

        } else {

          // New Worker > Create user doc
          const newWorkerDoc = {
            'emailaddress': formData.emailaddress.toLowerCase(),
            'givenname': formData.givenname,
            'surname': formData.surname,
            'created': new Date(),
            'organisationid': orgId,
            'organisationname': formData.organisation,
            'orgAdmin': [],
            'projectAdmin': [],
            'projects': [],
            'roles': {
              'globalAdmin': false,
              'lendleaseUser': lendleaseUser,
            },
            'status': 'active',
            'subbyorg': formData.subbyorg,
          };
  
          docPromises.push(
            WriteDocument('users', formData.emailaddress.toLowerCase(), newWorkerDoc, false)
          );

        }

        //------------------------------------------------------
        //  Append org document with worker emailaddress
        //------------------------------------------------------

        // Firestore Client ~ Allows the update of elements in an array
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);

        const docRef = doc(db, 'organisations', orgId);

        const updateOrgId = {
          'organisationid': orgId,
          'organisationname': formData.organisation,
        };

        docPromises.push(
          updateDoc(docRef, {
            workers: arrayUnion(formData.emailaddress.toLowerCase())
          }),
          WriteDocument('users', formData.emailaddress.toLowerCase(), updateOrgId, true),
        );

        //------------------------------------------------------
        //  Settle Promises
        //------------------------------------------------------

        return Promise.all(docPromises).then(() => {
          
          // Auditing
          const message = `${getUser.emailaddress} -- Invited worker ${formData.emailaddress} to organisation ${formData.organisation}.`;
          const types = [
            'app settings'
          ];
          const references = [
            formData.organisation,
            getUser.emailaddress,
            formData.emailaddress,
            'organisations',
          ];

          return WriteAuditLog(getUser.emailaddress, message, types, references)
          .then(() => {
            setFormData({
              'emailaddress': '',
              'givenname': '',
              'surname': '',
              'subbyorg': '',
              'organisation': '',
              'fatalErrorMessage': ''
            });

            setModalState({
              'pageStatus': 'pending',
              'inviteModalVisible': false
            });

          });

        })
          
      }).catch((error) => {

        console.log('Invite Worker failed', error);
        setFormData({ 'fatalErrorMessage': error });
        setModalStatus('error-fatal');

      });
    
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //When the page loads > get all active organisations
    useEffect(() => {

      if (modalStatus !== 'pending') return;

      function onLoad(documents) {
        // Update globalAdmins > Set page status to 'onload'
        setFormData({
          'allOrgs': documents,
          'filteredOrgs': documents
        })
        setModalStatus('onload');

      }

      function onChange(documents) {
        // Update globalAdmins > Set page status to 'onload'
        setFormData({
          'allOrgs': documents,
          'filteredOrgs': documents
        })
        setModalStatus('onload');

      }

      function onError(error) {
        console.log(error)
        setModalStatus('error-fatal');

      }

      const unsubscribe = QueryListener('organisations', [['status', '==', 'active'],], onLoad, onChange, onError)

      return () => {
        unsubscribe();
      };

      // eslint-disable-next-line
    }, [modalStatus]);

    // Check if the form fields are populated and enable 'Send Invite' button
    useEffect(() => {
      
      if (
        formData.givenname.length > 0 &&
        formData.surname.length > 0 &&
        formData.emailaddress.length > 0 &&
        formData.organisation.length > 0
      ) {

        setInviteButtonDisabled(false);

      } else {

        setInviteButtonDisabled(true);

      }

    }, [formData]);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //====================================================
    //  Modal Hidden
    //====================================================
    
    if (modalState?.inviteModalVisible === false) return null;

    //====================================================
    //  Pending
    //====================================================

    else if (modalStatus === 'pending') {
      return (
        <div className='Modal-Background'>
          <dialog className='Invite-User-Container'>
            <div className='flex justify-center'>
              <img alt="loading-circle-icon" src={LoadingIcon} width='200px'></img>
            </div>
          </dialog>
        </div>
      )
    }
  
    //====================================================
    //  Error-Invalid
    //====================================================

    else if (modalStatus === 'error-invalid') {
      return (
        <div className='Modal-Background'>
          <dialog className='Invite-User-Container'>
            <div className='flex flex-col text-center gap-2 py-4'>
              <h4>Sorry you cannot perform this action.</h4>
              <p>
                {formData.invalidErrorMessage}
              </p>

              <div>
                <button className='Primary-Button' onClick={() => {
                  setFormData({
                    'emailaddress': '',
                    'givenname': '',
                    'surname': '',
                    'subbyorg': '',
                    'organisation': '',
                    'fatalErrorMessage': ''
                  })
                  setModalStatus('pending')
                  }}>
                    Go Back
                </button>
              </div>
            </div>
          </dialog>
        </div>
      )
    }

    //====================================================
    //  Error-Fatal
    //====================================================

    else if (modalStatus === 'error-fatal') {
      return (
        <div className='Modal-Background'>
          <dialog className='Invite-User-Container'>
            <div className='flex flex-col text-center gap-2'>
                <h4>Oops something went wrong!</h4>
                
                <p className=''>
                  An unknown error has occurred while attempting to invite <strong>{formData.emailaddress}</strong>
                  <br></br><br></br>
                  If the error persists, please speak with your administrator. 
                </p>
                <p > 
                  <b>Message:</b> {formData.fatalErrorMessage}
                </p>

                <div>
                  <button className='Primary-Button' onClick={() => {
                      setFormData({
                        'emailaddress': '',
                        'givenname': '',
                        'surname': '',
                        'subbyorg': '',
                        'organisation': '',
                        'fatalErrorMessage': ''
                    })
                    setModalStatus('pending')
                    }}>Go Back</button>
                </div>
            </div>
          </dialog>
        </div>
      )
    }

    //====================================================
    //  Onload
    //====================================================

    else {
      return (
      <div className='Modal-Background'>
        <dialog className='Invite-User-Container'>

          <div className='Action-Modal-Container'>
            
            {/* Modal Icon */}
            <div className='Action-Modal-Icon'>
                <img src={InviteWorker} alt='Invite User Icon'></img>
            </div>

            {/* Modal Body */}
            <div className='Action-Modal-Body'>
            
              <h4>Invite Worker</h4>
  
              <p> Invite a worker to an organisation by entering their details and clicking the <b style={{fontWeight: '500'}}>Send Invite</b> button below. </p>
              
              {/* ================================================ */}
              {/*              Invite User Form                    */}
              {/* ================================================ */}            
              
              <div className='flex flex-col gap-3'>

                {/* <------------------------NAME---------------------> */}

                <div className='grid grid-cols-2 gap-3'>
                  
                    {/* <------------------FIRST NAME-----------------> */}

                    <div className='flex flex-col'>
                        <label> First Name </label>
                        <input 
                            required
                            name='givenname'
                            type='text' 
                            className='Action-Modal-Input-Field'
                            onChange={(e) => setFormData({'givenname': e.target.value})}
                            value={formData.givenname}
                            autoComplete="off"
                        ></input>
                    </div>
                        
                    {/* <-------------------LAST NAME-----------------> */}

                    <div className='flex flex-col'>
                        <label> Last Name </label>
                        <input 
                            required
                            name='surname'
                            type='text' 
                            className='Action-Modal-Input-Field'
                            onChange={(e) => setFormData({'surname': e.target.value})}
                            value={formData.surname}
                            autoComplete='off'
                        ></input>
                    </div>

                </div>

                {/* <-------------------EMAIL ADDRESS-----------------> */}
                
                <div className='flex flex-col'>
                  <label> Email Address </label>
                  <input 
                    required
                    name='emailaddress'
                    type='email' 
                    className='Action-Modal-Input-Field'
                    onChange={(e) => setFormData({'emailaddress': e.target.value})}
                    value={formData.emailaddress}
                    autoComplete='off'
                  ></input>
                </div>

                {/* <-------------------ORGANISATION-----------------> */}

                <div className='flex flex-col'>
                    <label> Organisation </label>

                      {/* Search */}
                      <div className='flex flex-row justify-between rounded-[5px] border-[#D8D8D8] border px-[10px] py-2 mb-3'>
                        <div className='flex flex-row'>
                          <img src={IconSearch} alt='search-icon' hidden={formData.searchResultsCount !== ''}></img>
                          <input
                            type='text'
                            name='orgSearchInput'
                            className='ml-2 focus:outline-none'
                            placeholder='Search'
                            onChange={(e) => handleSearch(e.target.value)}
                            value={formData.orgSearchInput}
                          ></input>
                        </div>
                        <img className='cursor-pointer' src={IconClose} alt='Close'hidden={formData.searchResultsCount === ''} onClick={() => setFormData({
                          'orgSearchInput': '',
                          'searchResultsCount': '',
                          'filteredOrgs': formData.allOrgs,
                        })}></img>
                      </div>

                    {/* Select */}
                    <div className='Searchable-Select'>
                      {
                        formData.filteredOrgs.length > 0 ?
                        (
                          formData.filteredOrgs.map((org, index) => (
                            <button 
                              key={index} 
                              className={formData.organisation === org.organisationname ? 'bg-[#0972D3] text-white' : ''}
                              onClick={() => setFormData({'organisation': org.organisationname})}
                            > 
                              {org.organisationname}
                            </button>
                          ))
                        )
                        :
                        (
                          <label> No organisations found. </label>
                        )
                      }
                    </div>

                </div>
                
                {/* <-------------------SUB-SUBCONTRACTOR ORGANISATION-----------------> */}
                
                <div className='flex flex-col'>
                  <label> Sub-Subcontractor Organisation (Optional) </label>
                  <input 
                    name='subbyorg'
                    type='text' 
                    className='Action-Modal-Input-Field'
                    onChange={(e) => setFormData({'subbyorg': e.target.value})}
                    value={formData.subbyorg}
                    autoComplete='off'
                  ></input>
                </div>

                {/* <------------------SUBMIT BUTTONS-----------------> */}
                <div className='flex flex-row gap-2 mt-4 '>
                  <button className='Primary-Button' disabled={inviteButtonDisabled} onClick={sendInvites}> Send Invite </button>
                  <button className='Secondary-Button' onClick={() => {
                        setFormData({
                          'emailaddress': '',
                          'givenname': '',
                          'surname': '',
                          'subbyorg': '',
                          'organisation': '',
                          'fatalErrorMessage': ''
                        })
                        setModalState({ 'inviteModalVisible': false })}
                  }> 
                    Cancel 
                  </button> 
                </div>

              </div>

            </div>

          </div>
        </dialog>
      </div>
      )
    }

  }
