//================================================================
//  Component: Organisations Table Row
//================================================================

//  Purpose: Allows global administrators to invite other global administrators

//  Properties:
//    - formData = {useReducer state}

//  Example:
//    <OrganisationTableRow
//      formData={formData}
//    ></OrganisationTableRow>    

//================================================================


//Libraries
import React, { useState, useReducer, useEffect, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../../Library/FirebaseConfig';
import { doc, updateDoc, arrayUnion, arrayRemove, getFirestore } from 'firebase/firestore';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components
import RemoveOwnerModal from '../Overview/RemoveOwnerModal';

//Functions
import getDocument from '../../../../Library/GetDocument';
import writeDocument from '../../../../Library/WriteDocument';
import queryDocument from '../../../../Library/QueryDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Images
import IconOpenBlue from '../../../../Components/Images/Icon_Expand_Blue.svg';
import IconCloseBlue from '../../../../Components/Images/Icon_Collaps_Blue.svg';
import IconAddGrey from '../../../../Components/Images/Icon_Add_Grey.svg';
import IconAddTeal from '../../../../Components/Images/Icon_Add_Teal.svg';
import IconDeleteRed from '../../../../Components/Images/Icon_Delete_Red.svg';
import InfoRequired from '../../../../Components/Images/Icon_InfoRequired_Teal.svg';
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';

//CSS
import '../../ProjectSettings.css';


export default function OrganisationTableRow({
    org
}) {

  //------------------------------------------------------
  //  useContext
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Used to save page status > 'onload', 'pending', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Toggle the row open or closed
    const [open, setOpen] = useState(false);

    // Toggle open the Delete Owner Modal
    const [modalOwnerVisible, setOwnerModalVisible] = useState(false);

    // Show an error message next to the save button
    const [formError, setFormError] = useState('');


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'name': org.organisationname,
            'nameValid': true,
            'description': org.description,
            'descriptionValid': true,
            'abn': org.abn,
            'abnValid': true,

            // Org Owner States
            'newOwner': '',
            'newOwnerGivenname': '',
            'newOwnerSurname': '',
            'newOwnerStatus': 'onload', // 'onload', 'new-user'
            'newOwnerMessage': '',
            'owners': [],

            // Delete Owner modal states
            'deleteOwnerObject': undefined,
            'deleteOwnerIndex': undefined,

        }
    );


  //------------------------------------------------------
  //  Functions --> Owners
  //------------------------------------------------------

    function handleChangeOwner(value) {

        if (value.length === 0) {

            return setFormData({
              'newOwner': value,
              'newOwnerAllowAdd': false
            });
    
          }

        // Validate emailaddress
        if (/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)) {

            return setFormData({
                'newOwner': value,
                'newOwnerStatus': 'onload',
                'newOwnerMessage': '',
                'newOwnerAllowAdd': true,
              });

        } 

        // invalid emailaddress
        setFormData({
            'newOwner': value,
            'newOwnerStatus': 'onload',
            'newOwnerMessage': '',
            'newOwnerAllowAdd': false,
        });

    }

    // Adds a Org Owner to 'formData.owners' array
    function handleAddOwner() { 

        // Check for duplicates
        const owner = formData.owners.filter((object) => object.emailaddress === formData.newOwner);
        if (owner.length > 0) {

            if (owner[0].type !== 'deleted') {

                return setFormData({
                    'newOwnerMessage': 'Email address already exists.'
                });

            };

        };

        //------------------------------------------------------
        //  Get the users document
        //  1. Check if they exist (Catch add's them as a new user)
        //  2. Check if they are part of another org
        //------------------------------------------------------

        return getDocument('users', formData.newOwner).then((userDoc) => {

            return queryDocument('organisations', [
                ['organisationid', '==', userDoc.organisationid]
            ]).then((usersOrg) => {

                // Member of a different active org?
                if (usersOrg.length > 0 && userDoc.organisationid !== org.organisationid) {

                    if (usersOrg[0].status === 'active') {

                        return setFormData({
                            'newOwnerMessage': `User is already apart of organisation, '${usersOrg[0].organisationname}'`
                        });

                    }

                }

                // Sanity check if the user was added/removed from the list
                let foundUser = false;
                formData.owners.forEach((user, index) => {

                    if (user.emailaddress === formData.newOwner) {

                    formData.owners[index] = {
                        'emailaddress': formData.newOwner,
                        'type': 'existing'
                    };
                    foundUser = true;

                    }

                });

                // User wasn't added/removed from the list, append the 'owners' array
                if (foundUser === false) {

                    formData.owners.push({
                    'emailaddress': formData.newOwner,
                    'type': 'existing'
                    });

                };

                formData.newOwner = '';
                formData.newOwnerMessage = '';
                formData.newOwnerAllowAdd = false;
                formData.newOwnerStatus = 'onload';
                setFormData(formData);

            }).catch((error) => {

                // Fatal Error and shouldn't be possible if the Firestore Rules are correct
                console.log('Fatal Error with security rules!', error );

            });

        }).catch(() => {

            formData.newOwnerStatus = 'new-user';
            formData.newOwnerMessage = '';
            formData.newOwnerAllowAdd = false;
            setFormData(formData);

        });

    };

    // Deletes a Org Owner from 'formData.owners' array
    function handleDeleteOwner(object, index) { 

        // Skip new users
        if (object.type === 'new') {
            formData.owners.splice(index, 1)
            return setFormData(formData);
        
        }

        // We need to check if the user already has the role!
        getDocument('users', object.emailaddress)
        .then((document) => {
            
            // Has the role --> Add to the deleted list
            if (document.orgAdmin.includes(org.organisationid)) {

                formData.owners[index] = {
                    'emailaddress': object.emailaddress,
                    'type': 'deleted',
                    'globalAdmin': document.roles?.globalAdmin,
                    'projectAdmin': document.projectAdmin
                };

            // Doesn't have the role --> Ignore
            } else {

                formData.owners.splice(index, 1);

            }

            setOwnerModalVisible(false);
            setFormData(formData);

        })
        .catch(() => {

            setFormData({'owners': formData.owners.splice(index, 1)});

        });

    };


  //------------------------------------------------------
  //  Submit Handler
  //------------------------------------------------------

    // org onSubmit handler
    function onSubmitUpdate(e){

        e.preventDefault();

        // OnSubmit Validation of all required fields
        let preventSubmit = false;

        // Check if ALL validations have passed
        if (preventSubmit === true)  return setFormError('Complete the form before proceeding');

        setPageStatus('pending');

        // ================================================================
        // Passed all validation checks --> write project & user documents
        //  - Create an new project document 
        //  - Create new user documents
        //  - Update 'projectAdmin' array (Ref to arrayUnion's in Firebase documentation)
        // ================================================================

        // ----------------------------------------------------------
        // 1. Define Variables
        // ----------------------------------------------------------

        // Firestore Client ~ Allows the update of elements in an array
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const newUsers = [];
        const existingOwners = [];
        const deletedUsers = [];
        const orgUpdatePromises = [];
        const newUserPromises = [];
        const existingUserPromises = [];
        const deletedUserPromises = [];
        const auditingPromises = [];

        // ----------------------------------------------------------
        // 2. Extract data from 'owners' array & write audit logs
        // ----------------------------------------------------------

        formData.owners.forEach((object) => {

            if (object.type === 'new') {

                // Added a check to ensure Lendlease users do NOT lose their LLUser role
                let lendleaseUser = false;
                if (object.emailaddress.endsWith('@lendlease.com')) lendleaseUser = true;

                // Define user document
                newUsers.push({
                    'emailaddress': object.emailaddress.toLowerCase(),
                    'givenname': object.givenname,
                    'surname': object.surname,
                    'created': new Date(),
                    'projectAdmin': [],
                    'organisationid': org.organisationid,
                    'organisationname': org.organisationname,
                    'orgAdmin': [
                        org.organisationid
                    ],
                    'projects': [],
                    'roles': {
                        'globalAdmin': false,
                        'lendleaseUser': lendleaseUser,
                    },
                    'status': 'active'
                });

                // Auditing
                const message = `${getUser.emailaddress} -- Created new org admin ${object.emailaddress.toLowerCase()} for organisation ${formData.name}.`;
                const types = [
                    'organisation history'
                ];
                const references = [
                    formData.name,
                    object.emailaddress.toLowerCase(),
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            }

            if (object.type === 'existing') {

                existingOwners.push(object.emailaddress.toLowerCase());

                // Auditing
                const message = `${getUser.emailaddress} -- Added new org admin ${object.emailaddress.toLowerCase()} to organisation ${formData.name}.`;
                const types = [
                    'organisation history'
                ];
                const references = [
                    formData.name,
                    object.emailaddress.toLowerCase(),
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            };

            if (object.type === 'deleted') {

                deletedUsers.push(object);

                // Auditing
                const message = `${getUser.emailaddress} -- Removed org admin ${object.emailaddress.toLowerCase()} from organisation ${formData.name}.`;
                const types = [
                    'organisation history'
                ];
                const references = [
                    formData.name,
                    object.emailaddress.toLowerCase(),
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            };
        
        });

        // ----------------------------------------------------------
        // 3. Update the organisation document workers array
        // ----------------------------------------------------------

        const orgRef = doc(db, 'organisations', org.organisationid);

        formData.owners.forEach((object) => {

            if (object.type === 'new') {
                
                // Update org doc workers array
                orgUpdatePromises.push(
                    updateDoc(orgRef, {
                        workers: arrayUnion(object.emailaddress.toLowerCase())
                    })
                );
                
                // Auditing
                const message = `${getUser.emailaddress} -- Added admin ${object.emailaddress.toLowerCase()} to organisation ${org.organisationname}.`;
                const types = [
                    'app settings'
                ];
                const references = [
                    object.emailaddress.toLowerCase(),
                    org.organisationname,
                    getUser.emailaddress,
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            }

            if (object.type === 'existing') {

                if (!org.workers.includes(object.emailaddress.toLowerCase())) {

                    // Update org doc workers array
                    orgUpdatePromises.push(
                        updateDoc(orgRef, {
                            workers: arrayUnion(object.emailaddress.toLowerCase())
                        })
                    );
                    
                    // Auditing
                    const message = `${getUser.emailaddress} -- Added admin ${object.emailaddress.toLowerCase()} to organisation ${org.organisationname}.`;
                    const types = [
                        'app settings'
                    ];
                    const references = [
                        object.emailaddress.toLowerCase(),
                        org.organisationname,
                        getUser.emailaddress,
                        'organisations',
                    ];
                    auditingPromises.push(
                        writeAuditLog(getUser.emailaddress, message, types, references)
                    );

                }

            }

            if (object.type === 'deleted') {

                orgUpdatePromises.push(
                    updateDoc(orgRef, {
                      workers: arrayRemove(object.emailaddress.toLowerCase())
                    })
                );

            }

        })

        // ----------------------------------------------------------
        // 4. Write 'new users' to 'users' collection
        // ----------------------------------------------------------

        newUsers.forEach((document) => {

            newUserPromises.push(
                writeDocument('users', document.emailaddress, document, false)
            )

        });

        // ----------------------------------------------------------
        // 5. Merge 'existing user' roles in their 'users' collection
        // ----------------------------------------------------------

        existingOwners.forEach((emailaddress) => {

            // https://cloud.google.com/firestore/docs/manage-data/add-data#update_elements_in_an_array
            const docRef = doc(db, 'users', emailaddress);

            existingUserPromises.push(
                updateDoc(docRef, {
                    orgAdmin: arrayUnion(org.organisationid)
                }),
            );

            const newdoc = {
                'organisationid': org.organisationid, 
                'organisationname': org.organisationname
            }
            existingUserPromises.push(
                writeDocument('users', emailaddress, newdoc, true)
            )

        });

        // ----------------------------------------------------------
        // 6. Delete 'existing user' in the 'users' collection
        // ----------------------------------------------------------

        deletedUsers.forEach((user) => {

            // Do NOT delete user doc if they are a global admin or project admin
            if (user?.globalAdmin === true || user.projectAdmin?.length > 0) {

                const updatedDoc = {
                    'organisationid': '',
                    'organisationname': '',
                    'orgAdmin': []
                }
            
                deletedUserPromises.push(
                    writeDocument('users', user.emailaddress, updatedDoc, true)
                )
        
            } else {
                // We ALWAYS delete org admins so PII data is removed
                const document = {
                    'status': 'inactive'
                };

                deletedUserPromises.push(
                    writeDocument('users', user.emailaddress, document, true)
                );
                
            }

        });

        // ---------------------------------------------------------
        // 7. Settle promises & handle results
        // ---------------------------------------------------------
    
        Promise.all([...newUserPromises, ...existingUserPromises, ...deletedUserPromises, ...auditingPromises])
        .then(() => {

            return queryDocument('users', [
                ['orgAdmin', 'array-contains', org.organisationid],
                ['status', '==', 'active'],
            ])
            .then((orgAdmins) => {

                // Extract orgAdmins
                const admins = [];
                orgAdmins.forEach((user) => {
    
                    admins.push({
                        'emailaddress': user.emailaddress,
                        'type': 'existing',
                    });
    
                });
    
                // Save Admins to formData
                setFormData({
                    'owners': admins,
                });

                // Reset form
                setFormError('');
                setPageStatus('onload');

            });

        })
        .catch((error) => {

            console.log(error)
            setFormError('Unable to save changes, please try again.');
    
        });

    };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onload
    //  1. Get all orgAdmins from the users collection
    //  2. Save to useReducer
    useEffect(() => {

        if (org === undefined) return;

        queryDocument('users', [
            ['orgAdmin', 'array-contains', org.organisationid],
            ['status', '==', 'active']
        ]).then((orgAdmins) => {

            // Extract orgAdmins
            const admins = [];
            orgAdmins.forEach((user) => {
                admins.push({
                    'emailaddress': user.emailaddress,
                    'type': 'existing'
                });

            });

            // Save Admins to formData & Reset Form
            setFormData({ 
                'name': org.organisationname,
                'nameValid': true,
                'description': org.description,
                'descriptionValid': true,
                'abn': org.abn,
                'abnValid': true,
    
                // Org Owner States
                'newOwner': '',
                'newOwnerGivenname': '',
                'newOwnerSurname': '',
                'newOwnerStatus': 'onload', // 'onload', 'new-user'
                'newOwnerMessage': '',
                'owners': admins,
    
                // Delete Owner modal states
                'deleteOwnerObject': undefined,
                'deleteOwnerIndex': undefined,
            });

        }).catch((error) => {
            setFormError(`Failed to get list of organisation admins. Error: ${error}`)
            setPageStatus('error-fatal')

        });

    }, [org]);
 
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //====================================================
    //  Pending
    //====================================================

    if (pageStatus === 'pending') {
        return (
            <div className='ProjectSetting-Table-Open'>
                <form className='ProjectSetting-Form'>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img alt='loading-circle-icon' src={LoadingIcon}></img>
                    </div>

                    {/* ================================================ */}
                    {/*             Submit Buttons                       */}
                    {/* ================================================ */}

                    <div className='ProjectSetting-Form-Row' style={{borderTop: 'none'}}>
                        <button 
                            style={{marginTop: '30px'}} 
                            className='Primary-Button'
                            disabled={pageStatus === 'onload' ? (false):(true)} 
                            type='submit' 
                            value='submit'
                        >
                            Save
                        </button>
                        <button 
                            style={{marginLeft: '10px', marginTop: '30px'}} 
                            className='Secondary-Button'
                            onClick={() => window.location.reload()}
                        >
                            Discard
                        </button>
                    </div>
                    <div className='Input-Field-Text-Error-Message'>
                        {formError}
                    </div>

                </form>
            </div>

        )

    }

    //====================================================
    //  Error-Fatal
    //====================================================

    else if (pageStatus === 'error-fatal') {
        return (
          <div className='Modal-Background'>
            <dialog className='Invite-User-Container'>
              <div className='flex flex-col text-center gap-2'>
                  <h4>Oops something went wrong!</h4>
                  
                  <p className=''>
                    An unknown error has occurred while attempting to edit this organisation.
                    <br></br><br></br>
                    If the error persists, please speak with your administrator. 
                  </p>
                  <p > 
                    <b>Message:</b> {formError}
                  </p>
  
                  <div>
                    <button className='Primary-Button' onClick={() => {
                        setFormError('');
                        setPageStatus('pending')
                    }}>Go Back</button>
                  </div>
              </div>
            </dialog>
          </div>
        )
    }
  

    //====================================================
    //  Onload
    //====================================================

    else {
        return (
        <>
            {/* ----------------------------------------------- */}
            {/*  Organisation Header                            */}
            {/* ----------------------------------------------- */}

            <div className='ProjectSetting-Organisation-Header'>
                <h6 className='text-black m-0'>{org.organisationname}</h6>

                {/* Show Expanded Icon if Open OR Collapsed Icon if Closed */}
                {
                    open ?
                    <img style={{cursor: 'pointer'}} onClick={() => setOpen(false)} src={IconCloseBlue} alt='close-row'></img>
                    :
                    <img style={{cursor: 'pointer'}} onClick={() => setOpen(true)} src={IconOpenBlue} alt='open-row'></img>
                }
            </div>

            {/* ----------------------------------------------- */}
            {/*  Edit Organisation Form                         */}
            {/* ----------------------------------------------- */}
            
            {
                open &&
                <div className='ProjectSetting-Table-Open shadow-inner'>

                    <form className='ProjectSetting-Form' onSubmit={(e) => onSubmitUpdate(e)}>

                        {/* ===================================== */}
                        {/*                 Name                  */}
                        {/* ===================================== */}

                        <div className='ProjectSetting-Org-Form-Row'>
                            <b>NAME</b>
                            <input
                                className='Input-Field-Text-Disabled' 
                                type='text'
                                disabled
                                value={formData.name}
                            ></input>
                        </div>

                        {/* ===================================== */}
                        {/*            Description                */}
                        {/* ===================================== */}

                        <div className='ProjectSetting-Org-Form-Row'>
                            <b>DESCRIPTION</b>
                            <textarea
                                className='Input-Field-TextArea'
                                disabled
                                value={formData.description}
                            ></textarea>
                        </div>

                        {/* ===================================== */}
                        {/*               ABN                     */}
                        {/* ===================================== */}

                        <div className='ProjectSetting-Org-Form-Row'>
                            <b>ABN</b>
                            <input
                                className={'Input-Field-Text-Disabled'} 
                                type='text'
                                value={formData.abn}
                                disabled
                            ></input>
                        </div>

                        {/* ================================================ */}
                        {/*         Organisation Owner(s)                    */}
                        {/*  1. Allows GA's to add existing firestore users  */}
                        {/*  2. Validates new users with a form              */}
                        {/*  3. Saves results to useReducer                  */}
                        {/* ================================================ */}

                        {

                            // ========================================================
                            // All 'newOwnerStatus' states ---> 'EXCLUDING new-users'!
                            // ========================================================

                            formData.newOwnerStatus !== 'new-user' ? (

                                <div className='ProjectSetting-Org-Form-Row'>

                                    <b>ORGANISATION OWNER(S)</b>

                                    {/* ======================================= */}
                                    {/*    Email Address Input Field            */}
                                    {/* ======================================= */}

                                    <div className='flex flex-row gap-2 items-center mb-2'>
                                        <input
                                            className={formData.newOwnerMessage.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                            type='text'
                                            placeholder='Email address'
                                            onChange={(e) => handleChangeOwner(e.target.value)}
                                            value={formData.newOwner}
                                            autoComplete='off'
                                        ></input>

                                        {/* ======================================= */}
                                        {/*    Toggle Add Icon                      */}
                                        {/*  1. Enabled                             */}
                                        {/*  1. Disabled                            */}
                                        {/* ======================================= */}

                                        {
                                            formData.newOwnerAllowAdd === true ? (

                                            <img
                                                style={{marginLeft: '10px'}} 
                                                className='Input-Icon'
                                                src={IconAddTeal} 
                                                alt='add-icon'
                                                onClick={() => handleAddOwner()}
                                            ></img>

                                            ): (

                                            <img
                                                style={{marginLeft: '10px'}} 
                                                className='Input-Icon'
                                                disabled={true}
                                                src={IconAddGrey} 
                                                alt='add-icon-disabled'
                                            ></img>

                                            )
                                        }

                                    </div>


                                    {/* ======================================= */}
                                    {/*    1. Error messages                    */}
                                    {/*    2. List of all Owners                */}
                                    {/* ======================================= */}

                                    <div className='ProjectSetting-Form-ProjectOwners'>
                                        <span className='Input-Field-Text-Error-Message'>{formData.newOwnerMessage}</span>

                                        {
                                            // Show the list of current owners
                                            formData?.owners?.map((object, index) => (

                                                <div key={index} style={{display: 'flex'}}>

                                                    {
                                                    object.type !== 'deleted' && (

                                                        <>
                                                            <label className='Label-List-Item mb-0'>{object.emailaddress}</label>
                                                            <img 
                                                                style={{marginLeft: '10px'}} 
                                                                className='Input-Icon' 
                                                                src={IconDeleteRed} 
                                                                alt='delete-icon' 
                                                                onClick={() => {

                                                                    formData.deleteOwnerObject = object;
                                                                    formData.deleteOwnerIndex = index;
                                                                    setFormData(formData);
                                                                    setOwnerModalVisible(true);

                                                                }}
                                                            ></img>
                                                        </>

                                                    )
                                                    }

                                                </div>
                                                
                                            ))

                                        }
                                    </div>

                                </div>

                            ):

                            // ========================================================
                            //  New Users Form ---> formData.newOwnerStatus === 'new-users'
                            // ========================================================

                            (
                                <div className='ProjectSetting-Org-Form-Row'>

                                    <b>ORGANISATION OWNER(S)</b>

                                    {/* New User Form */}
                                    <div className='New-User-Form'>

                                        {/* <-------------------HEADER--------------------> */}
                                        <div className='New-User-Header'>
                                            <img src={InfoRequired} alt='Info-Required-Icon' width='25px'></img>
                                            MORE INFORMATION REQUIRED
                                        </div>

                                        {/* <-----------------EMAIL ADDRESS---------------> */}
                                        <div className='New-User-Email'>
                                            <p> Email Address </p>
                                            <input 
                                                className='Input-Field-Text'
                                                type='text' 
                                                value={formData.newOwner}
                                                disabled
                                                autoComplete='off'
                                            ></input>
                                        </div>
              
                                        {/* <-----------------USER NAME------------------> */}
                                        <div className='New-User-Name'>

                                            {/* Given Name */}
                                            <div>
                                                <p> Given Name </p>
                                                <input 
                                                    className='Input-Field-Text'
                                                    type='text' 
                                                    value={formData.newOwnerGivenname}
                                                    onChange={(e) => setFormData({'newOwnerGivenname': e.target.value})}
                                                ></input>
                                            </div>
                                                            
                                            {/* Surname */}
                                            <div>
                                                <p> Surname </p>
                                                <input 
                                                    className='Input-Field-Text'
                                                    type='text' 
                                                    value={formData.newOwnerSurname}
                                                    onChange={(e) => setFormData({'newOwnerSurname': e.target.value})}
                                                ></input>
                                            </div>
                                        </div>

                                        {/* <-----------------ADD BUTTON---------------> */}
                                        <div className='New-User-Buttons'>

                                            {/* Disable '+ Add User' button if fields are empty */}
                                            <button 
                                                className='Primary-Button'
                                                style={{fontSize: '13px'}} 
                                                disabled = {
                                                    formData.newOwnerGivenname.length > 0 &&
                                                    formData.newOwnerSurname.length > 0 ? 
                                                    false : true
                                                }
                                                onClick={() => {
                                                    formData.owners.push({
                                                        'emailaddress': formData.newOwner,
                                                        'type': 'new',
                                                        'givenname': formData.newOwnerGivenname,
                                                        'surname': formData.newOwnerSurname,
                                                    });
                                                    formData.newOwnerStatus = 'onload';
                                                    formData.newOwner = '';
                                                    formData.newOwnerAllowAdd = false;
                                                    formData.newOwnerGivenname = '';
                                                    formData.newOwnerSurname = '';
                                                    setFormData(formData);
                                                }}
                                            >
                                                + Add User
                                            </button>

                                            {/* Cancel Button */}
                                            <button 
                                                className='Secondary-Button'
                                                style={{fontSize: '13px'}} 
                                                onClick={() => {
                                                    formData.newOwnerStatus = 'onload';
                                                    formData.newOwner = '';
                                                    formData.newOwnerGivenname = '';
                                                    formData.newOwnerSurname = '';
                                                    setFormData(formData);
                                                }}
                                            >
                                                Cancel
                                            </button>

                                        </div>

                                    </div>
                                </div>
                            )
                        }

                        {/* ================================================ */}
                        {/*             Remove Owner Modal                  */}
                        {/* ================================================ */}

                        {
                            modalOwnerVisible && (
                                <RemoveOwnerModal
                                    deleteOwnerObject={formData.deleteOwnerObject}
                                    deleteOwnerIndex={formData.deleteOwnerIndex}
                                    handleDeleteOwner={handleDeleteOwner}
                                    setModalVisible={setOwnerModalVisible}
                                ></RemoveOwnerModal>
                            )
                        }

                        {/* ================================================ */}
                        {/*             Submit Buttons                       */}
                        {/* ================================================ */}

                        <div className='ProjectSetting-Form-Row' style={{borderTop: 'none'}}>
                            <button style={{marginTop: '30px'}} className='Primary-Button'disabled={pageStatus === 'onload' ? (false):(true)} type='submit' value='submit'>
                                Save
                            </button>
                            <button style={{marginLeft: '10px', marginTop: '30px'}} className='Secondary-Button'onClick={() => window.location.reload()}>
                                Discard
                            </button>
                        </div>
                        <div className='Input-Field-Text-Error-Message'>
                            {formError}
                        </div>

                        {/* ================================================ */}

                    </form>
                
                </div>
            }
        </>
        )
    }
}
