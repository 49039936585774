//================================================================
//  Component: ExportToCSV
//  Documentation: https://www.npmjs.com/package/react-csv
//================================================================

//  Purpose: Input firestore data and converts to a CSV file, with a user friendly export button!

//  Properties:
//    - filename <Required> = <String> the name of the file
//    - data <Required> = <Array of Arrays with Objects OR Array of Objects>
//    - type <Optional> = <String> 'button' or defaults to an image
//    - headers <Optional> = {[
//      { label: "Project Id", key: "projectId" },
//      { label: "latest Month Total", key: "latestMonthTotal" },
//      { label: "Previous Month Total", key: "previousMonthTotal" },
//    ]}

//  ExportToCSV:
//    <ExportToCSV
//      filename='emissions'
//      data={projectEmissionsChartData} 
//      type='button'
//      headers={[
//        { label: "Project Id", key: "projectId" },
//        { label: "latest Month Total", key: "latestMonthTotal" },
//        { label: "Previous Month Total", key: "previousMonthTotal" },
//      ]}
//    ></ExportToCSV>    

//================================================================


//Libraries
import React, { useRef, useState, useEffect } from 'react';
import { CSVLink } from "react-csv";

//Contexts

//Components

//Functions
import GetCollection from '../../../Library/GetCollection';

//Images
import CSVImage from '../../../Components/Images/Icon_Export_CSV_Grey.svg';
import LoadingIcon from '../../../Components/Images/Icon_Loading_Spinner_Blue.svg';
import DownloadIcon from '../../../Components/Images/Icon_Download_Blue.svg';
import ErrorIcon from '../../../Components/Images/Icon_Error_Red.svg';

//CSS


export default function ExportPrequalifications({
  formData,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const csvLink = useRef();

    // Component status --> 'onload', 'pending', 'success' & 'error'
    const [status, setStatus] = useState('onload');

    // CSV Report Data
    const [csvData, setCSVData] = useState();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Download all prequalifications and their user data
    function getPrequalifications(){

      setStatus('pending');

      // Get the whole users collection
      GetCollection('users').then((users) => {

        const results = [];

        // Loop through the users, add to report if they exist
        formData.filtered.forEach((prequalification) => {

          // Find the user doc
          const userDoc = users.filter((worker) => worker.emailaddress === prequalification.workeremail)[0];

          // Doc strucuture requsted by Elie Sakr
          results.push({
            'Project': prequalification?.projectname,
            'Organisation': prequalification?.organisationname,
            'Email': prequalification?.workeremail,
            'Givenname': prequalification?.givenname,
            'Surname': prequalification?.surname,
            'Mobile': userDoc?.mobile,
            'Home Phone': userDoc?.phone,
            'City': userDoc?.city,
            'Indigenous': userDoc?.ancestry,
            'Gender': userDoc?.gender,
            'Military Status': userDoc?.militaryveteran,
            'Apprenticeship': userDoc?.apprentice,
            'DCAC Expires on': prequalification?.expirydate,
            'DCAC Ref Number': prequalification?.referenceid,
          });

        });
        
        setCSVData(results);
        setStatus('success');
           
      }).catch((error) => {

        console.log('Unable to extract all Lendlease Users', error);
        setStatus('error');

      });

    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // Reset when the search filters change
    useEffect(() => {

      if (formData.filtered === undefined) return;

      setStatus('onload');

    }, [formData.filtered]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------


  if (status === 'onload') {

    return (
      <img onClick={() => getPrequalifications()} className='cursor-pointer' alt='download-icon' src={CSVImage}></img>
    )

  }

  else if (status === 'pending') {

    return (
      <img className='w-[44px] animate-spin' src={LoadingIcon} alt='loading-icon'></img>
    )

  }

  else if (status === 'success') {

    return (
      <CSVLink className='no-underline' ref={csvLink} filename={`${formData?.project?.projectname}-${Date.now()}.csv`} data={csvData} target='_blank'>
        <img className='animate-bounce' src={DownloadIcon} alt='Download-File'></img>
      </CSVLink>
    )

  }

  else {

    return (

      <img className='w-[44px]' title='Failed to create report, refresh and try again.' src={ErrorIcon} alt='error-icon'></img>

    )

  }

}
