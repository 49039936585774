//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {firebaseConfig} from './FirebaseConfig';
import { doc, setDoc } from 'firebase/firestore'; 


/** Create/Write a Firestore document (always returns a promise)
 * 
 * @param {string} actionedby
 * @param {string} message
 * @param {object} types
 * @param {object} references
 * 
 *  const actionedby = 'mark.bennett2@lendlease.com';
 *  const message = 'This is an audit log';
 *  const types = [
 *      'prequalification history',
 *  ]
 *  const references = [
 *      'mark@gmail.com',
 *      'prj-1690947687351',
 *      'org-1690947834312',
 *      'preq-1234567890',
 *  ];
 * 
 *  WriteAuditLog(message, types, references);
 * 
 *  Documentation
 *  - https://cloud.google.com/community/tutorials/cloud-functions-firestore
 *  - https://cloud.google.com/firestore/docs/samples/firestore-data-set-doc-upsert#firestore_data_set_doc_upsert-nodejs
 *  - https://firebase.google.com/docs/firestore/manage-data/add-data#set_a_document
 */



export default async function WriteAuditLog(actionedby, message, types, references){

  //------------------------------------------------------
  //  Whitelist of types
  //------------------------------------------------------

    const allowedTypes = [
      'prequalification history',
      'project history',
      'organisation history',
      'worker history',
      'app history',
      'app settings',
    ];

  //------------------------------------------------------
  //  Validate inputs
  //------------------------------------------------------

    if (actionedby.length === 0){

      throw new Error(`WriteAuditLog failed: 'actionedby' is a required value!`);

    }

    if (message.length === 0){

      throw new Error(`WriteAuditLog failed: 'message' is a required value!`);

    }

    if (!Array.isArray(types)){

      throw new Error(`WriteAuditLog failed: 'type' MUST be an array!`);
  
    } else {

      let found = false;
      types.forEach((type) => {

        if (allowedTypes.indexOf(type) === -1) {

          found = true;

        }
        
      });

      // Invalid type
      if (found === true) {

        throw new Error(`WriteAuditLog failed: One of the provided 'types' is NOT supported!`);

      }

    }

    if (!Array.isArray(references)){

      throw new Error(`WriteAuditLog failed: 'reference' MUST be an array!`);
  
    }

  //------------------------------------------------------
  //  Update one document with an object 
  //------------------------------------------------------

    //Update existing document
    try {

      //Firestore Database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_number_between_0_inclusive_and_1_exclusive
      const documentId = `${Date.now()}-${actionedby}-${Math.floor(Math.random()*10001)}`;

      //Set collection & define document
      const collectionDocRef = doc(db, 'auditlogs', documentId);
      const auditLogs = {
        'id': documentId,
        'actiondate': new Date(),
        'actionedby': actionedby,
        'message': message,
        'types': types, 
        'references': references,
      };

      //Write to document
      return setDoc(collectionDocRef, auditLogs, { merge: false }).catch((error) => {

        throw new Error(`Function WriteAuditLog failed to complete, error ${error}`);

      });

    } catch (error) {
  
      throw new Error(`Function WriteAuditLog failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}