// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

/**
 * Generic function to get a Firestore Collection
 * 
 * @param {string} collectionId
 * 
 * ```
 * const collectionId = 'users';
 * 
 * GetCollection(collectionId).then((results) =>{
 * 
 *    console.log('results', results)
 *     
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/firestore/query-data/get-data
 */

export default async function GetCollection(collectionId){

  //------------------------------------------------------
  //  Get Collection
  //------------------------------------------------------

    try {

      //Firestore database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Set collection reference > Get collection docs
      const collectionRef = collection(db, collectionId)
      const docsSnap = await getDocs(collectionRef);
          
      const results = []

      //Extract results into an array
      docsSnap.forEach((doc) => {
          results.push(doc.data())
      });
      
      return results

    } catch (error) {
      throw new Error(`Function GetCollection failed to complete, error ${error}`)

    }

  //------------------------------------------------------
}