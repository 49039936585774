//================================================================
//  Component: Worker Profile
//================================================================

//  Purpose: This is the profile for a worker on the admin portal

//  Properties:
//    - formData = {useReducer}
//    - setFormdata = {useReducer}
//    - setPageStatus = {set useState}

//  Example:
//    <WorkerProfile
//      formData={formData}
//      setFormData={setFormData}
//      setPageStatus={setPageStatus}
//    ></WorkerProfile>    

//================================================================


//Libraries
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

//Contexts

//Components

//Functions
import queryDocument from '../../../Library/QueryDocument';
import ConvertDate from '../../../Library/ConvertDate';
import DocumentListener from '../../../Library/DocumentListener';

//Images
import ViewFileIcon from '../../../Components/Images/View_File_Icon.svg';

//Functions

//CSS
import '../PreQualifications.css';

export default function WorkerProfile({
    formData,
    setFormData,
    setPageStatus
}) {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const [searchParms] = useSearchParams();
    const emailaddress = searchParms.get('emailaddress');

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // Set up document listener > Get user document
    useEffect(() => {

        // Retrives user document from 'users' collection in Firestore 
        if (emailaddress === undefined) return

         function onLoad(document){
            setFormData({ 'worker': document });

            queryDocument('files', [
                ['requesttype', '==', 'profile-uploads'],
                ['workeremail', '==', emailaddress],
                ['status', '==', 'transferred'],
            ])
            .then((documents) => {
                setFormData({ 'profileUploadsFiles': documents });

            });
         }
        
         function onChange(document){
            setFormData({ 'worker': document });

            queryDocument('files', [
                ['requesttype', '==', 'profile-uploads'],
                ['workeremail', '==', emailaddress],
                ['status', '==', 'transferred'],
            ])
            .then((documents) => {
                setFormData({ 'profileUploadsFiles': documents });

            });

         }
        
         function onError(){
           setPageStatus('error-fatal');
         }
        
         const unsubscribe = DocumentListener('users', emailaddress, onLoad, onChange, onError);
        
         return () =>{
           unsubscribe();
         };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailaddress])


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='Worker-Profile-Container'>
            <form className='Worker-Profile-Form'>

                {/* ===================================== */}
                {/*          First Name & Surname         */}
                {/* ===================================== */}

                <div className='Worker-Profile-Row' style={{ flexDirection: 'column' }}>

                    <div>
                        <label><b className='font-medium'>FIRST NAME</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.givenname}
                            disabled
                        ></input>
                    </div>

                    <div>
                        <label><b className='font-medium'>LAST NAME</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.surname}
                            disabled
                        ></input>
                    </div>

                </div>

                {/* ===================================== */}
                {/*           ADDRESS LINE 1              */}
                {/* ===================================== */}

                <div className='Worker-Profile-Row' style={{ flexDirection: 'column' }}>

                    <label><b className='font-medium'>ADDRESS LINE 1 </b> </label>
                    <input
                        className='Input-Field-Text-Disabled'
                        type='text'
                        defaultValue={formData?.worker?.addresslineone}
                        disabled
                    ></input>

                </div>

                {/* ===================================== */}
                {/*           ADDRESS LINE 2              */}
                {/* ===================================== */}

                <div className='Worker-Profile-Row' style={{ flexDirection: 'column' }}>

                    <label><b className='font-medium'>ADDRESS LINE 2</b> </label>
                    <input
                        className='Input-Field-Text-Disabled'
                        type='text'
                        defaultValue={formData?.worker?.addresslinetwo}
                        disabled
                    ></input>

                </div>

                {/* ======================================================================== */}
                {/*        SUBURB/CITY    &   STATE/PROVINCE/AREA   &   POSTAL/ZIP CODE      */}
                {/* ======================================================================== */}

                <div className='Worker-Profile-Row flex flex-row'>

                    <div className='w-full'>
                        <label><b className='font-medium'>SUBURB/CITY </b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.city}
                            disabled
                        ></input>
                    </div>

                    <div className='pl-[20px] pr-[20px] w-full'>
                        <label><b className='font-medium'>STATE/PROVINCE/AREA </b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.state}
                            disabled
                        ></input>
                    </div>

                    <div className='w-full'>
                        <label><b className='font-medium'>POSTAL/ZIP CODE </b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.zipcode}
                            disabled
                        ></input>
                    </div>

                </div>
                <br></br>

                {/* ===================================== */}
                {/*         MOBILE & HOME PHONE           */}
                {/* ===================================== */}

                <div className='Worker-Profile-Row flex flex-row w-full'>

                    <div className='w-full'>
                        <label><b className='font-medium'>MOBILE </b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.mobile}
                            disabled
                        ></input>
                    </div>

                    <div className='pl-[20px] w-full'>
                        <label><b className='font-medium'>HOME PHONE</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.phone}
                            disabled
                        ></input>
                    </div>

                </div>
                <br></br>

                {/* ===================================== */}
                {/*         EMAIL & DATE OF BIRTH         */}
                {/* ===================================== */}

                <div className='Worker-Profile-Row flex flex-row'>

                    <div className='w-full'>
                        <label><b className='font-medium'>EMAIL</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            disabled={true}
                            defaultValue={formData?.worker?.emailaddress}
                        ></input>
                    </div>

                    <div className='pl-[20px] pr-[20px] w-full'>
                        <label><b className='font-medium'>DATE OF BIRTH</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.dob}
                            disabled
                        ></input>
                    </div>

                    <div className='w-full'>
                        <label><b className='font-medium'>GENDER</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.gender}
                            disabled
                        ></input>
                    </div>

                </div>

                {/* ========================================================= */}
                {/*    EMERGENCY CONTACT NAME   &   EMERGENCY CONTACT NUMBER  */}
                {/* ========================================================= */}

                <div className='Worker-Profile-Row flex flex-row'>

                    <div className='w-full'>
                        <label><b className='font-medium'>EMERGENCY CONTACT NAME</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.emergencycontact}
                            disabled
                        ></input>
                    </div>

                    <div className='pl-[20px] w-full'>
                        <label><b className='font-medium'>EMERGENCY CONTACT NUMBER</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            defaultValue={formData?.worker?.emergencycontactphone}
                            disabled
                        ></input>
                    </div>

                </div>

                {/* ========================================================== */}
                {/*    ABORIGINAL OR TORRES STRAIT ISLANDER & MILITARY STATUS  */}
                {/* ========================================================== */}

                <div className='Worker-Profile-Row flex flex-row'>

                    <div className='w-full'>
                        <label><b className='font-medium'>ABORIGINAL OR TORRES STRAIT ISLANDER</b> </label>
                        <input
                            className='Input-Field-Select'
                            defaultValue={formData?.worker?.ancestry}
                            disabled
                        ></input>
                    </div>

                    <div className='pl-[20px] w-full'>
                        <label><b className='font-medium'>MILITARY STATUS</b> </label>
                        <input
                            className='Input-Field-Select'
                            defaultValue={formData?.worker?.militaryveteran}
                            disabled
                        ></input>
                    </div>

                </div>

                {/* ========================================= */}
                {/*       APPRENTICE   &   ORGANISATION       */}
                {/* ========================================= */}

                <div className='Worker-Profile-Row flex flex-row'>

                    <div className='w-full'>
                        <label><b className='font-medium'>APPRENTICE </b> </label>
                        <input
                            className='Input-Field-Select'
                            defaultValue={formData?.worker?.apprentice}
                            disabled
                        ></input>
                    </div>

                    <div className='pl-[20px] w-full'>
                        <label><b className='font-medium'>ORGANISATION</b> </label>
                        <input
                            className='Input-Field-Text-Disabled'
                            type='text'
                            disabled={true}
                            defaultValue={formData?.worker?.organisationname}
                        ></input>
                    </div>

                </div>


                {/* ============================================================ */}
                {/*     SUB-SUBCONTRACTOR  &   SUB-SUBCONTRACTOR ORGANISATION    */}
                {/* ============================================================ */}

                <div className='Worker-Profile-Row flex flex-row'>

                    <div className='w-full'>
                        <label><b className='font-medium'>SUB-SUBCONTRACTOR </b> </label>
                        <input
                            className='Input-Field-Select'
                            defaultValue={formData?.worker?.subby}
                            disabled
                        ></input>
                    </div>

                    <div className='pl-[20px] w-full'>
                        {

                            <>
                                <label><b className='font-medium'>SUB-SUBCONTRACTOR ORGANISATION</b> </label>
                                <input
                                    className='Input-Field-Text-Disabled'
                                    type='text'
                                    defaultValue={formData?.worker?.subbyorg}
                                    disabled
                                ></input>
                            </>

                        }
                    </div>

                </div>

                {/* ========================================= */}
                {/*             MEDICAL CONDITIONS            */}
                {/* ========================================= */}

                <div className='Worker-Profile-Row flex flex-col'>

                    <label><b className='font-medium'>MEDICAL CONDITIONS</b> </label>
                    <textarea
                        className='Input-Field-TextArea'
                        defaultValue={formData?.worker?.medical}
                        disabled
                    ></textarea>

                </div>

                {/* ========================================= */}
                {/*                ALLERGIES                  */}
                {/* ========================================= */}

                <div className='Worker-Profile-Row flex flex-col'>

                    <label><b className='font-medium'>ALLERGIES</b> </label>
                    <textarea
                        className='Input-Field-TextArea'
                        defaultValue={formData?.worker?.allergies}
                        disabled
                    ></textarea>

                </div>

                {/* ========================================= */}
                {/*            PROFILE UPLOADS                */}
                {/* ========================================= */}

                <div className='Worker-Profile-Row flex flex-col'>

                    {/* Header */}
                    <label>
                        <b className='font-medium'>PROFILE UPLOADS</b>
                    </label>

                    {/* Uploads Table */}
                    {
                        formData?.profileUploadsFiles.length > 0 ?
                        (
                            <div className='Worker-Profile-Uploads-List-Container' style={{marginBottom: '15px'}}>
                                <div className='Worker-Profile-Uploads-List-Header'>
                                    <b>FILE NAME</b>
                                    <b>DATE OBTAINED</b>
                                </div>

                                {/* File Link */}
                                {
                                    formData?.profileUploadsFiles.map((object, index) => (
                                        <div className='Worker-Profile-Uploads-List-Row' key={index}>

                                            <p>{object.filename}</p>
                                            <p>{ConvertDate(object?.dateobtained)}</p>
                                            <a
                                              href={object.signedurl} 
                                              alt={object.filename} 
                                              target='_blank' 
                                              rel='noreferrer'>
                                              <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                            </a>  
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        :
                        (
                            <div style={{marginBottom: '15px'}}>
                                No uploads.
                            </div>
                        )
                    }

                </div>
            </form >
        </div >
    )
}
