//================================================================
//  Component: AuditLogs.js
//================================================================

//  Purpose: This is a page to display a table of auditlogs in the global admin portal

//  Properties:

//  Example:
//    <AuditLogs></AuditLogs>    

//================================================================

//Libraries
import React, { useEffect, useState } from 'react';

//Contexts

//Functions
import QueryDocument from '../../../../Library/QueryDocument';
import ConvertDate from '../../../../Library/ConvertDate';

//Components

//Images
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';
import IconSort from '../../../../Components/Images/Icon_Sort_LightGrey.svg';
import IconSortClicked from '../../../../Components/Images/Icon_Sort_DarkGrey.svg'

//CSS
import '../../AppSettings.css';


export default function AuditLogs() {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

        // Filtered 'auditlogs' collection
        const [auditLogsFiltered, setAuditLogsFiltered] = useState([]);

        // Search input
        const [searchInput, setSearchInput] = useState('');

        // Use state to sort date column > 'asc', 'desc'
        const [dateDesc, setDateDesc] = useState(true);

        // Use state to sort action by column > 'asc', 'desc'
        const [actionedByDesc, setActionedByDesc] = useState(true);

        // Use state to sort types column > 'asc', 'desc'
        const [typesDesc, setTypesDesc] = useState(true);

        // Use state to set the current column that is sorting > 'actiondate', 'actionedby', 'types'
        const [currentSortColumn, setCurrentSortColumn] = useState('actiondate')

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

        // handles the search input & filtering
        function handleSearch(value) {

            setSearchInput(value);

            // Reset the Filter
            if (value.length === 0) {

                return QueryDocument('auditlogs', '', ['actiondate', 'desc'], '').then((documents) => {

                    setAuditLogsFiltered([...documents]);

                });

            };

            QueryDocument('auditlogs', '', ['actiondate', 'desc'], '').then((documents) => {

                // Search Filter
                //  1. Date
                //  2. Action By
                //  3. Message
                //  4. Types
                const searchResults = documents.filter((object) =>
                    ConvertDate(object?.actiondate).includes(value) ||
                    object?.actionedby?.toLowerCase().includes(value.toLowerCase()) ||
                    object?.message?.toLowerCase().includes(value.toLowerCase()) ||
                    object?.types[0].toLowerCase().includes(value.toLowerCase())
                );

                // Save the filtered audit logs to the useState
                setAuditLogsFiltered(searchResults);

            });

        };

        // Used to sort the date column
        function sortDate() {

            setDateDesc(!dateDesc);

            setCurrentSortColumn('actiondate');

            if (dateDesc) {

                // Get the latest data from Firestore "asc"
                QueryDocument(`auditlogs`, "", ["actiondate", "asc"], '').then((documents) => {

                    setAuditLogsFiltered([...documents]);

                });


            } else {

                // Get the latest data from Firestore "desc"
                QueryDocument(`auditlogs`, "", ["actiondate", "desc"], '').then((documents) => {

                    setAuditLogsFiltered([...documents]);

                });
            }

        }

        // Used to sort the actionedby column
        function sortActionedBy() {

            setActionedByDesc(!actionedByDesc);

            setCurrentSortColumn('actionedby');

            if (actionedByDesc) {

                // Get the latest data from Firestore "asc"
                QueryDocument(`auditlogs`, "", ["actionedby", "asc"], '').then((documents) => {

                    setAuditLogsFiltered([...documents]);

                });


            } else {

                // Get the latest data from Firestore "desc"
                QueryDocument(`auditlogs`, "", ["actionedby", "desc"], '').then((documents) => {

                    setAuditLogsFiltered([...documents]);

                });
            }

        }

        // Used to sort the types column
        function sortTypes() {

            setTypesDesc(!typesDesc);

            setCurrentSortColumn('types');
    
            if (typesDesc) {
    
                // Get the latest data from Firestore "asc"
                QueryDocument(`auditlogs`, "", ["types", "asc"], '').then((documents) => {
    
                    setAuditLogsFiltered([...documents]);
    
                });
    
    
            } else {
    
                // Get the latest data from Firestore "desc"
                QueryDocument(`auditlogs`, "", ["types", "desc"], '').then((documents) => {
    
                    setAuditLogsFiltered([...documents]);
    
                });
            }
    
        }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

        // onLoad
        // 1.  Query the auditlogs collection by action date descending
        useEffect(() => {

            QueryDocument('auditlogs', '', ['actiondate', 'desc'], '').then((documents) => {

                setAuditLogsFiltered([...documents]);

            })

        }, []);


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------


        return (
            <div className='Page-Body-Container flex flex-col gap-4'>

                {/* Search Bar */}
                <div className='AppSettings-Search' hidden={auditLogsFiltered.length === 0}>
                    <img src={IconSearch} alt='searchInput-icon'></img>
                    <label htmlFor='searchInput'>
                        <input id='searchInput'
                            type='text'
                            placeholder='Search Filter'
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchInput}
                        ></input>
                    </label>
                </div>
                
                
                {/* Table */}
                {
                   auditLogsFiltered.length > 0 ?
                   (
                    <table className='Table-Container shadow-sm mb-4'>
                        <colgroup>
                            <col span='1' style={{width: '20%'}}></col>
                            <col span='1' style={{width: '25%'}}></col>
                            <col span='1' style={{width: '35%'}}></col>
                            <col span='1' style={{width: '20%'}}></col>
                        </colgroup>
                        <thead className='AppSettings-AuditLogs-Header'>
                            <tr className='text-[14px] tracking-wider bg-slate-200'>

                                {/* Action Date */}
                                <th>
                                    <div className='flex flex-row text-[14px] items-center'>
                                        DATE
                                        <img
                                            style={dateDesc ? { rotate: '180deg' } : { rotate: '0deg' }}
                                            className='cursor-pointer'
                                            src={currentSortColumn === 'actiondate' ? IconSortClicked : IconSort}
                                            alt='Sort'
                                            onClick={() => sortDate()}
                                        ></img>
                                    </div>
                                </th>

                                {/* Actioned By */}
                                <th>
                                    <div className='flex flex-row text-[14px] items-center'>
                                        ACTIONED BY
                                        <img
                                            style={actionedByDesc ? { rotate: '180deg' } : { rotate: '0deg' }}
                                            className='cursor-pointer'
                                            src={currentSortColumn === 'actionedby' ? IconSortClicked : IconSort}
                                            alt='Sort'
                                            onClick={() => sortActionedBy()}
                                        ></img>
                                    </div>
                                </th>

                                {/* Details */}
                                <th>
                                    <div className='flex flex-row text-[14px] items-center'>
                                        DETAILS
                                    </div>
                                </th>

                                {/* Types */}
                                <th>
                                    <div className='flex flex-row text-[14px] items-center'>
                                        TYPES
                                        <img
                                            style={typesDesc ? { rotate: '180deg' } : { rotate: '0deg' }}
                                            className='cursor-pointer'
                                            src={currentSortColumn === 'types' ? IconSortClicked : IconSort}
                                            alt='Sort'
                                            onClick={() => sortTypes()}
                                        ></img>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        {/* Map of Data */}
                        <tbody>
                            {
                                auditLogsFiltered?.map((auditLogObject) => (
                                    <tr key={auditLogObject.id} className='cursor-auto border-b border-[#E0E0E0] break-all'>
                                        <td>{ConvertDate(auditLogObject?.actiondate)}</td>
                                        <td>{auditLogObject.actionedby}</td>
                                        <td>{auditLogObject.message.split('-- ')[1]}</td>
                                        <td>{auditLogObject.types[0]}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                   )
                   :
                   (
                    <div>
                        No audit logs found.
                    </div>
                   )
                }

            </div>
        )
}
