//================================================================
//  Component: Delete Organisation Modal
//================================================================

//  Purpose: Allows global administrators to delete an organisation

//  Properties:
//    - orgDoc = {object, document containing organisation data}
//    - handleDeleteOrg = {function - used to delete the organisation}
//    - setModalVisible = {useState - used to toggle visibility of the delete organisation modal}

//  Example:
//  <DeleteOrgModal
//     orgDoc={org}
//     handleDeleteOrg={deleteOrganisation}
//     setModalVisible={setModalDeleteOrg}
//  ></DeleteOrgModal>

//================================================================


//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images
import DeleteIcon from '../../../../Components/Images/Icon_Delete_Red.svg';

//CSS
import '../../AppSettings';


export default function DeleteOrgModal({
  orgDoc,
  handleDeleteOrg,
  setModalVisible
}) {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Modal-Background'>
      <div className='AppSettings-Modal-Worker'>
        <div className='Action-Modal-Container'>

          {/* Modal Icon */}
          <div className='Action-Modal-Icon'>
            <img src={DeleteIcon} alt='Delete-Icon'></img>
          </div>
          
          {/* Modal Body */}
          <div className='Action-Modal-Body'>

            <h4 style={{marginTop: '14px'}}>
              Delete Organisation
            </h4>

            <p className='m-0'> 
              Are you sure you want to delete the organisation below? 
              <ul className='my-2'>
                <li className='list-disc font-medium'>{orgDoc.organisationname}</li>
              </ul> 
              All user data will be <b className='font-semibold'>permanently deleted</b> and cannot be recovered.
            </p>

            {/* Invite User Form */}
            <div className='Action-Modal-Body-Form'>
              
              {/* Submit Button */}
              <div style={{marginTop: '20px'}} className='Button-Group'>
                <button 
                  style={{backgroundColor: '#D85C5C', border: '1px solid #D85C5C'}}
                  className='Primary-Button' 
                  onClick={() => handleDeleteOrg(orgDoc.organisationid)}
                  // Benno ~ Added type 'button' to prevent form submission
                  type='button'
                > 
                  Remove
                </button>
                <button 
                  className='Secondary-Button'
                  style={{color: '#D85C5C', border: '1px solid #D85C5C'}}
                  onClick={() => setModalVisible(false)}
                  // Benno ~ Added type 'button' to prevent form submission
                  type='button'
                >
                  Cancel
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
