//================================================================
//  Page: Approve Application Modal 
//================================================================

//  Purpose: This is a modal to approve a prequalification application

//  Example
// <ApplicationApproveApplicationModal
//     modalVisibility={modalVisibility.rejectModalVisible}
//     setModalVisibility={setModalVisibility}
//     prequalificationDoc={formData.prequalificationDoc}
//     currentUser={currentUser}
// ></ApplicationApproveApplicationModal>

//================================================================

//Libraries
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Contexts

//Components

//Functions
import WriteDocument from '../../Library/WriteDocument';

//Images
import ApproveApplicationIcon from '../../Components/Images/ApproveApplication_Icon.svg';

//CSS
import '../ApplicationApprovalsModals/ApplicationApprovalsModals.css'

export default function ApplicationApproveApplicationModal({
    modalVisibility,
    setModalVisibility,
    prequalificationDoc,
    currentUser
}) {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const projectid = searchParms.get('project');

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to set modal status > 'onload', 'error', 'success' - success = modal closed and back to project prequal page
    const [modalStatus, setModalStatus] = useState('onload');

    const [approveApplicationDCACExpiryDate, setApproveApplicationDCACExpiryDate] = useState('');
    const [approveApplicationLendleaseRefNo, setApproveApplicationLendleaseRefNo] = useState('');

    const [approveButtonValid, setApproveButtonValid] = useState(false);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    function updatePrequalificationDocStatus() {

        if (!approveButtonValid) return;

        const newAuditHistory = {
            actionedby: currentUser.emailaddress,
            actiondate: new Date(),
            type: 'Approved',
            message: 'Prequalification submission has been approved.'
        };

        const existingAuditHistory = prequalificationDoc.audithistory;
        existingAuditHistory.push(newAuditHistory);

        const updatedDocument = {
            'status': 'approved',
            'expirydate': approveApplicationDCACExpiryDate,
            'referenceid': approveApplicationLendleaseRefNo,
            'audithistory': existingAuditHistory
        }

        WriteDocument('prequalifications', prequalificationDoc.prequalificationid, updatedDocument, true).then(() => {

            setModalStatus('success')
            navigate(`/prequalifications?project=${projectid}`)

        }).catch(() => {

            setModalStatus('error');

        });
    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    // Keeps modal closed while not in use
    if (!modalVisibility) return null;

    // Onload state when Send for Approval option on Approve PreQualifications-DropDown selected
    if (modalStatus === 'onload') {
        return (
            <div className='Modal-Background' onClick={() => setModalVisibility({ 'approveModalVisible': false })}>
                <div className='Approve-Application-Modal-Container'>
                    <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>

                        {/* Modal Icon */}
                        <div className='Action-Modal-Icon'>
                            <img src={ApproveApplicationIcon} alt='Approve Application Icon'></img>
                        </div>

                        {/* Modal Text Body */}
                        <div className='Action-Modal-Body'>
                            <h4>Approve Application</h4>

                            <p>To approve this application, please complete the form below.</p>

                            {/* DCAC Expiry Date */}
                            <label>DCAC EXPIRY DATE</label>
                            <input 
                                type='date'
                                name='DCACExpiryDate'
                                className='Action-Modal-Input-Field'
                                style={{marginBottom: '15px'}}
                                min={new Date().toISOString().split('T')[0]}
                                onChange={(e) => {
                                    // Checks that both fields are completed, if true then makes button valid
                                    if (approveApplicationLendleaseRefNo.length > 0 && e.target.value.length > 0) {

                                        setApproveButtonValid(true);

                                    }

                                    setApproveApplicationDCACExpiryDate(e.target.value);
                                    
                                }}
                                value={approveApplicationDCACExpiryDate}
                            ></input>

                            
                            {/* Lendlease Reference Number */}
                            <label>LENDLEASE REFERENCE NO.</label>
                            <input type='text' style={{ width: '100%', marginBottom: '30px' }}
                                name='LendleaseRefNo'
                                className='Action-Modal-Input-Field'
                                onChange={(e) => {
                                    // Checks that both fields are completed, if true then makes button valid
                                    if (approveApplicationDCACExpiryDate.length > 0 && e.target.value.length > 0) {

                                        setApproveButtonValid(true);

                                    }

                                    setApproveApplicationLendleaseRefNo(e.target.value);

                                }}
                                value={approveApplicationLendleaseRefNo}
                            ></input>

                            <div className='Action-Modal-Button-Container '>
                                {/* Confirm Button */}
                                <button
                                    disabled={!approveButtonValid}
                                    className='Primary-Button'
                                    style={{backgroundColor: '#61B280', borderColor: '#61B280'}}
                                    onClick={() => updatePrequalificationDocStatus()}
                                >
                                    Approve
                                </button>

                                {/* Cancel Button */}
                                <button className='Secondary-Button' style={{borderColor: '#4B4B4B', color: '#4B4B4B'}} onClick={() => setModalVisibility({ 'approveModalVisible': false })}> 
                                    Cancel 
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        )
    }

    // Modal Error State if WriteDocument failed
    else if (modalStatus === 'error') {
        return (
            <div className='Modal-Background' onClick={() => setModalVisibility({ 'approveModalVisible': false })}>
                <div className='Approve-Application-Modal-Container'>
                    <div className='Action-Modal-Container' onClick={(e) => { e.stopPropagation() }}>


                        {/* Modal Icon */}
                        <div className='Action-Modal-Icon'>
                            <img src={ApproveApplicationIcon} alt='Approve Application Icon'></img>
                        </div>

                        {/* Modal Text Body */}
                        <div className='Action-Modal-Body'>
                            <h4>Approve Application</h4>

                            <p>
                                Oops something went wrong!
                                <br></br>
                                There was an error processing your request.
                            </p>

                            <div className='Action-Modal-Button-Container'>
                                <button className='Primary-Button' style={{backgroundColor: '#61B280', borderColor: '#61B280'}} onClick={() => setModalVisibility({ 'approveModalVisible': false })}>Go Back</button>
                            </div>
                           
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}