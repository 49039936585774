//================================================================
//  Component: Navigation Header
//================================================================

//  Purpose: This is the navigation header for the Lendlease DCAC Admin Portal

//  Example:
//    <NavHeader></NavHeader>

//================================================================


//Libraries
import React, { useContext, useEffect } from 'react';
import { useNavigate, NavLink, useSearchParams } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors, SetAppStatus, GetProjectSelector, SetProjectSelector } from '../../Library/GlobalContexts';

//Components
import ProjectSelector from '../ProjectSelector/ProjectSelector';
import Logout from '../../Library/Logout';

//Functions
import CheckUserRole from '../../Library/checkUserRole';
import getDocument from '../../Library/GetDocument';
import QueryListener from '../../Library/QueryListener';

//Images
import LLLogo from '../Images/Image_LLLogo_Blue.png';
import ExpandIcon from '../Images/Icon_Expand_White.svg';
import Settings from '../Images/Icon_Settings_White.svg';
import AppSettings from '../Images/Icon_AppSettings_Black.svg';

//CSS
import './NavHeader.css';


export default function NavHeader() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
  
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const projectid = searchParams.get('project');


  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser); 
    const setAppErrors = useContext(SetAppErrors);
    const setAppStatus = useContext(SetAppStatus)
    const getProjectSelector = useContext(GetProjectSelector);
    const setProjectSelector = useContext(SetProjectSelector);  

  //------------------------------------------------------
  //  Define variables
  //------------------------------------------------------
  
    const modalRoot = document.getElementById('projectselector');
    const modalElement = document.createElement('div');


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Set modal visibility for the project selector
    function showModal() {
  
      getProjectSelector.visible = !getProjectSelector.visible;

      //Update getProjectSelector context
      setProjectSelector({...getProjectSelector})

    };

  
  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // Onload
    //  1. listen for getUser changes
    //  2. Check the users role
    //  3. Update 'projects' list based off their role
    useEffect(() => {

      // Conditions
      if (getUser === undefined) return;
      if (getUser === undefined) return;

      // globalAdmin
      if (getUser.roles.globalAdmin === true) {

        function onLoadChange(documents){

          getProjectSelector.projects = documents;
          setProjectSelector({...getProjectSelector});

        }
    
        function onError(error){

          setAppStatus('failed');
          setAppErrors(`Failed to get project information, unable to create the document listener error: ${error}`);  
        
        }
    
        const unsubscribe = QueryListener('projects', [
          ['status', '==', 'active']
        ], onLoadChange, onLoadChange, onError)
    
        return () =>{
          unsubscribe();
        };
        
      }

      // projectAdmins
      if (getUser.roles.globalAdmin === false) {

        const projectPromises = [];
        getUser.projectAdmin.forEach((projectid) => {

          projectPromises.push(
            getDocument('projects', projectid)
          );

        });

        Promise.allSettled(projectPromises).then((projectPromises) => {

          const projects = [];
          projectPromises.forEach((promise) => {

            // Hide failed promises and inactive projects
            if (promise.status !==  'fulfilled') return;
            if (promise.value.status !== 'active') return;

            projects.push(promise.value);

          });

          getProjectSelector.projects = projects;
          setProjectSelector({...getProjectSelector});
  
        });

      }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);


    // onLoad
    //  1. Wait for component to mount
    //  2. Appends modal 
    useEffect(() => {

      if (modalRoot === undefined) return;
      if (modalElement === undefined) return;

      modalRoot.appendChild(modalElement);

      return () => {

        modalRoot.removeChild(modalElement);

      };
  
    }, [modalRoot, modalElement]);

    
    // onChange
    //  1. Listen for searchParms changes
    //  2. IF the project exists, update the UI
    useEffect(() => {

      // Conditions
      if (projectid === null) return;
      if (projectid === undefined) return;

      // Ensure the projectid exists in context ('projects' collection)
      getDocument('projects', projectid).then((projectObject) => {

        // Only projects that exist will trigger an update
        getProjectSelector.selectedProject = projectObject;
        setProjectSelector({...getProjectSelector});

      }).catch(() => {

        getProjectSelector.selectedProject = undefined;
        setProjectSelector({...getProjectSelector});

      });
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectid]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='NavHeader-Container'>

        {/*----------------------------------------------- */}
        {/*   Title and Logo                               */}
        {/*------------------------------------------------*/}
    
        <div className='NavHeader-LeftPane'>

          <NavLink to='/'>
            <img className='NavHeader-Logo' src={LLLogo} alt='Lendlease Logo' onClick={() => navigate('/')}></img>
          </NavLink>

          <h4 className='whitespace-nowrap overflow-hidden overflow-ellipsis'> Lendlease DCAC Administrator Portal </h4>

        </div>

        <div className='NavHeader-RightPane'>

          {/*----------------------------------------------- */}
          {/*   Project Selector Dropdown                    */}
          {/*     - Update based off context                 */}
          {/*     - Defaults to 'Select a Project'           */}
          {/*------------------------------------------------*/}

          <button className='Project-Selector-Dropdown' onClick={() => showModal()}> 
            {
              getProjectSelector?.selectedProject?.projectname !== undefined ? 
              (
                <>{getProjectSelector.selectedProject.projectname}</>
              )
              :
              ( 
                <>Select a Project</>
              )
            }
            <img src={ExpandIcon} alt='Dropdown'></img>
          </button>

          {/* Project Selector Modal */}
          { getProjectSelector.visible && <ProjectSelector></ProjectSelector> }

          {/*----------------------------------------------- */}
          {/*   Settings                                     */}
          {/*------------------------------------------------*/}

          {/* Icon */}
          <img className='Settings-Icon' src={Settings} alt='Settings'></img>

          {/* Dropdown */}
          <div className='Settings-Dropdown'>

              {/* App settings (only visible to global admins) */}
              {
                CheckUserRole(['globalAdmin'], getUser?.roles, true) &&
                
                <NavLink to='/appsettings?view=permissions' className='Settings-Dropdown-Link'>
                  App Settings

                  <img className='Settings-Link-Icon' src={AppSettings} alt="App Settings Icon"></img>
                </NavLink>
              }

              <Logout title='Sign out' setStyle='navbar'></Logout>

          </div>

        </div>

      </div>
    )
  
}
