//================================================================
//  Component: Organisations
//================================================================

//  Purpose: This is the standard for creating a component

//  Example:
//    <Organisations></Organisations>    

//================================================================


//Libraries
import React, { useState, useEffect, useReducer } from 'react';

//Contexts

//Functions
import QueryListener from '../../../../Library/QueryListener';

//Components
import OrganisationTableRow from './OrganisationTableRow';

//Images
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';

//CSS
import '../../AppSettings.css';


export default function Organisations() {

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

  // Used to determine the status of the page > 'pending', 'onload', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');
  
  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'search': '',
        'searchResultsCount': '',
        'filteredOrgs': [],
        'allOrgs': [],
        'errorMessage': '',
      }
    );

  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Search for all orgs
    function searchByName(value) {

      formData.search = value;

      if (value === '') {

        // Reset Filter
        formData.filteredOrgs = formData.allOrgs;
        formData.searchSelectedOrg = undefined;
        formData.searchResultsCount = '';
      
      } else {

        const results = formData.allOrgs.filter((object) => 

          object?.organisationname?.toLowerCase()?.includes(value?.toLowerCase()

        ));

        formData.filteredOrgs = results;
        formData.searchResultsCount = results.length;

      };

      setFormData(formData);

    };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onload
    //  1. Create a queryListener for all active orgs
    //  2. Sort results to find attached orgs to project
    //  3. Update formData.allOrgs
    useEffect(() => {

      function onLoadChange(orgs){
        setPageStatus('onload');
        setFormData({
          'allOrgs': orgs,
          'filteredOrgs': orgs,
        });

      };
      
      function onError(error){
        setFormData({ 'errorMessage' : error })
        setPageStatus('error-fatal')

      };

      const unsubscribe = QueryListener('organisations', [
        ['status', '==', 'active']
      ], onLoadChange, onLoadChange, onError);

      return () => {

        unsubscribe();
        
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------
      //====================================================
      //  Pending
      //====================================================
      
      if (pageStatus === 'pending') {
        return (
            <div className='Page-Body-Container'>
                <div className='flex justify-center'>
                    <img alt='loading-circle-icon' src={LoadingIcon}></img>
                </div>
            </div>
        )
      }

    //====================================================
    //  Error-Fatal
    //====================================================

    else if (pageStatus === 'error-fatal') {
      return (
          <div className='Page-Body-Container'>
              <div className='flex flex-col text-center gap-2 my-2'>
                  <h4>Oops something went wrong!</h4>
                  
                  <p className='leading-7 mb-2'>
                      An unknown error has occurred while loading this page.
                      <br></br>
                      <b>Error Message:</b> {formData?.errorMessage}
                      <br></br>
                      <br></br>
                      If the error persists, please speak reach out to your support team. 
                  </p>
  
                  <div>
                      <button className='Primary-Button' onClick={() => window.location.reload() }>Try Again</button>
                  </div>
              </div>
          </div>
      )
    }

    //====================================================
    //  Onload
    //====================================================

    else if (pageStatus === 'onload') {
      return (
        <div className='Page-Body-Container flex flex-col gap-4'>

          {/* ===================================================== */}
          {/*                     Search                            */}
          {/* ===================================================== */}

          <div className='AppSettings-Search-Container' hidden={formData?.filteredOrgs.length === 0}>

            <div className='AppSettings-Search'>
              <img src={IconSearch} alt='search-icon'></img>
              <input
                type='text'
                placeholder='Search for an organisation'
                onChange={(e) => searchByName(e.target.value)}
                value={formData.search}
              ></input>
            </div>
            {
              formData.searchResultsCount !== '' && (
                <h6 className='text-[15px] mt-4 mb-0 font-normal'>
                  Showing {formData.searchResultsCount} of {formData.allOrgs.length} Organisations
                </h6>
              )
            }

          </div>

          {/* ===================================================== */}
          {/*                     Org Table                         */}
          {/* ===================================================== */}

          { 
            formData?.filteredOrgs.length > 0 ?
            (
              <div className='AppSettings-Table mb-4'>
                <div className='Project-Selector-Table'>
                  {
                    formData?.filteredOrgs?.map((org, index) =>(

                      <div key={index}>
                        <OrganisationTableRow
                          org={org}
                          pageStatus={pageStatus}
                          setPageStatus={setPageStatus}
                          setErrorMessage={setFormData}
                        ></OrganisationTableRow>
                      </div>

                    ))
                  }
                </div>
              </div>
            )
            :
            (
              <div>
                No organisations found.
              </div>
            )
          }
          
        </div>

      )
    }
}
