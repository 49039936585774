//================================================================
//  Page: PreQualifications 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React, { useContext, useState, useEffect, useReducer } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

//Contexts
import { SetAppErrors, GetProjectSelector} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import ExportPrequalifications from './Components/ExportPrequalifications.js';
import StatusLabel from '../../Components/StatusLabel/StatusLabel';

//Functions
import ConvertDate from '../../Library/ConvertDate.js';
import queryListener from '../../Library/QueryListener';

//Images
import PaneReview from '../../Components/Images/Pane_Review.svg';

import PaneMoreInfo from '../../Components/Images/Pane_More_Info.svg';
import PanePendingApproval from '../../Components/Images/Pane_Pending_Approval.svg';
import PaneApproved from '../../Components/Images/Pane_Approved.svg';
import PaneRejected from '../../Components/Images/Pane_Rejected.svg';
import PaneExpired from '../../Components/Images/Pane_Expired.svg';

//CSS
import './PreQualifications.css';


export default function PreQualifications() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const [ searchParms ] = useSearchParams();
    const projectid = searchParms.get('project');
    const navigate = useNavigate();

    const setAppErrors = useContext(SetAppErrors);
    const getProjectSelector = useContext(GetProjectSelector);

  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-other', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  const [formData, setFormData] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      'project': '',
      'filterby': '',
      'search': '',
      'prequalifications': [],
      'filtered': [],
    }
  );


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handles the Filter By PreQualifications-DropDown
    function filterBy(value){

      if (value === '') {

        // Reset Filter
        setFormData({'filtered': formData.prequalifications});
      
      } else {

        const results = formData.prequalifications.filter((object) => object.status === value)

        setFormData( {
          'filtered': results
        });

      }

    }

    // Handles the Filter By PreQualifications-DropDown
    function searchByName(value){

      if (value === '') {

        // Reset Filter
        setFormData({'filtered': formData.prequalifications});
      
      } else {

        const results = formData.prequalifications.filter((object) => 
        `${object?.givenname?.toLowerCase()} ${object?.surname?.toLowerCase()}`?.includes(value?.toLowerCase()
        ))

        setFormData( {
          'filtered': results
        });

      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onLoad
    //  1. Get Pre-Qualifications
    useEffect(() => {

      // Conditions
      if (formData?.project?.length === 0) return setPageStatus('accessdenied');
      if (formData?.project?.projectid === undefined) return setPageStatus('accessdenied');

      function onLoad(documents) {

        const results = documents.filter((doc) => doc.status !== 'decommissioned');

        setFormData({
          'prequalifications': results,
          'filtered': results,
        });

      }

      function onChange(documents) {

        const results = documents.filter((doc) => doc.status !== 'decommissioned');

        setFormData({'prequalifications': results});

      }

      function onError(error) {

        setAppErrors(`Fatal error has occured, unable to reach Firestore to extract Pre-Qualifications list! ${error}`);
        
      }

      const unsubscribe = queryListener('prequalifications', [
        ['projectid', '==', formData.project.projectid],
        ['status', '!=', 'not-submitted'],
      ], onLoad, onChange, onError);

      return () => {
        unsubscribe();
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.project]);


    // onChange
    //  1. Project from searchParms & extract data from context
    useEffect(() => {

      // Conditionals
      if (projectid === undefined) return setPageStatus('accessdenied');;
      if (projectid === null) return setPageStatus('accessdenied');;
      if (getProjectSelector.projects.length === 0) return setPageStatus('accessdenied');;

      // Ensure the projectid exists in context ('projects' collection)
      const projectObject = getProjectSelector.projects.filter((object) => object.projectid === projectid)[0];

      if (projectObject === undefined) return setPageStatus('accessdenied');

      setFormData({'project': projectObject});
      setPageStatus('onload');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProjectSelector]);

    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requiredRoles={ ['projectAdmin', 'globalAdmin'] }
        requiredRolesValues={ [projectid, true] }
        status={pageStatus}
        header={
          <div className='Worker-Header-Container'>
            <div className='PreQualifications-Header'>
              <h5 className='m-0'>Pre-Qualifications</h5>
            </div>
          </div>
        }
        body={
          <div className='PreQualifications-Container'>

            {/* ==================================== */}
            {/*               Dashboard              */}
            {/* ==================================== */}

            <div className='PreQualifications-Dashboard'>

              {
                [ 
                  {'name': 'In Review', 'image': PaneReview, 'background': '#84C1D4', 'status': 'review'}, 
                  {'name': 'Requires More Info', 'image': PaneMoreInfo, 'background': '#E47525', 'status': 'moreinfo'}, 
                  {'name': 'Pending Approval', 'image': PanePendingApproval, 'background': '#E4BA25', 'status': 'pendingapproval'}, 
                  {'name': 'Approved', 'image': PaneApproved, 'background': '#61B280', 'status': 'approved'}, 
                  {'name': 'Rejected', 'image': PaneRejected, 'background': '#D85C5C', 'status': 'rejected'}, 
                  {'name': 'Expired', 'image': PaneExpired, 'background': '#C0C0C0', 'status': 'expired'}, 
                ].map((style, index) => (

                  <div key={index} className='PreQualifications-Dashboard-Tile'>

                    <div className='PreQualifications-Dashboard-Tile-Number'>
                      {formData?.filtered?.filter((object) => object.status === style.status).length}
                    </div>
                    <div style={{backgroundColor: style.background}} className='PreQualifications-Dashboard-Tile-Status'>
                      <img
                        src={style.image}
                        alt={`icon-${style.status}`}
                      ></img>
                    </div>

                  </div>
                
                ))
              }

            </div>

            {/* ==================================== */}
            {/*               Table                  */}
            {/* ==================================== */}

            <div className='PreQualifications-Table'>

              {/*  Header  */}
              <div className='PreQualifications-Table-Title items-center pb-2'>

                <h5 className='pl-2 m-0'>{formData?.project?.projectname}</h5>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                
                  <select 
                    style={{width: '200px'}}
                    className='Input-Field-Select'
                    onChange={(e) => {

                      setFormData({'filterby': e.target.value});
                      filterBy(e.target.value);
                    
                    }}
                    value={formData.location}
                  >
                    <option value='Filter By' hidden>Filter By</option>
                    <option value=''>All</option>
                    <option value='review'>In Review</option>
                    <option value='moreinfo'>Requires More Info</option>
                    <option value='pending'>Pending Approval</option>
                    <option value='approved'>Approved</option>
                    <option value='rejected'>Rejected</option>
                    <option value='expired'>Expired</option>
                  </select>

                  <input
                    style={{marginLeft: '10px', width: '300px', height: '42px', padding: '10px 15px'}}
                    className='Input-Field-Text'
                    type='text'
                    placeholder='Search by name'
                    autoComplete='off'
                    onChange={(e) => {
                      
                      setFormData({'search': e.target.value});
                      searchByName(e.target.value);
                    
                    }}
                    value={formData.search}
                  ></input>

                  {/* ------------------------------------------------------ */}
                  {/*  Download Prequalifications Button                     */}
                  {/* ------------------------------------------------------ */}

                  <ExportPrequalifications
                    formData={formData}
                    setFormData={setFormData}
                  ></ExportPrequalifications>

                </div>

              </div>

              {/* Table */}
              {
                formData.filtered.length > 0 ?
                <table className='Table-Container shadow-sm'>
                  <colgroup>
                    <col span='1' style={{width: '5%'}}></col>
                    <col span='1' style={{width: '15%'}}></col>
                    <col span='1' style={{width: '15%'}}></col>
                    <col span='1' style={{width: '15%'}}></col>
                    <col span='1' style={{width: '15%'}}></col>
                    <col span='1' style={{width: '15%'}}></col>
                    <col span='1' style={{width: '15%'}}></col>
                  </colgroup>
                  <thead>
                    <tr className='text-[14px] tracking-wider bg-slate-200 uppercase'>
                      <th>ID</th>
                      <th>User</th>
                      <th>Status</th>
                      <th>Submitted On</th>
                      <th>Actioned By</th>
                      <th>Expires On</th>
                      <th>Reference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formData.filtered?.map((request, index) =>(

                        <tr key={index} style={{height: '100px', cursor: 'default'}}>

                          {/* ID */}
                          <td> 
                            {index}
                          </td>

                          {/* User */}
                          <td onClick={() => navigate(`/prequalifications/worker?project=${projectid}&emailaddress=${request.workeremail}&view=profile`)}> 
                            <label className='PreQualifications-Label'>
                              {request.givenname} {request.surname}
                            </label>
                            <div>
                              {request.organisationname}
                            </div>
                          </td>       

                          {/* Status */}
                          <td> 
                            <StatusLabel prequalification={request}></StatusLabel>
                          </td>  

                          {/* Submitted On */}
                          <td> 
                            {
                              ConvertDate(
                                request?.audithistory.at(-1)?.actiondate?.toDate()
                              )
                            } 
                          </td> 

                          {/* Actioned By */}
                          <td className='break-all' title={request?.audithistory.at(-1)?.message} style={{cursor: 'help'}}> 
                            {
                              request?.audithistory.at(-1)?.actionedby
                            } 
                          </td> 

                          {/* Expires On */}
                          <td title={`Ref: ${request?.referenceid}`}> 
                            {

                              request?.expirydate.length === 0 ? (

                                <>
                                  N/A
                                </>

                              ): (

                                request?.expirydate

                              )

                            }
                          </td> 

                          {/* Reference */}
                          <td> 
                            {
                              request?.referenceid
                            } 
                          </td> 

                        </tr>
                      
                      ))
                    }
                  </tbody>
                </table>
                :
                <div className='bg-white p-4'>
                  No prequalifications found.
                </div>
              }


            </div>

          </div>
        }
      ></PageComponent>
    )
}
