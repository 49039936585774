//================================================================
//  Component: Organisations Table Row
//================================================================

//  Purpose: Allows global administrators to invite other global administrators

//  Properties:
//    - org = {object, contains all org data}
//    - pageStatus = {useState, used to store the status of the page}
//    - setPageStatus = {useState, used to set the status of the page}
//    - setErrorMessage = {useReducer, used to set the page error message}

//  Example:
//    <OrganisationTableRow
//      org={org}
//      pageStatus={pageStatus}
//      setPageStatus={setPageStatus}
//      setErrorMessage={setFormData}
//    ></OrganisationTableRow>    

//================================================================


//Libraries
import React, { useState, useReducer, useEffect, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../../Library/FirebaseConfig';
import { doc, updateDoc, arrayUnion, arrayRemove, getFirestore } from 'firebase/firestore';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components
import RemoveOwnerModal from './RemoveOwnerModal';
import DeleteOrgModal from './DeleteOrgModal';

//Functions
import getDocument from '../../../../Library/GetDocument';
import writeDocument from '../../../../Library/WriteDocument';
import queryDocument from '../../../../Library/QueryDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Images
import IconOpenBlue from '../../../../Components/Images/Icon_Expand_Blue.svg';
import IconCloseBlue from '../../../../Components/Images/Icon_Collaps_Blue.svg';
import IconAddGrey from '../../../../Components/Images/Icon_Add_Grey.svg';
import IconAddTeal from '../../../../Components/Images/Icon_Add_Teal.svg';
import IconDeletTeal from '../../../../Components/Images/Icon_Delete_Red.svg';
import IconInfoRequired from '../../../../Components/Images/Icon_InfoRequired_Teal.svg';
import ButtonDeleteOrg from '../../../../Components/Images/Button_Delete_Org.svg';
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';

//CSS
import '../../AppSettings.css';


export default function OrganisationTableRow({
    org, 
    pageStatus, 
    setPageStatus,
    setErrorMessage
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);


  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Toggle the row open or closed
    const [open, setOpen] = useState(false);

    // Toggle open the Delete Worker Modal
    const [modalOwnerVisible, setOwnerModalVisible] = useState(false);

    // Toggle open the Delete Org Modal
    const [modalDeleteOrg, setModalDeleteOrg] = useState(false);

    // Show an error message next to the save button
    const [formError, setFormError] = useState('');


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'name': org.organisationname,
            'nameValid': true,
            'description': org.description,
            'descriptionValid': true,
            'abn': org.abn,
            'abnValid': true,

            // Org Owner States
            'newOwner': '',
            'newOwnerGivenname': '',
            'newOwnerSurname': '',
            'newOwnerStatus': 'onload', // 'onload', 'new-user'
            'newOwnerMessage': '',
            'owners': [],

            // Delete Owner modal states
            'deleteOwnerObject': undefined,
            'deleteOwnerIndex': undefined,

            // Projects
            'projects': [],
            'deletedProjectIds': [],
        }
    );


  //------------------------------------------------------
  //  Functions --> Owners
  //------------------------------------------------------

    function handleChangeOwner(value) {

        if (value.length === 0) {
            return setFormData({
              'newOwner': value,
              'newOwnerAllowAdd': false
            });
    
          }

        // Validate emailaddress
        if (/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)) {
            return setFormData({
                'newOwner': value,
                'newOwnerStatus': 'onload',
                'newOwnerMessage': '',
                'newOwnerAllowAdd': true,
              });

        } 

        // invalid emailaddress
        setFormData({
          'newOwner': value,
          'newOwnerStatus': 'onload',
          'newOwnerMessage': '',
          'newOwnerAllowAdd': false,
        });

    }

    // Adds a Org Owner to 'formData.owners' array
    function handleAddOwner() { 

        // Check for duplicates
        const owner = formData.owners.filter((object) => object.emailaddress === formData.newOwner);
        if (owner.length > 0) {

            if (owner[0].type !== 'deleted') {

                return setFormData({
                    'newOwnerMessage': `Email address already exists.`
                });

            };

        };

        //------------------------------------------------------
        //  Get the users document
        //  1. Check if they exist (Catch add's them as a new user)
        //  2. Check if they are part of another org
        //------------------------------------------------------

        getDocument('users', formData.newOwner).then((userDoc) => {

            return queryDocument('organisations', [
                ['organisationid', '==', userDoc.organisationid]
            ]).then((usersOrg) => {

                // Member of a different active org?
                if (usersOrg.length > 0 && userDoc.organisationid !== org.organisationid) {

                    if (usersOrg[0].status === 'active') {

                        return setFormData({
                            'newOwnerMessage': `User is already a part of organisation, '${usersOrg[0].organisationname}'`
                        });

                    }

                }

                // Sanity check if the user was added/removed from the list
                let foundUser = false;
                formData.owners.forEach((user, index) => {

                    if (user.emailaddress === formData.newOwner) {

                        formData.owners[index] = {
                            'emailaddress': formData.newOwner,
                            'type': 'existing'
                        };
                        foundUser = true;

                    }

                });

                // User wasn't added/removed from the list, append the 'owners' array
                if (foundUser === false) {

                    formData.owners.push({
                        'emailaddress': formData.newOwner,
                        'type': 'existing'
                    });

                };

                formData.newOwner = '';
                formData.newOwnerMessage = '';
                formData.newOwnerAllowAdd = false;
                formData.newOwnerStatus = 'onload';
                setFormData(formData);

            }).catch((error) => {

                // Fatal Error and shouldn't be possible if the Firestore Rules are correct
                console.log('Fatal Error with security rules!', error );

            });

        }).catch(() => {

            formData.newOwnerStatus = 'new-user';
            formData.newOwnerMessage = '';
            formData.newOwnerAllowAdd = false;
            setFormData(formData);

        });

    };

    // Deletes a Org Owner from 'formData.owners' array
    function handleDeleteOwner(object, index) { 

        // Skip new users
        if (object.type === 'new') {
            formData.owners.splice(index, 1)
            return setFormData(formData);
        
        }

        // We need to check if the user already has the role!
        getDocument('users', object.emailaddress).then((document) => {

            // Has the role --> Add to the deleted list
            if (document.orgAdmin.includes(org.organisationid)) {
                formData.owners[index] = {
                    'emailaddress': object.emailaddress,
                    'type': 'deleted',
                    'globalAdmin': document.roles?.globalAdmin,
                    'projectAdmin': document.projectAdmin
                };

            // Doesn't have the role --> Ignore
            } else {
                formData.owners.splice(index, 1);

            }

            setFormData(formData);
            setOwnerModalVisible(false);

        })
        .catch(() => {

            setFormData({'owners': formData.owners.splice(index, 1)});

        });

    };

  //------------------------------------------------------
  //  Submit Handler --> Delete Org
  //------------------------------------------------------

    // onSubmit delete projects
    function deleteOrganisation(){

        const document = {
            'status': 'decommissioned',
            'decomissioneddate': new Date(),
        };
        writeDocument('organisations', org.organisationid, document, true).then(() => {

            // Auditing
            const message = `${getUser.emailaddress} -- Deleted organisation ${org.organisationname}.`;
            const types = [
                'app settings'
            ];
            const references = [
                org.organisationname,
                getUser.emailaddress,
                'organisations',
            ];
            return writeAuditLog(getUser.emailaddress, message, types, references);

        }).catch((error) => {

            setFormError('Unable to delete org, refresh and try again.');
    
        });

    };


  //------------------------------------------------------
  //  Submit Handler --> Org Form
  //------------------------------------------------------

    // org onSubmit handler
    function onSubmitUpdate(e){

        e.preventDefault();

        // OnSubmit Validation of all required fields
        let preventSubmit = false;

        if (formData.name.length === 0) {
            preventSubmit = true;
            formData.nameValid = false;
        
        } else {
            formData.nameValid = true;

        };

        if (formData.description.length === 0) {
            preventSubmit = true;
            formData.descriptionValid = false;
        
        } else {
            formData.descriptionValid = true;

        };

        if (formData.abn.length === 0) {
            preventSubmit = true;
            formData.abnValid = false;
        
        } else {
            formData.abnValid = true;

        };

        setFormData(formData);

        // Check if ALL validations have passed
        if (preventSubmit === true) {
            return setFormError('Complete the form before proceeding');

        }

        setPageStatus('pending');
        setFormError('');

        // ================================================================
        // Passed all validation checks --> write project & user documents
        //  - Create an new project document 
        //  - Create new user documents
        //  - Update 'projectAdmin' array (Ref to arrayUnion's in Firebase documentation)
        // ================================================================

        // ----------------------------------------------------------
        // 1. Define Variables
        // ----------------------------------------------------------

        // Firestore Client ~ Allows the update of elements in an array
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const newUsers = [];
        const existingOwners = [];
        const deletedUsers = [];
        const orgUpdatePromises = [];
        const newUserPromises = [];
        const existingUserPromises = [];
        const deletedUserPromises = [];
        const auditingPromises = [];

        // ----------------------------------------------------------
        // 2. Extract data from 'owners' array & write audit logs
        // ----------------------------------------------------------

        formData.owners.forEach((object) => {

            if (object.type === 'new') {

                // Added a check to ensure Lendlease users do NOT lose their LLUser role
                let lendleaseUser = false;
                if (object.emailaddress.endsWith('@lendlease.com')) {
                    lendleaseUser = true;

                }

                // Define user document
                newUsers.push({
                    'emailaddress': object.emailaddress.toLowerCase(),
                    'givenname': object.givenname,
                    'surname': object.surname,
                    'created': new Date(),
                    'projectAdmin': [],
                    'organisationid': org.organisationid,
                    'organisationname': org.organisationname,
                    'orgAdmin': [
                        org.organisationid
                    ],
                    'projects': [],
                    'roles': {
                        'globalAdmin': false,
                        'lendleaseUser': lendleaseUser,
                    },
                    'status': 'active'
                });

                // Auditing
                const message = `${getUser.emailaddress} -- Created org admin ${object.emailaddress.toLowerCase()} for organisation ${org.organisationname}.`;
                const types = [
                    'app settings'
                ];
                const references = [
                    org.organisationname,
                    getUser.emailaddress,
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            }

            if (object.type === 'existing') {

                existingOwners.push(object.emailaddress.toLowerCase());

                // Auditing
                const message = `${getUser.emailaddress} -- Added org admin ${object.emailaddress.toLowerCase()} for organisation ${org.organisationname}.`;
                const types = [
                    'app settings'
                ];
                const references = [
                    org.organisationname,
                    getUser.emailaddress,
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            };

            if (object.type === 'deleted') {

                deletedUsers.push(object);

                // Auditing
                const message = `${getUser.emailaddress} -- Removed org admin ${object.emailaddress.toLowerCase()} for organisation ${org.organisationname}.`;
                const types = [
                    'app settings'
                ];
                const references = [
                    org.organisationname,
                    getUser.emailaddress,
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            };
        
        });

        // ----------------------------------------------------------
        // 3. Update the organisation document workers array
        // ----------------------------------------------------------

        const orgRef = doc(db, 'organisations', org.organisationid);

        formData.owners.forEach((object) => {

            if (object.type === 'new') {
                
                // Update org doc workers array
                orgUpdatePromises.push(
                    updateDoc(orgRef, {
                        workers: arrayUnion(object.emailaddress.toLowerCase())
                    })
                );
                
                // Auditing
                const message = `${getUser.emailaddress} -- Added admin ${object.emailaddress.toLowerCase()} to organisation ${org.organisationname}.`;
                const types = [
                    'app settings'
                ];
                const references = [
                    object.emailaddress.toLowerCase(),
                    org.organisationname,
                    getUser.emailaddress,
                    'organisations',
                ];
                auditingPromises.push(
                    writeAuditLog(getUser.emailaddress, message, types, references)
                );

            }

            if (object.type === 'existing') {

                if (!org.workers.includes(object.emailaddress.toLowerCase())) {

                    // Update org doc workers array
                    orgUpdatePromises.push(
                        updateDoc(orgRef, {
                            workers: arrayUnion(object.emailaddress.toLowerCase())
                        })
                    );
                    
                    // Auditing
                    const message = `${getUser.emailaddress} -- Added admin ${object.emailaddress.toLowerCase()} to organisation ${org.organisationname}.`;
                    const types = [
                        'app settings'
                    ];
                    const references = [
                        object.emailaddress.toLowerCase(),
                        org.organisationname,
                        getUser.emailaddress,
                        'organisations',
                    ];
                    auditingPromises.push(
                        writeAuditLog(getUser.emailaddress, message, types, references)
                    );

                }

            }

            if (object.type === 'deleted') {

                orgUpdatePromises.push(
                    updateDoc(orgRef, {
                      workers: arrayRemove(object.emailaddress.toLowerCase())
                    })
                );

            }

        })

        // ----------------------------------------------------------
        // 4. Write 'new users' to 'users' collection
        // ----------------------------------------------------------

        newUsers.forEach((document) => {

            newUserPromises.push(
                writeDocument('users', document.emailaddress, document, false)
            )

        });

        // ----------------------------------------------------------
        // 5. Merge 'existing user' roles in their 'users' collection
        // ----------------------------------------------------------

        existingOwners.forEach((emailaddress) => {

            // https://cloud.google.com/firestore/docs/manage-data/add-data#update_elements_in_an_array
            const docRef = doc(db, 'users', emailaddress);

            existingUserPromises.push(
                updateDoc(docRef, {
                    orgAdmin: arrayUnion(org.organisationid)
                })
            );

            const newdoc = {
                'organisationid': org.organisationid, 
                'organisationname': org.organisationname
            }

            existingUserPromises.push(
                writeDocument('users', emailaddress, newdoc, true)
            )

        });

        // ----------------------------------------------------------
        // 6. Delete 'existing user' in the 'users' collection
        // ----------------------------------------------------------

        deletedUsers.forEach((user) => {

            // Do NOT delete user doc if they are a global admin or project admin
            if (user?.globalAdmin === true || user.projectAdmin?.length > 0) {

                const updatedDoc = {
                    'organisationid': '',
                    'organisationname': '',
                    'orgAdmin': []
                }
            
                deletedUserPromises.push(
                    writeDocument('users', user.emailaddress, updatedDoc, true)
                )
        
            } else {
                // We ALWAYS delete org admins so PII data is removed
                const document = {
                    'status': 'inactive'
                };

                deletedUserPromises.push(
                    writeDocument('users', user.emailaddress, document, true)
                );
                
            }

        });

        // ---------------------------------------------------------
        // 7. Settle promises & handle results
        // ---------------------------------------------------------

        Promise.all([...orgUpdatePromises, ...newUserPromises, ...existingUserPromises, ...deletedUserPromises, ...auditingPromises])
        .then(() => {

            return queryDocument('users', [
                ['orgAdmin', 'array-contains', org.organisationid],
                ['status', '==', 'active'],
            ])
            .then(() => {

                // Reload the page
                window.location.reload();

            });

        })
        .catch((error) => {
            console.log(error)
            setFormError('Unable to save changes, please try again.');
    
        });

    };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onload
    //  1. Get all orgAdmins from the users collection
    //  2. Save to useReducer
    useEffect(() => {

        if (org === undefined) return;

        // 1. Get all project documents
        const projectPromises = [];
        org.projects.forEach(projectid => {
            
            projectPromises.push(
                getDocument('projects', projectid)
            );

        });
        
        // Settle Promises
        Promise.all(projectPromises).then((projects) =>{

            // Save projects to formData
            setFormData({ 'projects': projects });

        });

        // 2. Get all orgAdmins
        queryDocument('users', [
            ['orgAdmin', 'array-contains', org.organisationid],
            ['status', '==', 'active']
        ])
        .then((orgAdmins) => {
    
            // Extract orgAdmins
            const admins = [];
            orgAdmins.forEach((user) => {

                admins.push({
                    'emailaddress': user.emailaddress,
                    'type': 'existing',
                });

            });

            // Save admins to formData & reset form
            setFormData({ 
                'name': org.organisationname,
                'nameValid': true,
                'description': org.description,
                'descriptionValid': true,
                'abn': org.abn,
                'abnValid': true,
    
                // Org Owner States
                'newOwner': '',
                'newOwnerGivenname': '',
                'newOwnerSurname': '',
                'newOwnerStatus': 'onload', // 'onload', 'new-user'
                'newOwnerMessage': '',
                'owners': admins,
    
                // Delete Owner modal states
                'deleteOwnerObject': undefined,
                'deleteOwnerIndex': undefined,
    
                // Projects
                // 'projects': projects, --> DON'T update this value HERE!
                'deletedProjectIds': [],
         });

        })
        .catch((error) => {
            setPageStatus('error-fatal');
            setErrorMessage({ 'errorMessage': error })

        });

    // eslint-disable-next-line
    }, [org]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //====================================================
    //  Pending
    //====================================================

    if (pageStatus === 'pending') {

        return (
            <div className='ProjectSetting-Table-Open'>
                <form className='ProjectSetting-Form'>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img alt='loading-circle-icon' src={LoadingIcon}></img>
                    </div>

                    {/* ================================================ */}
                    {/*             Submit Buttons                       */}
                    {/* ================================================ */}

                    <div className='ProjectSetting-Form-Row' style={{borderTop: 'none'}}>
                        <button 
                            style={{marginTop: '30px'}} 
                            className='Primary-Button'
                            disabled={pageStatus === 'onload' ? (false):(true)} 
                            type='submit' 
                            value='submit'
                        >
                            Save
                        </button>
                        <button 
                            style={{marginLeft: '10px', marginTop: '30px'}} 
                            className='Secondary-Button'
                            onClick={() => window.location.reload()}
                        >
                            Discard
                        </button>
                    </div>
                    <div className='Input-Field-Text-Error-Message'>
                        {formError}
                    </div>

                </form>
            </div>

        )

    }

    //====================================================
    //  Onload
    //====================================================

    else if (pageStatus === 'onload') {
        return (
            <>
                {/* ----------------------------------------------- */}
                {/*  Organisation Header                            */}
                {/* ----------------------------------------------- */}
    
                <div className='AppSettings-Table-Closed'>
                    <h6 className='text-black m-0'>{org.organisationname}</h6>
    
                    {/* Show Expanded Icon if Open OR Collapsed Icon if Closed */}
                    {
                        open ?
                        <img style={{cursor: 'pointer'}} onClick={() => setOpen(false)} src={IconCloseBlue} alt='close-row'></img>
                        :
                        <img style={{cursor: 'pointer'}} onClick={() => setOpen(true)} src={IconOpenBlue} alt='open-row'></img>
                    }
                </div>
    
                {/* ----------------------------------------------- */}
                {/*  Edit Organisation Form                         */}
                {/* ----------------------------------------------- */}
    
                {
                    open && 
                    <div className='AppSettings-Table-Open shadow-inner'>
                        <form className='AppSettings-Form' onSubmit={(e) => onSubmitUpdate(e)}>
    
                            {/* ===================================== */}
                            {/*                 Name                  */}
                            {/* ===================================== */}
    
                            <div className='AppSettings-Form-Row mt-0 border-0'>
    
                                <label className='font-semibold text-[#5F5F5F] uppercase tracking-wider text-[15px] mt-0'>Name *</label>
                                <input
                                    className={formData.nameValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                                    type='text'
                                    placeholder='Enter the organisation name'
                                    onChange={(e) => setFormData({'name': e.target.value})}
                                    value={formData.name}
                                ></input>
    
                            </div>
    
                            {/* ===================================== */}
                            {/*            Description                */}
                            {/* ===================================== */}
    
                            <div className='AppSettings-Form-Row'>
    
                                <label className='font-semibold text-[#5F5F5F] uppercase tracking-wider text-[15px] mt-0'>Description *</label>
                                <textarea
                                    className={formData.descriptionValid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')} 
                                    placeholder='Enter a short description for the organisation'
                                    onChange={(e) => setFormData({'description': e.target.value})}
                                    value={formData.description}
                                ></textarea>
    
                            </div>
    
                            {/* ===================================== */}
                            {/*               ABN                     */}
                            {/* ===================================== */}
    
                            <div className='AppSettings-Form-Row'>
    
                                <label className='font-semibold text-[#5F5F5F] uppercase tracking-wider text-[15px] mt-0'>ABN *</label>
                                <input
                                    className={formData.abnValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                                    type='text'
                                    placeholder="Enter the organisation's ABN"
                                    onChange={(e) => setFormData({'abn': e.target.value})}
                                    value={formData.abn}
                                ></input>
    
                            </div>
    
                            {/* ================================================ */}
                            {/*         Organisation Owner(s)                    */}
                            {/*  1. Allows GA's to add existing firestore users  */}
                            {/*  2. Validates new users with a form              */}
                            {/*  3. Saves results to useReducer                  */}
                            {/* ================================================ */}
    
                            {
    
                            // ========================================================
                            // All 'newOwnerStatus' states ---> 'EXCLUDING new-users'!
                            // ========================================================
    
                                formData.newOwnerStatus !== 'new-user' ? 
                                (
                                    <div className='AppSettings-Form-Row'>
    
                                        <label className='font-semibold text-[#5F5F5F] uppercase tracking-wider text-[15px] mt-0'>Organisation Owner(s)</label>
    
                                            {/* ======================================= */}
                                            {/*    Email Address Input Field            */}
                                            {/* ======================================= */}
    
                                            <div className='flex flex-row gap-2 items-center mb-2'>
                                                <input
                                                    className={formData.newOwnerMessage.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    type='text'
                                                    placeholder='Email address'
                                                    onChange={(e) => handleChangeOwner(e.target.value)}
                                                    value={formData.newOwner}
                                                    autoComplete='off'
                                                ></input>
    
                                                {/* ======================================= */}
                                                {/*    Toggle Add Icon                      */}
                                                {/*  1. Enabled                             */}
                                                {/*  1. Disabled                            */}
                                                {/* ======================================= */}
    
                                                {
                                                    formData.newOwnerAllowAdd === true ? (
    
                                                    <img
                                                        className='Input-Icon'
                                                        src={IconAddTeal} 
                                                        alt='add-icon'
                                                        onClick={() => handleAddOwner()}
                                                    ></img>
    
                                                    )
                                                    : 
                                                    (
                                                        <img
                                                            className='Input-Icon'
                                                            disabled={true}
                                                            src={IconAddGrey} 
                                                            alt='add-icon-disabled'
                                                        ></img>
                                                    )
                                                }
    
                                            </div>
    
    
                                        {/* ======================================= */}
                                        {/*    1. Error messages                    */}
                                        {/*    2. List of all Owners                */}
                                        {/* ======================================= */}
    
                                        <div className='AppSettings-Form-Users'>
    
                                            <span className='Input-Field-Text-Error-Message'>{formData.newOwnerMessage}</span>
    
                                            {
    
                                                // Show the list of current owners
                                                formData?.owners?.map((object, index) => (
    
                                                    <div key={index} className='flex'>
    
                                                        {
                                                            object.type !== 'deleted' && (
    
                                                                <div className='AppSettings-Projects-Label'>
                                                                    <label className='Label-List-Item mb-0'>{object.emailaddress}</label>
                                                                    <img 
                                                                        className='Input-Icon' 
                                                                        src={IconDeletTeal} 
                                                                        alt='delete-icon' 
                                                                        onClick={() => {
                                                                        
                                                                            formData.deleteOwnerObject = object;
                                                                            formData.deleteOwnerIndex = index;
                                                                            setFormData(formData);
                                                                            setOwnerModalVisible(true);
    
                                                                        }}
                                                                    ></img>
                                                                </div>
    
                                                            )
                                                        }
    
                                                    </div>
                                                
                                                ))
    
                                            }
                                        </div>
    
                                    </div>
                                ) 
                                
                                :
                            
                            // ========================================================
                            //  New Users Form ---> formData.newOwnerStatus === 'new-users'
                            // ========================================================
    
                                (
                                    <div className='AppSettings-Form-Row'>
    
                                        <label className='font-semibold text-[#5F5F5F] uppercase tracking-wider text-[15px] mt-0'>Organisation Owner(s)</label>
    
                                        {/* New User Form */}
                                        <div className='New-User-Form'>
    
                                            {/* <-------------------HEADER--------------------> */}
                                            <div className='New-User-Header'>
                                                <img src={IconInfoRequired} alt='Info-Required-Icon' width='25px'></img>
                                                MORE INFORMATION REQUIRED
                                            </div>
    
                                            {/* <-----------------EMAIL ADDRESS---------------> */}
                                            <div className='New-User-Email'>
                                                <p> Email Address </p>
                                                <input
                                                    className='Input-Field-Text'
                                                    type='text'
                                                    value={formData.newOwner}
                                                    disabled
                                                    autoComplete='off'
                                                ></input>
                                            </div>
    
                                            {/* <-----------------USER NAME------------------> */}
                                            <div className='New-User-Name'>
    
                                                {/* Given Name */}
                                                <div>
                                                    <p> Given Name </p>
                                                    <input
                                                        className='Input-Field-Text'
                                                        type='text'
                                                        placeholder='Sally'
                                                        value={formData.newOwnerGivenname}
                                                        onChange={(e) => setFormData({'newOwnerGivenname': e.target.value})}
                                                    ></input>
                                                </div>
    
                                                {/* Surname */}
                                                <div>
                                                    <p> Surname </p>
                                                    <input
                                                        className='Input-Field-Text'
                                                        type='text'
                                                        placeholder='Smith'
                                                        value={formData.newOwnerSurname}
                                                        onChange={(e) => setFormData({'newOwnerSurname': e.target.value})}
                                                    ></input>
                                                </div>
    
                                            </div>
    
                                            {/* <-----------------ADD BUTTON---------------> */}
                                            <div className='New-User-Buttons'>
    
                                                {/* Disable '+ Add User' button if fields are empty */}
                                                <button 
                                                    className='Primary-Button'
                                                    style={{fontSize: '13px'}} 
                                                    disabled = {
                                                        formData.newOwnerGivenname.length > 0 &&
                                                        formData.newOwnerSurname.length > 0 ? false : true
                                                    }
                                                    onClick={() => {
                                                        formData.owners.push({
                                                            'emailaddress': formData.newOwner,
                                                            'type': 'new',
                                                            'givenname': formData.newOwnerGivenname,
                                                            'surname': formData.newOwnerSurname,
                                                        });
                                                        formData.newOwnerStatus = 'onload';
                                                        formData.newOwner = '';
                                                        formData.newOwnerAllowAdd = false;
                                                        formData.newOwnerGivenname = '';
                                                        formData.newOwnerSurname = '';
                                                        setFormData(formData);
                                                    }}
                                                    >   
                                                        + Add User
                                                    </button>
    
                                                    {/* Cancel Button */}
                                                    <button 
                                                        className='Secondary-Button'
                                                        style={{fontSize: '13px'}} 
                                                        onClick={() => {
                                                            formData.newOwnerStatus = 'onload';
                                                            formData.newOwner = '';
                                                            formData.newOwnerGivenname = '';
                                                            formData.newOwnerSurname = '';
                                                            setFormData(formData);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
    
                            {/* ================================================ */}
                            {/*             Remove Owner Modal                  */}
                            {/* ================================================ */}
    
                            {
                                modalOwnerVisible && 
                                (
                                    <RemoveOwnerModal
                                        deleteOwnerObject={formData.deleteOwnerObject}
                                        deleteOwnerIndex={formData.deleteOwnerIndex}
                                        handleDeleteOwner={handleDeleteOwner}
                                        setModalVisible={setOwnerModalVisible}
                                    ></RemoveOwnerModal>
                                )
                            }
    
                                {/* ================================================ */}
                                {/*                   Projects                       */}
                                {/* ================================================ */}
    
                                <div className='AppSettings-Form-Row'>
                                
                                    <label className='font-semibold text-[#5F5F5F] uppercase tracking-wider text-[15px] mt-0'>Projects</label>
                                    <div className='flex flex-col'>
                                    {
                                        formData.projects.length === 0 ? (
                                            <div>
                                                No projects found.
                                            </div>
                                        )
                                        : 
                                        (   
                                            formData.projects.map((project, index) => (
                                                <div key={index} className='AppSettings-Projects-Label'>
                                                    <label className='Label-List-Item'>
                                                        {project.projectname}
                                                    </label>
                                                    <img 
                                                        className='Input-Icon' 
                                                        src={IconDeletTeal} 
                                                        alt='Delete-Icon'
                                                        onClick={() => {
                                                            formData.projects.splice(index, 1);
                                                            formData.deletedProjectIds.push(project.projectid);
                                                            setFormData(formData);
                                                        }}
                                                    ></img>
    
                                                </div>
                                            ))
                                                
                                        )
                                    }
                                    </div>
                                </div>
    
    
                            {/* ================================================================ */}
                            {/*                     Submit Buttons                               */}
                            {/* ================================================================ */}
    
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
    
                                <div>
    
                                    {/* Save Button */}
                                    <button 
                                        style={{marginTop: '30px'}} 
                                        className='Primary-Button'
                                        disabled={pageStatus === 'onload' ? (false):(true)} 
                                        type='submit' 
                                        value='submit'
                                    >
                                        Save
                                    </button>
    
                                    {/* Discard Button */}
                                    <button 
                                        style={{marginLeft: '10px', marginTop: '30px'}} 
                                        className='Secondary-Button'
                                        onClick={() => window.location.reload()}
                                    >
                                        Discard
                                    </button>
    
                                    {/* Errors */}
                                    <div className='Input-Field-Text-Error-Message'>
                                        {formError}
                                    </div>
                                
                                </div>
    
                                {/* Delete Organisation & Modal*/}
                                <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
                                    <img 
                                        className='AppSettings-DeleteOrg-Button' 
                                        src={ButtonDeleteOrg} 
                                        alt='Delete-Org'
                                        onClick={() => setModalDeleteOrg(true)}
                                    ></img>
                                </div>
    
                                {
                                    modalDeleteOrg && (
    
                                        <DeleteOrgModal
                                            orgDoc={org}
                                            handleDeleteOrg={deleteOrganisation}
                                            setModalVisible={setModalDeleteOrg}
                                        ></DeleteOrgModal>
    
                                    )
                                }
    
    
                            </div>
    
                        </form>
    
                    </div>
                }
                
            </>
        )
    }

}
