//================================================================
//  Component: Prequalification Form
//================================================================

//  Purpose: This is the prequalification form that a user fills in to apply for a DCAC pass for their defence project

//  Properties:
//    - currentUser = {useState, current users document}
//    - prequalification = {useState, current prequalification request for a project}
//    - setPageStatus = {useState, setPageStatus}
//    - projectDoc = {document, stores the current defence project document}
//    - signedUrls = {array, contains all the signed urls for all files on the form}

//  Example:
//    <PrequalificationForm
//      currentUser={currentUser}
//      prequalification={prequalification}
//      setPageStatus={setPageStatus}
//      projectDoc={projectDoc}
//      signedUrls={signedUrls}
//    ></PrequalificationForm>    

//================================================================


//Libraries
import React, { useReducer } from 'react';

//Contexts

//Components
import FileUploadDCACForm from '../Components/FileUploadDCACForm';
import CommentsThread from '../Components/CommentsThread/CommentsThread';

//Functions

//Images
import ExpandIcon from '../../../Components/Images/Expand_Icon.svg';
import CollapseIcon from '../../../Components/Images/Collapse_Icon.svg';
import Step1Icon from '../../../Components/Images/Step_1_Icon.svg';
import Step1CompleteIcon from '../../../Components/Images/Step_1_Complete_Icon.svg';
import Step2Icon from '../../../Components/Images/Step_2_Icon.svg';
import Step2CompleteIcon from '../../../Components/Images/Step_2_Complete_Icon.svg';
import Step3Icon from '../../../Components/Images/Step_3_Icon.svg';
import Step3CompleteIcon from '../../../Components/Images/Step_3_Complete_Icon.svg';
import TickedIcon from '../../../Components/Images/Ticked_Icon.svg';
import SendMail from '../../../Components/Images/Icon_Mail_White.svg';
import AttachFile from '../../../Components/Images/Icon_AttachFile_Teal.svg';
import ExistingDCAC from '../.././../Components/Images/Icon_ExistingDCAC_Teal.svg';
import AttachFileDisabled from '../../../Components/Images/Icon_AttachFile_Grey.svg';
import ViewFileIcon from '../../../Components/Images/View_File_Icon.svg';

//CSS
import '../PreQualifications.css';


export default function PrequalificationForm({
  currentUser,
  prequalification,
  signedUrls
}) {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store all the form input and their states
    const [formData, setFormData] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {

        // --- Existing DCAC Form Fields --- //
        'existingDCAC': prequalification?.existingdcac,                                            // Does the user have an existing DCAC? - ('yes'/'no')
        'existingDCACFiles': signedUrls?.existingDCAC,
        'existingDCACValid': true,
        'existingDCACErrorMessage': '',

        // --- No DCAC Form Fields --- //
        // Section 1 | Privacy Policy
        'privacyPolicyChecked': prequalification?.privacystatementaccepted,                        // Determines whether privacy policy box is checked (true/false)
        'privacyOpen': false,                                                                      // Privacy policy pane expanded/collapsed (true/false)
        'privacyPolicyValid': true,

        // Section 2 | Application
        'applicationFormComplete': prequalification?.status === 'not-submitted' ? false : true,     // Determines whether application form is complete (true/false)
        'applicationOpen': false,                                                                  // Application pane expanded/collapsed (true/false)

        // Application Form Fields
        'givenName': prequalification?.givenname,
        'givenNameValid': true,
        'lastName': prequalification?.surname,
        'lastNameValid': true,
        'notificationEmail': prequalification?.notificationemail,
        'notificationEmailValid': true,
        'dateOfBirth': prequalification?.dateofbirth,
        'dateOfBirthValid': true,
        'companyName': prequalification?.companyname,
        'companyNameValid': true,
        'companyPosition': prequalification?.companyposition,
        'companyPositionValid': true,
        'reasonForAccess': prequalification?.reasonforaccess,
        'reasonForAccessValid': true,
        'AGSVA': prequalification?.agsva,
        'AGSVAFiles': signedUrls?.agsva,
        'AGSVAValid': true,
        'AGSVAErrorMessage': '',
        'AusCit': prequalification?.auscit,
        'AusCitFiles': signedUrls?.auscit,
        'AusCitValid': true,
        'AusCitErrorMessage': '',
        'NPC': prequalification?.npc,
        'NPCFiles': signedUrls?.npc,
        'NPCValid': true,
        'NPCErrorMessage': '',
        'DecRightToWork': prequalification?.decrighttowork,                                        // Checkbox 1 | Right to work - (true/false)
        'DecRightToWorkValid': true,
        'DecConditionsOfUse': prequalification?.decconditionsofuse,                                // Checkbox 2 | Conditions of use - (true/false)
        'DecConditionsOfUseValid': true,
        'DecNDA': prequalification?.decnda,                                                        // Checkbox 3 | NDA - (true/false)
        'DecNDAValid': true,

        'passOfficeState': prequalification?.nominatedpassstate,
        'passOfficeStateValid': true,
        'passOfficeNameFilter': [
          prequalification?.nominatedpassoffice
        ],
        'passOfficeName': prequalification?.nominatedpassoffice,
        'passOfficeNameValid': true,
        'passOfficeNew': prequalification?.nominatedpassofficenew,
        'passOfficeNewValid': true,

        // Section 3 | Additional Information 
        'addInfoComplete': prequalification?.status === 'not-submitted' ? false : true,             // Determines whether additional information pane has been opened (true/false)
        'addInfoOpen': false,                                                                      // Additional Information pane expanded/collapsed (true/false)
        
        'addInfoUploadFile': false,                                                                // Changes to 'true' when 'Attach File' button clicked on Additional Information pane
        'addInfoFile': [],                                                                         // Array of files uploaded with a comment in Additional Information pane
        'addInfoValid': true,
        'addInfoErrorMessage': '',
        'addInfoSendValid': false,
        'addInfoCommentTextArea': '',                                                              // Stores the current text in the comments text area
        'addInfoComments': prequalification?.comments,                                             // Stores the array of comments posted on the comments thread

        // Prequalification History Fields
        'prequalHistoryOpen': false                                                                // Determines whether the prequalification history pane is open
      }
    );
    
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Function to handle changes to input fields
    function handleInputChange(evt){
        
      const name = evt.target.name;
      const newValue = evt.target.value;

      //Validate field - 'givenName'
      if(name === 'givenName') {
        if(newValue.length <= 0) {
          setFormData({'givenNameValid': false})
        } else {
          setFormData({'givenNameValid': true})
        }
      }

      //Validate field - 'lastName'
      if(name === 'lastName') {
        if(newValue.length <= 0) {
          setFormData({'lastNameValid': false})
        } else {
          setFormData({'lastNameValid': true})
        }
      }

      //Validate field - 'notificationEmail'
      if(name === 'notificationEmail') {
        if(newValue.length <= 0) {
          setFormData({'notificationEmailValid': false})
        } else {
          setFormData({'notificationEmailValid': true})
        }
      }

      //Validate field - 'dateOfBirth'
      if(name === 'dateOfBirth') {
        if(newValue.length <= 0) {
          setFormData({'dateOfBirthValid': false})
        } else {
          setFormData({'dateOfBirthValid': true})
        }
      }

      //Validate field - 'companyName'
      if(name === 'companyName') {
        if(newValue.length <= 0) {
          setFormData({'companyNameValid': false})
        } else {
          setFormData({'companyNameValid': true})
        }
      }

      //Validate field - 'companyPosition'
      if(name === 'companyPosition') {
        if(newValue.length <= 0) {
          setFormData({'companyPositionValid': false})
        } else {
          setFormData({'companyPositionValid': true})
        }
      }

      //Validate field - 'reasonForAccess'
      if(name === 'reasonForAccess') {
        if(newValue.length <= 0) {
          setFormData({'reasonForAccessValid': false})
        } else {
          setFormData({'reasonForAccessValid': true})
        }
      }

      //Validate field - 'AGSVA'
      if(name === 'AGSVA') {
        if(newValue.length <= 0) {
          setFormData({'AGSVAValid': false})
        } else {
          setFormData({'AGSVAValid': true})
        }
      }

      //Validate field - 'AusCit'
      if(name === 'AusCit') {
        if(newValue.length <= 0) {
          setFormData({'AusCitValid': false})
        } else {
          setFormData({'AusCitValid': true})
        }
      }

      //Validate field - 'NPC'
      if(name === 'NPC') {
        if(newValue.length <= 0) {
          setFormData({'NPCValid': false})
        } else {
          setFormData({'NPCValid': true})
        }
      }

      setFormData({ [name]: newValue });

    };


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='PreQ-Form-Container'>

        {/* -------------------------------------------- */}
        {/*  Prequalification Form                       */}
        {/* -------------------------------------------- */}

            {/* ------------------------------------------ */}
            {/*  Do you have an existing DCAC?             */}
            {/* ------------------------------------------ */}

            <div className='PreQ-Form-Section-Container' style={{fontWeight: '500'}}>

              <div className='PreQ-Form-ExistingDCAC'>
                <label> Do you have an existing DCAC? </label>
                
                <div>
                  <button className={formData.existingDCAC === 'yes' ? 'Secondary-Button-Active' : 'Secondary-Button'} disabled>
                    Yes
                  </button>

                  <button className={formData.existingDCAC === 'no' ? 'Secondary-Button-Active' : 'Secondary-Button'} disabled>
                    No
                  </button>
                </div>

              </div>

            </div>
          
            {/* ------------------------------------------ */}
            {/*  DCAC Form                                 */}
            {/* ------------------------------------------ */}

            <div style={{marginBottom: '20px'}}>
              {
                // ------------------------------------------ //
                //   Existing DCAC                            //
                // ------------------------------------------ //

                formData.existingDCAC === 'yes' ? 
                (
                  <div style={{marginBottom: '20px'}}>

                    {/* Upload DCAC */}
                    <div className='PreQ-Form-Section-Container' style={{padding: '40px'}}>
                      
                      <div className='PreQ-Form-ExistingDCAC-Header'>
                        <img src={ExistingDCAC} alt='Existing DCAC'></img>
                        <h6>DEFENCE COMMON ACCESS CARD</h6>
                      </div>

                      <p style={{padding: '5px 0px'}}>Below is a copy of this worker's existing DCAC.</p>
                    
                      {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                      {
                        prequalification?.status === 'not-submitted' ? 
                        (
                          <FileUploadDCACForm
                            formData={formData}
                            setFormData={setFormData}
                            dataField={'existingDCACFiles'}
                            errorMessageField={'existingDCACErrorMessage'}
                            validField={'existingDCACValid'}
                          ></FileUploadDCACForm>
                        )
                        :
                        (
                          <div className='PreQ-Uploads-List-Container'>
                            <b>FILE NAME</b>                                                                                                
        
                            {/* File Link */}
                            {
                              signedUrls?.existingDCAC.map((object, index) => (
                                <div className='PreQ-Uploads-List-Row' key={index}>

                                  {/* File Name */}
                                  <a 
                                    href={object.signedurl} 
                                    alt={object.filename} 
                                    target='_blank' 
                                    rel='noreferrer'>
                                      {object.filename}
                                  </a>
  
                                  {/* View File */}
                                  <div className='PreQ-Uploads-List-ViewFile'>

                                    <a 
                                      href={object.signedurl} 
                                      alt={object.filename} 
                                      target='_blank' 
                                      rel='noreferrer'>
                                      <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                    </a>

                                  </div>
                                      
                                </div>
                              ))
                            }
                          </div>
                        )
                      }

                    </div>

                  </div>
                ) 
                
                // ------------------------------------------ //
                //   New DCAC                                 //
                // ------------------------------------------ //
                
                : formData.existingDCAC === 'no' ? 
                (
                  <div>

                    <div className='PreQ-Form-Section-Container' style={{padding: '0px'}}>

                    {/* ------------------------------------ */}
                    {/*   PRIVACY STATEMENT                  */}
                    {/* ------------------------------------ */}
                      
                      {/* -------------- EXPANDABLE PANE -------------*/}

                      <div className='PreQ-Form-Expandable-Pane' onClick={() => setFormData({ 'privacyOpen': !formData.privacyOpen })}>

                        {/* Step 1 */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-Step' 
                          src={formData.privacyPolicyChecked ? TickedIcon : formData.privacyOpen ? Step1CompleteIcon : Step1Icon} 
                          alt='Privacy Statement'
                        ></img>

                        {/* PRIVACY STATEMENT */}
                        <label className='PreQ-Form-Expandable-Pane-Heading'>PRIVACY STATEMENT</label>

                        {/* V */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-ExpandCollapseIcon' 
                          src={formData.privacyOpen ? CollapseIcon : ExpandIcon} 
                          alt='Expand' 
                          onClick={() => setFormData({ 'privacyOpen': !formData.privacyOpen })}
                        ></img>

                      </div>

                      {/* ---------- PRIVACY STATEMENT CHECKBOXES ---------- */}
                      {
                        formData.privacyOpen && 
                        (
                          <div className='PreQ-Form-Expandable-Pane-Content'>

                            <p style={{fontWeight: '500'}}>OUR PRIVACY POLICY</p>
                            <p>The information collected will only be used for the purpose of obtaining a DCAC and/or as part of security onboarding. The information will be retained on this platform until the project or DCAC expiry (whichever occurs first).</p>
                            
                            {/* Checkbox */}
                            <div className='PreQ-Form-PrivacyPolicy-Container'>
                              <label className='PreQ-Form-Checkbox-Container'>
                                <input
                                  required
                                  type='checkbox'
                                  name='privacystatement'
                                  checked={formData.privacyPolicyChecked}
                                  onChange={() => setFormData({ 
                                    'privacyPolicyChecked': !formData.privacyPolicyChecked,
                                    'privacyPolicyValid': true
                                  })}
                                  disabled
                                ></input>
                                <span className='PreQ-Form-Checkbox'></span>
                              </label>
                              <label>Yes, I have read and understood the privacy policy and consent to my information being used as detailed above.</label>
                            </div>
               
                          {/* Error Message */}
                          {!formData.privacyPolicyValid && <label className='PreQ-Uploads-Error-Message' style={{paddingBottom: '0px'}}> Please acknowledge you have read the privacy statement by checking the box above.</label>}
   
                          </div>

                        ) 
                      }

                    {/* ------------------------------------ */}
                    {/*   APPLICATION                        */}
                    {/* ------------------------------------ */}

                      {/* -------------- EXPANDABLE PANE -------------*/}

                      <div className='PreQ-Form-Expandable-Pane' onClick={() => setFormData({ 'applicationOpen': !formData.applicationOpen })}>

                        {/* Step 2 */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-Step' 
                          src={
                            prequalification?.status === 'review' ||
                            prequalification?.status === 'pending' ||
                            prequalification.status === 'pendingapproval' ||
                            prequalification?.status === 'approved' ||
                            prequalification?.status === 'rejected' ||
                            prequalification?.status === 'expired' ||
                            (
                              formData.givenName?.length > 0 &&
                              formData.lastName?.length > 0 &&
                              formData.notificationEmail?.length > 0 &&
                              formData.dateOfBirth?.length > 0 &&
                              formData.companyName?.length > 0 &&
                              formData.companyPosition?.length > 0 &&
                              formData.reasonForAccess?.length> 0 &&
                              (
                                (formData.AGSVA === 'yes' && formData.AGSVAFiles.length > 0) 
                                ||
                                (
                                  formData.AGSVA === 'no' && 
                                  (
                                    ((formData.AusCit === 'yes' && formData.AusCitFiles?.length > 0) || formData.AusCit === 'no') && 
                                    ((formData.NPC === 'yes' && formData.NPCFiles?.length > 0) || formData.NPC === 'no')
                                  )
                                )
                              ) && 
                              formData.DecRightToWork &&
                              formData.DecConditionsOfUse &&
                              (signedUrls.nda[0]?.fileid?.length > 0 && formData.DecNDA)
                            )
                            ? TickedIcon : formData.applicationOpen ? Step2CompleteIcon : Step2Icon} 
                          alt='Application'
                        ></img>
                          
                        {/* APPLICATION */}
                        <label className='PreQ-Form-Expandable-Pane-Heading'>APPLICATION</label>

                        {/* V */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-ExpandCollapseIcon' 
                          src={formData.applicationOpen ? CollapseIcon : ExpandIcon} 
                          alt='Expand' 
                          onClick={() => setFormData({ 'applicationOpen': !formData.applicationOpen })}
                        ></img>
                        
                      </div>

                      {/* ---------- APPLICATION FORM ---------- */}

                      {
                        formData.applicationOpen && (

                          <div className='PreQ-Form-Expandable-Pane-Content'>
                            <div className='PreQ-Form-Application-Container'>

                              {/* ----------------------------------------------- */}
                              {/*   Question 1 - Full Name                        */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>1. Please provide your full name *</p>
                                <div className='PreQ-Form-Application-Dual-Column-Grid'>
                                             
                                  {/* First Name */}
                                  <div className='PreQ-Form-Application-Names'>
                                    <label> FIRST NAME </label>
                                    <input 
                                      required 
                                      className={formData.givenNameValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                      type='text'
                                      name='givenName' 
                                      value={formData.givenName}
                                      onChange={handleInputChange}
                                      disabled
                                    ></input>
                                  </div>

                                  {/* Last Name */}
                                  <div className='PreQ-Form-Application-Names'>
                                    <label> LAST NAME </label>
                                    <input 
                                      required 
                                      className={formData.lastNameValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                      type='text' 
                                      name='lastName' 
                                      value={formData.lastName}
                                      onChange={handleInputChange}
                                      disabled
                                    ></input>
                                  </div>

                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 2 - Email Address                    */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>2. Please provide the email address where you would like the application notification to be sent *</p>
                                <div className='PreQ-Form-Application-Single-Column-Grid'>
                                  <input 
                                    required 
                                    className={formData.notificationEmailValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='text'
                                    name='notificationEmail' 
                                    value={formData.notificationEmail}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                    disabled
                                  ></input>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 3 - Date of Birth                    */}
                              {/* ----------------------------------------------- */}

                              <div>
                                <p className='PreQ-Form-Application-Question'>3. Date of Birth *</p>
                                <div>
                                  <input 
                                    required
                                    className={formData.dateOfBirthValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='date'
                                    name='dateOfBirth'
                                    value={formData.dateOfBirth}
                                    onChange={handleInputChange}
                                    disabled
                                  ></input>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 4 - Company Name                     */}
                              {/* ----------------------------------------------- */}

                              <div>
                                <p className='PreQ-Form-Application-Question'>4. Company Name *</p>
                                <div className='PreQ-Form-Application-Single-Column-Grid'>
                                  <input
                                    required
                                    className={formData.companyNameValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='text' 
                                    name='companyName'
                                    placeholder='Enter your company name' 
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                    disabled
                                  ></input>
                                </div>
                              </div>
                              
                              {/* ----------------------------------------------- */}
                              {/*   Question 5 - Company Position                 */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>5. Company Position *</p>
                                <div className='PreQ-Form-Application-Single-Column-Grid'>
                                  <input
                                    required
                                    className={formData.companyPositionValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='text'
                                    name='companyPosition'
                                    placeholder='Enter your company position' 
                                    value={formData.companyPosition}
                                    onChange={handleInputChange}
                                    disabled
                                  ></input>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 6 - Reason for Access                */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>6. Please provide a reason for your access *</p>
                                <div className='PreQ-Form-Application-Full-Width-Grid '>
                                  <textarea
                                    required
                                    className={formData.reasonForAccessValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    name='reasonForAccess'
                                    placeholder='Enter a reason for access' 
                                    rows='5' 
                                    cols='100' 
                                    value={formData.reasonForAccess}
                                    onChange={(e) => setFormData({ 'reasonForAccess': e.target.value })}
                                    disabled
                                  ></textarea>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 7 - AGSVA Clearance (Yes/No)         */}
                              {/* ----------------------------------------------- */}

                              <div> 
                                
                                {/* Question */}
                                <div className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                  7. Do you currently hold an AGSVA security clearance (i.e. baseline or higher)? * 
                                </div>

                                {/* Radio Buttons */}
                                <div className='PreQ-Radio-Buttons-Container '>
                                  <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input 
                                      type='radio' 
                                      name='AGSVA' 
                                      value='yes' 
                                      checked={formData.AGSVA === 'yes'} 
                                      onChange={handleInputChange}
                                      disabled
                                    ></input>
                                    <span className={formData.AGSVAValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                                  </label>

                                  <label className='Radio-Buttons-Container'>
                                    No
                                    <input 
                                      type='radio' 
                                      name='AGSVA' 
                                      value='no' 
                                      checked={formData.AGSVA === 'no'} 
                                      onChange={handleInputChange}
                                      disabled
                                      ></input>
                                    <span className={formData.AGSVAValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                                  </label>
                                </div>

                              </div>

                              {
                                // -----------------------------------------------//
                                //   AGSVA is 'Yes' > Upload Files                //
                                // -----------------------------------------------//

                                formData.AGSVA === 'yes' ? 
                                (

                                  <div className='PreQ-Form-Application-Required-Uploads' style={{padding: '15px'}}>

                                    <h6>REQUIRED UPLOADS</h6>
                                    <p>Please upload a copy of your current driver's licence (front and back).</p>

                                    {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                                    {
                                      prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' ? 
                                      (
                                        <FileUploadDCACForm
                                          formData={formData}
                                          setFormData={setFormData}
                                          dataField={'AGSVAFiles'}
                                          errorMessageField={'AGSVAErrorMessage'}
                                          validField={'AGSVAValid'}
                                        ></FileUploadDCACForm>
                                      )
                                      :
                                      (
                                        <div className='PreQ-Uploads-List-Container'>
                                          <b>FILE NAME</b>
                      
                                          {/* File Link */}
                                          {
                                              signedUrls?.agsva.map((object, index) => (
                                                  <div className='PreQ-Uploads-List-Row' key={index}>

                                                      {/* File Name */}
                                                      <a 
                                                        href={object.signedurl} 
                                                        alt={object.filename} 
                                                        target='_blank' 
                                                        rel='noreferrer'>
                                                          {object.filename}
                                                      </a>
                                            
                      
                                                      {/* View File */}
                                                      <div className='PreQ-Uploads-List-ViewFile'>
                                                        <a 
                                                          href={object.signedurl} 
                                                          alt={object.filename} 
                                                          target='_blank' 
                                                          rel='noreferrer'>
                                                          <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                        </a>
                                                      </div>
                                                        
                                                  </div>
                                              ))
                                          }
                                        </div>
                                      )
                                    }

                                  </div>

                                )
                                :
                                // ------------------------------------------------//
                                //   AGSVA is 'No' > Skip to Next Question         //
                                // ------------------------------------------------//
                                (
                                
                                <>
                                  {/*---------------------------------------------------------------*/}
                                  {/*  Question 8 - Are you an Australian Citizen?                  */}
                                  {/*---------------------------------------------------------------*/}

                                  <div>
                                    
                                    {/* Question */}
                                    <p className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                      8. Are you an Australian citizen? *
                                    </p>

                                    {/* Radio Buttons */}
                                    <div className='PreQ-Radio-Buttons-Container '>
                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio' 
                                          name='AusCit'
                                          value='yes' 
                                          checked={formData.AusCit === 'yes'} 
                                          onChange={handleInputChange} 
                                          disabled
                                        ></input>
                                        <span className={formData.AusCitValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                                        Yes
                                      </label>

                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio' 
                                          name='AusCit'
                                          value='no' 
                                          checked={formData.AusCit === 'no'} 
                                          onChange={handleInputChange} 
                                          disabled
                                        ></input>
                                        <span className={formData.AusCitValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                                        No
                                      </label>
                                    </div>

                                    {/* Required Uploads */}
                                    <div>
                                      {
                                        formData.AusCit === 'yes' ? (

                                        // --------------------------------------------------------------- //
                                        //   Australian Citizen ('Yes') > Upload License/Passport          //
                                        // --------------------------------------------------------------- //

                                          <div className='PreQ-Form-Application-Required-Uploads'>

                                            <h6>REQUIRED UPLOADS</h6>

                                            <p>Please upload a copy of your current driver's licence (front and back).</p>

                                            <div className='PreQ-Form-Application-Upload-Option-Container'>
                                              <div className='PreQ-Form-Application-Upload-Option'>A</div>
                                              <ul>
                                                <li className='list-disc'>Current Australian Passport</li>
                                                <li className='list-disc'>Current National Police Check (cannot be older than 30 days on date of approval). <b>Note: </b>needs to be uploaded by 21 days to allow for processing. </li>
                                              </ul>
                                            </div>

                                            <p>OR</p>

                                            <div className='PreQ-Form-Application-Upload-Option-Container'>
                                              <div className='PreQ-Form-Application-Upload-Option'>B</div>
                                              <ul>
                                                <li className='list-disc'>Current Driver's Licence (Front and Back)</li>
                                                <li className='list-disc'>Birth Certificate</li>
                                                <li className='list-disc'>Current National Police Check (cannot be older than 30 days on date of approval). <b>Note: </b>needs to be uploaded by 21 days to allow for processing. </li>
                                              </ul>
                                            </div>

                                            <p style={{padding: '15px 0px 10px 0px'}}><b>Note: </b>If you were born overseas but are an Australian Citizen and do not hold a current Australian Passport, then upload a copy of your Australian Citizenship Certificate and current Driver's Licence (Front and Back).</p>


                                            {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                                            {
                                              prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' ? 
                                              (
                                                <FileUploadDCACForm
                                                  formData={formData}
                                                  setFormData={setFormData}
                                                  dataField={'AusCitFiles'}
                                                  errorMessageField={'AusCitErrorMessage'}
                                                  validField={'AusCitValid'}
                                                ></FileUploadDCACForm>
                                              )
                                              :
                                              (
                                                <div className='PreQ-Uploads-List-Container'>
                                                  <b>FILE NAME</b>
                              
                                                  {/* File Link */}
                                                  {
                                                      signedUrls?.auscit.map((object, index) => (
                                                          <div className='PreQ-Uploads-List-Row' key={index}>

                                                            {/* File Name */}
                                                            <a 
                                                              href={object.signedurl} 
                                                              alt={object.filename} 
                                                              target='_blank' 
                                                              rel='noreferrer'>
                                                                {object.filename}
                                                            </a>
                                                        
                              
                                                            {/* View File */}
                                                            <div className='PreQ-Uploads-List-ViewFile'>
                                                              <a 
                                                                href={object.signedurl} 
                                                                alt={object.filename} 
                                                                target='_blank' 
                                                                rel='noreferrer'>
                                                                <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                              </a>
                                                            </div>
                                                                
                                                          </div>
                                                      ))
                                                  }
                                                </div>
                                              )
                                            }

                                          </div>

                                        // --------------------------------------------------------------- //
                                        //   Australian Citizen ('No') > Upload Foreign Passport           //
                                        // --------------------------------------------------------------- //

                                        ) 
                                        : 
                                        formData.AusCit === 'no' ? (
                                          <div className='PreQ-Form-Application-Required-Uploads'>                                          
                                            <h6>REQUIRED UPLOADS</h6>
                                            <p>Please upload the following documents</p>
                                            <ul>
                                              <li className='list-disc'>Current Foreign Passport</li>
                                              <li className='list-disc'>VEVO check obtained via the Department of Home Affairs Website.
                                                <p style={{margin: '0px'}}><b>Note: </b>should be two pages in length and not older than 1 month. </p>
                                                <a href='https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online'>https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online</a>
                                              </li>
                                              <li className='list-disc'>
                                                <p style={{margin: '0px'}}>Current National Police Check (cannot be older than 30 days on date of approval).</p>
                                                <b>Note: </b>needs to be uploaded by 21 days to allow for processing.
                                              </li>
                                            </ul>

                                            <br></br>

                                            {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                                            {
                                              prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' ? 
                                              (
                                                <FileUploadDCACForm
                                                  formData={formData}
                                                  setFormData={setFormData}
                                                  dataField={'AusCitFiles'}
                                                  errorMessageField={'AusCitErrorMessage'}
                                                  validField={'AusCitValid'}
                                                ></FileUploadDCACForm>
                                              )
                                              :
                                              (
                                                <div className='PreQ-Uploads-List-Container'>
                                                  <b>FILE NAME</b>
                              
                                                  {/* File Link */}
                                                  {
                                                      signedUrls?.auscit.map((object, index) => (
                                                          <div className='PreQ-Uploads-List-Row' key={index}>

                                                            {/* File Name */}
                                                            <a 
                                                              href={object.signedurl} 
                                                              alt={object.filename} 
                                                              target='_blank' 
                                                              rel='noreferrer'>
                                                                {object.filename}
                                                            </a>
                            
                                                            {/* View File */}
                                                            <div className='PreQ-Uploads-List-ViewFile'>
                                                              <a 
                                                                href={object.signedurl} 
                                                                alt={object.filename} 
                                                                target='_blank' 
                                                                rel='noreferrer'>
                                                                <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                              </a> 
                                                            </div>
                                                                
                                                          </div>
                                                      ))
                                                  }
                                                </div>
                                              )
                                            }

                                          </div>
                                        )
                                        : null
                                      }
                                    </div>

                                  </div>

                              
                                  {/*---------------------------------------------------------------*/}
                                  {/*  Question 9 - Disclosable Outcomes                            */}
                                  {/*---------------------------------------------------------------*/}

                                  <div>

                                    {/* Question */}
                                    <p className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                      9. Do you have any disclosable outcomes of your National Police Check? *
                                    </p>

                                    {/* Radio Buttons */}
                                    <div className='PreQ-Radio-Buttons-Container '>
                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio'
                                          name='NPC'
                                          value='yes'
                                          checked={formData.NPC === 'yes'}
                                          onChange={handleInputChange} 
                                          disabled
                                        ></input>
                                        <span className={formData.NPCValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                                        Yes
                                      </label>

                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio'
                                          name='NPC'
                                          value='no'
                                          checked={formData.NPC === 'no'}
                                          onChange={handleInputChange} 
                                          disabled
                                        ></input>
                                        <span className={formData.NPCValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                                        No
                                      </label>
                                    </div>

                                    {/* Required Uploads */}
                                    {
                                      formData.NPC === 'yes' && (
                                        <div className='PreQ-Form-Application-Required-Uploads'>
                                          <h6>REQUIRED UPLOADS</h6>
                                          <p>Please upload a copy of a referral letter from your current employer below.</p>

                                          {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                                          {
                                            prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' ? 
                                            (
                                              <FileUploadDCACForm
                                                formData={formData}
                                                setFormData={setFormData}
                                                dataField={'NPCFiles'}
                                                errorMessageField={'NPCErrorMessage'}
                                                validField={'NPCValid'}
                                              ></FileUploadDCACForm>
                                            )
                                            :
                                            (
                                              <div className='PreQ-Uploads-List-Container'>
                                                <b>FILE NAME</b>
                            
                                                {/* File Link */}
                                                {
                                                    signedUrls?.npc.map((object, index) => (
                                                        <div className='PreQ-Uploads-List-Row' key={index}>

                                                          {/* File Name */}
                                                          <a 
                                                            href={object.signedurl} 
                                                            alt={object.filename} 
                                                            target='_blank' 
                                                            rel='noreferrer'>
                                                              {object.filename}
                                                          </a>
                          
                                                          {/* View File */}
                                                          <div className='PreQ-Uploads-List-ViewFile'>
                                                            <a
                                                              href={object.signedurl} 
                                                              alt={object.filename} 
                                                              target='_blank' 
                                                              rel='noreferrer'>
                                                              <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                            </a>   
                                                          </div>
                                                              
                                                        </div>
                                                    ))
                                                }
                                              </div>
                                            )
                                          }

                                        </div>
                                      )
                                    }

                                  </div>
                                
                                </>  
                                )
                              }

                              {/* ----------------------------------------------- */}
                              {/*  Nominated Pass Office                          */}
                              {/* ----------------------------------------------- */}

                              <div>

                                {/* Question */}
                                <p className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                  10. Nominated Pass Office *
                                </p>

                                {/* Select an office */}
                                <div style={{paddingLeft: '10px'}}>

                                  <select 
                                    className={'PreQ-Form-NominatedOffice-Select'}
                                    value={formData.passOfficeState}
                                    disabled
                                  >
                                    <option >{formData.passOfficeState}</option>
                                  </select>
                                  
                                  {/* Map each office from the selected state */}
                                  <select
                                    style={{marginLeft: '10px'}}
                                    className={'PreQ-Form-NominatedOffice-Select'}
                                    disabled
                                  >
                                    <option >{formData.passOfficeName}</option>
                                  </select>

                                  <div style={{paddingTop: '10px'}}>
                                    <label style={{color: '#545454', padding: '10px 0px'}}>If you require an alternate pass office, please enter the name of the pass office below.</label>
                                    <div>
                                      <input
                                        style={{width: '50%', minWidth: '250px'}}
                                        className={'PreQ-Form-Application-Input'} 
                                        type='text'
                                        value={formData.passOfficeNew}
                                        disabled
                                      ></input>
                                    </div>

                                  </div>

                                </div>

                              </div>

                              {/* ----------------------------------------------- */}
                              {/*  DECLARATION                                    */}
                              {/* ----------------------------------------------- */}

                              <div className='PreQ-Form-Application-Declaration'>
                                <h6>DECLARATION</h6>

                                {/* Checkbox 1 - Right to Work */}
                                <div>
                                  <label className='PreQ-Form-Checkbox-Container'>
                                    <input
                                      required
                                      type='checkbox'
                                      id='DecRightToWork'
                                      name='DecRightToWork'
                                      checked={formData.DecRightToWork}
                                      onChange={() => setFormData({ 'DecRightToWork': !formData.DecRightToWork, 'DecRightToWorkValid': true })}
                                      disabled
                                    ></input>
                                    <span className={formData.DecRightToWorkValid ? 'PreQ-Form-Checkbox' : 'PreQ-Form-Checkbox-Error'}></span>
                                  </label>
                                  <p>By ticking this box, I confirm that the information provided in this form and my profile is true and accurate. I am lawfully entitled to work in Australia and can provide all relevant documentation confirming this right to work if requested.</p>
                                </div>

                                {/* Checkbox 2 - Conditions of Use */}
                                <div>
                                  <label className='PreQ-Form-Checkbox-Container'>
                                    <input
                                      required
                                      type='checkbox'
                                      id='DecConditionsOfUse'
                                      name='DecConditionsOfUse'
                                      checked={formData.DecConditionsOfUse}
                                      onChange={() => setFormData({ 'DecConditionsOfUse': !formData.DecConditionsOfUse, 'DecConditionsOfUseValid': true })}
                                      disabled
                                    ></input>
                                    <span className={formData.DecConditionsOfUseValid ? 'PreQ-Form-Checkbox' : 'PreQ-Form-Checkbox-Error'}></span>
                                  </label>
                                  <p>I agree that on collection of my DCAC at the nominated pass office, I will read, sign and abide by the Dept of Defence <a href="https://www.google.com" target='_blank' rel='noreferrer'>Conditions of Use</a> of a Defence Common Access Card.</p>
                                </div>

                                {/* Checkbox 3 - Non-Disclosure Agreement --> OPTIONAL */}
                                {
                                  signedUrls.nda[0]?.fileid?.length > 0 && 

                                    <div>
                                      <label className='PreQ-Form-Checkbox-Container'>
                                        <input
                                          required
                                          type='checkbox'
                                          id='DecNDA'
                                          name='DecNDA'
                                          checked={formData.DecNDA}
                                          onChange={() => setFormData({ 'DecNDA': !formData.DecNDA, 'DecNDAValid': true })}
                                          disabled
                                        ></input>
                                        <span className={formData.DecNDAValid ? 'PreQ-Form-Checkbox' : 'PreQ-Form-Checkbox-Error'}></span>
                                      </label>

                                      <p>I have read and agree to the terms outlined in the <a href={signedUrls.nda[0]?.signedurl} alt={signedUrls.nda[0]?.filename} target='_blank' rel='noreferrer'>Non-Disclosure Agreement</a> .</p>
                                    
                                    </div>
                                  
                                }

                              </div>

                            </div>
                          </div>
        
                        )
                      }

                    {/* ------------------------------------ */}
                    {/*   ADDITIONAL INFORMATION             */}
                    {/* ------------------------------------ */}

                      {/* -------------- EXPANDABLE PANE -------------*/}
                      
                      <div 
                        className='PreQ-Form-Expandable-Pane'
                        onClick={() => {

                          if(formData.addInfoOpen) setFormData({'addInfoComplete': true})
                          setFormData({
                            'addInfoOpen': !formData.addInfoOpen,
                          })
                        
                        }}
                      >
                        
                        {/* Step 3 */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-Step' 
                          src={formData.addInfoComplete ? TickedIcon : formData.addInfoOpen ? Step3CompleteIcon : Step3Icon} 
                          alt='Additional Information'
                        ></img>

                        {/* ADDITIONAL INFORMATION */}
                        <label className='PreQ-Form-Expandable-Pane-Heading'>ADDITIONAL INFORMATION</label>

                        {/* V */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-ExpandCollapseIcon' 
                          src={formData.addInfoOpen ? CollapseIcon : ExpandIcon} 
                          alt='Expand' 
                          onClick={() => {
                            if(formData.addInfoOpen) setFormData({'addInfoComplete': true})
                            setFormData({
                              'addInfoOpen': !formData.addInfoOpen,
                            })
                          }}
                        ></img>

                      </div>

                      {/* ---------- ADDITIONAL INFORMATION ---------- */}

                      {
                        formData.addInfoOpen && (
                          <div className='PreQ-Form-Expandable-Pane-Content'>
                            <div className='PreQ-Form-AddInfo-Container'>

                              <p>Provide any additional information regarding your application for the reviewer below.</p>
                              <h6>COMMENTS</h6>

                              {/* ----------------- COMMENTS ----------------- */}

                              <div className='PreQ-Form-AddInfo-Comments-Container'>

                                {/* Text Area */}
                                <div className='PreQ-Form-AddInfo-Comments'>
                                  <textarea
                                    rows='5' 
                                    cols='100'
                                    placeholder='Enter any additional comments you may want your reviewer to see.' 
                                    value={formData.addInfoCommentTextArea}
                                    onChange={(e) => {
                                      setFormData({
                                        'addInfoCommentTextArea': e.target.value,
                                        'addInfoSendValid': true
                                      })
                                    }}
                                    disabled={(prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' || prequalification?.status === 'review') ? false : true}
                                  ></textarea>

                                  
                                  {/* ----------------- UPLOAD FILE ----------------- */}
                                  {
                                    formData.addInfoUploadFile &&

                                      <FileUploadDCACForm
                                        formData={formData}
                                        setFormData={setFormData}
                                        dataField={'addInfoFile'}
                                        errorMessageField={'addInfoErrorMessage'}
                                        validField={'addInfoValid'}
                                      ></FileUploadDCACForm>

                                  }
                                </div>

                                <div className='PreQ-Form-AddInfo-Comments-Container-Buttons'>

                                  {/* Send Button */}
                                  <button 
                                    className='Primary-Button flex flex-row gap-2 justify-center items-center' 
                                    disabled={(prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' || prequalification?.status === 'review') ? false : true}
                                    onClick={() => {
                                      
                                      formData.addInfoComments.push(
                                        {
                                          'actionedby': `${currentUser?.givenname} ${currentUser?.surname} `,
                                          'attachedfile': formData.addInfoFile,
                                          'comment': formData.addInfoCommentTextArea,
                                          'actiondate': new Date()
                                        }
                                      )
                                      formData.addInfoUploadFile = false;
                                      formData.addInfoFile = [];
                                      formData.addInfoSendValid = false;
                                      setFormData(formData);
                                      setFormData({'addInfoCommentTextArea': ''});
                                  
                                    }}                                  >  
                                    <img src={SendMail} alt='Send Mail' style={{padding: '0px 5px'}}></img> Send
                                  </button>

                                  {/* Attach File Button */}
                                  <button 
                                    className='Secondary-Button flex flex-row gap-2 justify-center items-center' 
                                    onClick={() => setFormData({ 'addInfoUploadFile': true })}
                                    disabled={(prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' || prequalification?.status === 'review') ? false : true}
                                  >  
                                    <img 
                                      src={(prequalification?.status === 'not-submitted' || prequalification?.status === 'moreinfo' || prequalification?.status === 'review') ? AttachFile : AttachFileDisabled} 
                                      alt='Attach File' 
                                      style={{padding: '0px 5px 0px 10px'}}
                                    ></img> 
                                    Attach File
                                  </button>
                                </div>

                              </div>

                              <hr></hr>

                              {/* ----------------- COMMENTS THREAD ----------------- */}

                              {
                                formData.addInfoComments.length > 0 && 

                                  <CommentsThread
                                    formData={formData}
                                  ></CommentsThread>

                              }

                            </div>
                          </div>
                        )
                      }
                    </div>
                  
                  </div>
                ) 
                :
                null
              }
            </div>

      </div>
    )
}