//Library
import React, {useState} from 'react'

//Auth config, status and errors
export const GetFireBaseProvider = React.createContext();
export const SetFireBaseProvider = React.createContext();
export const GetAppStatus = React.createContext();
export const SetAppStatus = React.createContext();
export const GetAppErrors = React.createContext();
export const SetAppErrors = React.createContext();

//Firebase user and profile
export const GetFireBaseUser = React.createContext();
export const SetFireBaseUser = React.createContext();
export const GetUser = React.createContext();
export const SetUser = React.createContext();

//Project selector
export const GetProjectSelector = React.createContext();
export const SetProjectSelector = React.createContext();

//Component and page errors
export const GetError = React.createContext();
export const SetError = React.createContext();

/**
 * Define global states that are shared across components without the need for props
 * 
 * @param {object} children
 * 
 * ```
 * <GlobalContexts>
 *     <App></App>
 * </GlobalContexts>
 * 
 * ```
 * 
 * Documentation
 * - https://www.w3schools.com/react/react_usecontext.asp
 * - https://stackoverflow.com/questions/64256481/referenceerror-cannot-access-usercontext-before-initialization-react
 * - https://mindsers.blog/post/updating-react-context-from-consumer/
 */



export default function GlobalContexts({children}) {

  //------------------------------------------------------
  //  Define a useState to each context
  //------------------------------------------------------

    const [fireBaseProvider, setFireBaseProvider] = useState();
    const [appStatus, setAppStatus] = useState('pending');
    const [appErrors, setAppErrors] = useState();

    const [firebaseUser, setFirebaseUser] = useState();
    const [getUser, setUser] = useState();

    // TODO ~ need to remove selectedProject and use urls instead
    const [projectSelector, setProjectSelector] = useState({
      'selectedProject': undefined,
      'projects': [],
      'visible': false
    });

    const [getErrors, setErrors] = useState();

  
  //------------------------------------------------------
  //  Pass down all parent components & contexts to childern
  //------------------------------------------------------

    return (

      // Firebase Provider
      <GetFireBaseProvider.Provider value={fireBaseProvider}>
      <SetFireBaseProvider.Provider value={setFireBaseProvider}>

      {/* App Status */}
      <GetAppStatus.Provider value={appStatus}>
      <SetAppStatus.Provider value={setAppStatus}>

      {/* App Errors */}
      <GetAppErrors.Provider value={appErrors}>
      <SetAppErrors.Provider value={setAppErrors}>

      {/* User */}
      <GetFireBaseUser.Provider value={firebaseUser}>
      <SetFireBaseUser.Provider value={setFirebaseUser}>
      <GetUser.Provider value={getUser}>
      <SetUser.Provider value={setUser}>

      {/* Project Selector */}
      <GetProjectSelector.Provider value={projectSelector}>
      <SetProjectSelector.Provider value={setProjectSelector}>

      {/* Other Errors */}
      <GetError.Provider value={getErrors}>
      <SetError.Provider value={setErrors}>

        {children}

      </SetError.Provider>
      </GetError.Provider>

      </SetProjectSelector.Provider>
      </GetProjectSelector.Provider>

      </SetUser.Provider>
      </GetUser.Provider>
      </SetFireBaseUser.Provider>
      </GetFireBaseUser.Provider>

      </SetAppErrors.Provider>
      </GetAppErrors.Provider>

      </SetAppStatus.Provider>
      </GetAppStatus.Provider>

      </SetFireBaseProvider.Provider>
      </GetFireBaseProvider.Provider>
    )

  //------------------------------------------------------
}
