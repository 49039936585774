//================================================================
//  Component: ProjectDelete
//================================================================

//  Purpose: This is the standard for creating a component

//  Properties:
//    - selectedProject = {useContext, getProjectSelector}

//  Example:
//    <ProjectDelete
//      selectedProject={getProjectSelector.selectedProject}
//    ></ProjectDelete>    

//================================================================


//Libraries
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetProjectSelector, GetUser } from '../../../../Library/GlobalContexts';

//Components

//Functions
import writeDocument from '../../../../Library/WriteDocument';
import writeAuditLog from '../../../../Library/WriteAuditLog';

//Images

//CSS
import '../../ProjectSettings.css';


export default function ProjectDelete({
  selectedProject
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const navigate = useNavigate();

    const getUser = useContext(GetUser);
    const getProjectSelector = useContext(GetProjectSelector);

  
  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Holds the checkbox state and enables the submit button
    const [preventSubmit, setPreventSubmit] = useState(true);

    // Holds firebase errors
    const [formError, setFormError] = useState('');


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function handleSubmit(){

      // ----------------------------------------------------------
      // 0. Conditions
      // ----------------------------------------------------------

      if (selectedProject?.projectid === undefined) {

        return setFormError('Unable to save changes, refresh and try again.');

      };

      // ----------------------------------------------------------
      // 1. Update project document
      // ----------------------------------------------------------

      const document = {
        'status': 'decommissioned',
        'decomissiondate': new Date()
      };

      writeDocument('projects', selectedProject.projectid, document, true)
      .then(() => {

        // Auditing
        const message = `${getUser.emailaddress} -- Deleted project ${selectedProject.projectname}.`;
        const types = [
          'project history'
        ];
        const references = [
          selectedProject.projectname,
          'project delete',
          getUser.emailaddress,
        ];
        return writeAuditLog(getUser.emailaddress, message, types, references).then(() => {

          getProjectSelector.selectedProject = undefined;

          // Route back home
          navigate('/home');
  
        });

      })
      .catch(() => {

        setFormError('Unable to save changes, please try again.');

      });

    };


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <>
      <div className='Page-Body-Container leading-7' style={{fontSize: '15px'}}>

        <div className='ProjectSetting-Header pb-3 mt-3 border-b border-b-[#D8D8D8]'>
          <h5>Delete Project</h5>
        </div>

        The project listed below will no longer be available and all data in this project will be permanently deleted.

        <ul className='mt-2'>
          <li className='list-disc font-medium'>
              {selectedProject?.projectname}
          </li>
        </ul>

        Please note the following:
        <ul className='mb-2'>
          <li className='list-disc'>
            Once deleted, your project and all it's associated data/files cannot be recovered.
          </li>
          <li className='list-disc'>
            Workers and administrators will no longer have access to the project on the portal.
          </li>
        </ul>

        {/* Checkbox */}
        <div className='flex flex-row items-start gap-1 text-[15px] my-4'>
          <label className='PreQ-Form-Checkbox-Container'>
            <input
              required
              type='checkbox'
              onChange={() => setPreventSubmit(!preventSubmit)}
            ></input>
            <span className='PreQ-Form-Checkbox'></span>
          </label>
          <label>By ticking this box, I confirm that I have read and understood the implications of deleting the project listed above. </label>
        </div>

        {/* Delete Project */}
        <button 
          style={preventSubmit === false ? ({backgroundColor: '#D85C5C', border: '1px solid #D85C5C'}): (null)}
          className='Primary-Button' 
          disabled={preventSubmit}
          onClick={() => handleSubmit()}
          >
            Delete Project
        </button>

        <div className='Input-Field-Text-Error-Message'>
          {formError}
        </div>

      </div>

    </>
  )
}
