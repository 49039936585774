//================================================================
//  Component: Comments Thread
//================================================================

//  Purpose: This component handles the comment thread in the additional information section of the prequalifications

//  Properties:
//  - formData = {useState, used to store file inputs for form}

//  Example:
//    <CommentsThread
//      formData={formData}
//     ></CommentsThread>

//================================================================


//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images

//CSS
import './CommentsThread.css';


export default function CommentsThread({
  formData
}) {


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Comments-Thread-Container'>
      {
        formData?.addInfoComments.map((comment, index) => (
          <div key={index} className='Comment-Container'>

            {/* User's Initials */}
            <div className='Comment-Box-Initials'>
              {comment.actionedby.split(' ')[0][0].toUpperCase()}{comment.actionedby.split(' ')[1][0].toUpperCase()}
            </div>

            {/* User's Comment */}
            <div className='Comment-Box-Body'>
              <p>{comment.actionedby}</p>
              {/* TODO - Need to fix this bug  */}
              <label>{comment?.actiondate?.toDate()?.toLocaleString('en-US', {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}</label>

              {comment.comment}
              
              {
                comment.attachedfile.length > 0 && 
                <div className='Comment-Box-File-Link'>
                  <a href={comment?.attachedfile[0]?.signedurl} target='_blank' rel='noreferrer'>{comment?.attachedfile[0]?.filename}</a>
                </div>
              }

            </div>
          </div>
        ))
      }
    </div>
  )
}
