//================================================================
//  Component: Project Selector
//================================================================

//  Purpose: Allows users to select defence projects 

//  Example:
//    <ProjectSelector></ProjectSelector>    

//================================================================


//Libraries
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, GetProjectSelector, SetProjectSelector } from '../../Library/GlobalContexts';

//Components

//Functions
import CheckUserRole from '../../Library/checkUserRole';

//Images
import EditIcon from '../Images/Icon_Edit_Grey.svg';

//CSS
import './ProjectSelector.css';


export default function ProjectSelector() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
  
    const navigate = useNavigate();


  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const getProjectSelector = useContext(GetProjectSelector);
    const setProjectSelector = useContext(SetProjectSelector);


  //------------------------------------------------------
  //  Check role service
  //------------------------------------------------------

    // Benno-TODO ~ Hey Nowshin, this should be in a useEffect with getUser as the dependency. As this will run everytime the components state changes.
    //Required roles
    const requiredRoles = ['globalAdmin'];

    //Get the user's roles 
    const userRoles = getUser?.roles;

    //Check if the current item has the required role
    const roleCheck = CheckUserRole(requiredRoles, userRoles, true);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Set modal visibility for the project selector
    function showModal(visibility) {

      getProjectSelector.visible = visibility
    
      //Update getProjectSelector context
      setProjectSelector({...getProjectSelector})
    
    };

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Modal-Background'>
      <div className='Project-Selector-Container'>

        {/* Header */}
        <div className='Project-Selector-Header'>

          <h5>Select a Project</h5>

          {/* NH-TODO ~ Decision on using <Link> component */}
          {/* Create Project (only available to global admins) */}
          {
            roleCheck &&
            <Link to='/createproject'> 
              <button className='Primary-Button' onClick={() => showModal(false)}>Create Project</button>
            </Link> 
          }
 
        </div>

        {/* Search Bar */}
        {/* Benno-TODO ~ Confirm if we are adding a search bar. */}

        {/* Projects Table */}
        <div className='Project-Selector-Table-Container'>
          <table className='Project-Selector-Table'>
            <colgroup>
              <col span='1' style={{width: '10%'}}></col>
              <col span='1' style={{width: '30%'}}></col>
              <col span='1' style={{width: '60%'}}></col>
            </colgroup>
            <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
              {
                getProjectSelector.projects?.map((project, index) =>(
                  <tr key={index} style={{cursor: 'default'}}>
                    <td> 
                      <img 
                        style={{cursor: 'pointer'}}
                        src={EditIcon} 
                        alt='Edit-Icon'
                        onClick={() => {
  
                          navigate(`projectsettings/?project=${project.projectid}&view=overview`);
                          getProjectSelector.selectedProject = project;
                          setProjectSelector({...getProjectSelector});
                          showModal(false);
  
                        }}
                      ></img> 
                    </td>
                    <td 
                      style={{color: '#0C6CDE', textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={() => {

                        navigate(`/prequalifications?project=${project.projectid}`);
                        getProjectSelector.selectedProject = project;
                        setProjectSelector({...getProjectSelector});
                        showModal(false);

                      }}
                    > {project.projectname} </td>
                    <td className='Project-Selector-Description'> {project.description} </td>
                  </tr>

                ))
              }
            </tbody>
          </table>
        </div> 

        {/* Cancel Button */}
        <div className='Project-Selector-Cancel'>
          <button className='Project-Selector-Cancel-Button' onClick={() => showModal(false)}>Cancel</button>
        </div>

      </div>
    </div>
  )
}
