//================================================================
//  Page: Create project 
//================================================================

//  Purpose: Create a project page

//  Example:
//    <CreateProject></CreateProject>    

//================================================================


//Libraries
import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp  } from 'firebase/app';
import {firebaseConfig } from '../../Library/FirebaseConfig';
import { getFirestore } from 'firebase/firestore';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

//Contexts
import { GetUser, SetError } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import UploadNewNDA from './Components/UploadNewNDA';

//Functions
import getDocument from '../../Library/GetDocument';
import writeDocument from '../../Library/WriteDocument';
import writeAuditLog from '../../Library/WriteAuditLog';

//Images
import CreateProjectIcon from '../../Components/Images/Icon_CreateProject_Blue.svg';
import IconAddGrey from '../../Components/Images/Icon_Add_Grey.svg';
import IconAddTeal from '../../Components/Images/Icon_Add_Teal.svg';
import IconDeleteRed from '../../Components/Images/Icon_Delete_Red.svg';
import IconInfoRequired from '../../Components/Images/Icon_InfoRequired_Teal.svg';

//CSS
import './CreateProject.css';


export default function CreateProject() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setError = useContext(SetError);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'onload', 'pending', 'error-other', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'name': '',
        'nameValid': true,
        'description': '',
        'descriptionValid': true,

        // Project Owner States
        'newOwner': '',
        'newOwnerGivenname': '',
        'newOwnerSurname': '',
        'newOwnerAllowAdd': false, 
        'newOwnerStatus': 'onload', // 'onload' or 'new-user'
        'newOwnerMessage': '',
        'owners': [],

        'location': '',
        'locationValid': true,
        // Benno ~ If we get time, I will move this to Firestore
        'passOfficeData': [
          {
            'state': 'NSW',
            'offices': [
              'Glenbrook',
              'Orchard Hills',
              'Albatross',
              'Wagga',
              'Kapooka',
              'Holsworthy',
              'Defence Plaza Sydney',
              'Kuttabul',
              'Williamtown',
              'Singleton',
              'Richmond',
            ]
          },
          {
            'state': 'VIC',
            'offices': [
              'Simpson',
              'Victoria Barracks Melbourne',
              'Puckapunyall',
              'East Sale',
              'Cerberus',
              'Williams',
              'Bandiana',
            ]
          },
          {
            'state': 'TAS',
            'offices': [
              'Derwent',
            ]
          },
          {
            'state': 'QLD',
            'offices': [
              'Gallipoli',
              'Oakey',
              'Amberley',
              'Kokoda',
              'Townsville',
              'Lavarack',
              'Cairns',

            ]
          },
          {
            'state': 'WA',
            'offices': [
              'Irwin',
              'Stirling',
              'Stirling Gangway',
              'Pearce',
              'Taylor',
            ]
          },
          {
            'state': 'NT',
            'offices': [
              'Robertson',
              'Larrakeyah',
              'Darwin National Pass Office',
              'Tindal',
            ]
          },
          {
            'state': 'SA',
            'offices': [
              'Edinburgh',
            ]
          },
          {
            'state': 'ACT',
            'offices': [
              'Russel',
              'Campbell Park',
              'RMC',
            ]
          },
        ],
        'passOfficeState': '',
        'passOfficeStateValid': true,
        'passOfficeNameFilter': [],
        'passOfficeName': '',
        'passOfficeNameValid': true,
        'passOfficeNew': '',
        'passOfficeNewValid': true,
        'dataLocation': 'Australia',
        'dataLocationValid': true,
        'disclosureFileId': `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}`,
        'disclosureFileName': '',
        'disclosureValid': true,
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function handleChangeOwner(value) {

      if (value.length === 0) {

        return setFormData({
          'newOwner': value,
          'newOwnerAllowAdd': false
        });

      }

      // Validate emailaddress
      if (/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)) {

        return setFormData({
          'newOwner': value,
          'newOwnerStatus': 'onload',
          'newOwnerMessage': '',
          'newOwnerAllowAdd': true,
        });

      } 

      // invalid emailaddress
      setFormData({
        'newOwner': value,
        'newOwnerStatus': 'onload',
        'newOwnerMessage': '',
        'newOwnerAllowAdd': false,
      });

    }

    // Adds a Project Owner to 'owners'
    function handleAddOwner() { 

      // Check for duplicates
      if (formData.owners.filter((object) => object.emailaddress === formData.newOwner).length > 0) {

        return setFormData({
          'newOwnerMessage': 'Email address already exists.'
        });

      }

      // Check if user exists in Firestore 'users' collection
      return getDocument('users', formData.newOwner).then(() => {

        formData.owners.push({
          'emailaddress': formData.newOwner,
          'type': 'existing'
        });

        formData.newOwner = '';
        formData.newOwnerMessage = '';
        formData.newOwnerAllowAdd = false;
        formData.newOwnerStatus = 'onload';
        setFormData(formData);

      }).catch(() => {

        formData.newOwnerStatus = 'new-user';
        formData.newOwnerMessage = '';
        formData.newOwnerAllowAdd = false;
        setFormData(formData);

      });

    };

    // Handle submit
    function onSubmit(e){

      e.preventDefault();

      // OnSubmit Validation of all required fields
      let preventSubmit = false;

      if (formData.name.length === 0) {

        preventSubmit = true;
        formData.nameValid = false;

      } else {

        formData.nameValid = true;

      };

      if (formData.description.length === 0) {

        preventSubmit = true;
        formData.descriptionValid = false;
        
      } else {

        formData.descriptionValid = true;

      };

      if (formData.owners.length === 0) {

        preventSubmit = true;
        formData.newOwnerMessage = 'At least one project owner is required.';

      } else {

        formData.newOwnerMessage = '';

      };

      if (formData.location.length === 0) {

        preventSubmit = true;
        formData.locationValid = false;
        
      } else {

        formData.locationValid = true;

      };

      if (formData.passOfficeState.length === 0) {

        preventSubmit = true;
        formData.passOfficeStateValid = false;
        
      } else {

        formData.passOfficeStateValid = true;

      };

      // Nominated Pass Office * ~ Requires one of two fields to be populated
      //  Option 1 ~ State & Office selected from dropdowns
      //  Option 2 ~ Office manually typed in
      let nominatedPassState = '';
      let nominatedPassOffice = '';
      if (formData.passOfficeName.length > 0) {

        formData.passOfficeNameValid = true;
        formData.passOfficeNewValid = true;
        nominatedPassState = formData.passOfficeState;
        nominatedPassOffice = formData.passOfficeName;

      } else if (formData.passOfficeNew.length > 0) {

        formData.passOfficeNameValid = true;
        formData.passOfficeNewValid = true;
        nominatedPassState = 'N/A';
        nominatedPassOffice = '';

      } else {

        preventSubmit = true;
        formData.passOfficeNameValid = false;
        formData.passOfficeNewValid = false;

      }

      if (formData.dataLocation.length === 0) {

        preventSubmit = true;
        formData.dataLocationValid = false;
        
      } else {

        formData.dataLocationValid = true;

      };

      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) {

        return;

      }

      // ================================================================
      // Passed all validation checks --> write project & user documents
      //  - Create an new project document 
      //  - Create new user documents
      //  - Update 'projectAdmin' array (Ref to arrayUnion's in Firebase documentation)
      // ================================================================

      // ----------------------------------------------------------
      // 0. Prepare
      // ----------------------------------------------------------

      // Firestore Client ~ Allows the update of elements in an array
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const projectId = `prj-${Date.now()}`
      const newUsers = [];
      const existingUsers = [];
      const fileDocId = `${formData.disclosureFileId}.${formData.disclosureFileName.split('.')[1]}`;
      const auditingPromises = [];

      setPageStatus('pending');

      // ----------------------------------------------------------
      // 1. Extract data from 'owners' array
      // ----------------------------------------------------------

      formData.owners.forEach((object) => {

        if (object.type === 'new') {

          // Define user document
          newUsers.push({
            'emailaddress': object.emailaddress,
            'givenname': object.givenname,
            'surname': object.surname,
            'created': new Date(),
            'projectAdmin': [
              projectId
            ],
            'organisationid': '',
            'organisationname': 'Lendlease',
            'orgAdmin': [],
            'projects': [],
            'roles': {
              'globalAdmin': false,
              'lendleaseUser': true,
            },
            'status': 'active'
          });

          // Auditing
          const message = `${getUser.emailaddress} -- Created user ${object.emailaddress} for project ${formData.name}.`;
          const types = [
            'app history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'organisations',
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );

        }

        if (object.type === 'existing') {

          existingUsers.push({
            'emailaddress': object.emailaddress,
            'projectAdmin': [
              projectId
            ]
          });

          // Auditing
          const message = `${getUser.emailaddress} -- Added user ${object.emailaddress} to project ${formData.name}.`;
          const types = [
            'app history'
          ];
          const references = [
            formData.name,
            object.emailaddress,
            'project',
          ];
          auditingPromises.push(
            writeAuditLog(getUser.emailaddress, message, types, references)
          );


        }
        
      });

      // ----------------------------------------------------------
      // 2. Write to 'projects' collection
      // ----------------------------------------------------------

      const projectDoc = {
        'projectid': projectId,
        'projectname': formData.name,
        'description': formData.description,
        'state': formData.location,
        'nominatedpassstate': nominatedPassState,
        'nominatedpassoffice': nominatedPassOffice,
        'nominatedpassofficenew': formData.passOfficeNew,
        'disclosure': fileDocId,
        'datalocation': formData.dataLocation,
        'status': 'active',
        'creationdate': new Date(),
        'createdby': getUser.emailaddress,
      };
      const projectPromise = writeDocument('projects', projectId, projectDoc, false);

      const message = `${getUser.emailaddress} -- Project ${formData.name} created.`;
      const types = [
        'app history'
      ];
      const references = [
        formData.name,
        projectId,
        getUser.emailaddress,
      ];
      auditingPromises.push(
        writeAuditLog(getUser.emailaddress, message, types, references)
      )

      // ----------------------------------------------------------
      // 3. Write 'new users' to 'users' collection
      // ----------------------------------------------------------

      const newUserPromises = [];
      newUsers.forEach((document) => {

        newUserPromises.push(
          writeDocument('users', document.emailaddress, document, true)
        )

      });

      // ----------------------------------------------------------
      // 4. Merge 'existing user' roles in their 'users' collection
      // ----------------------------------------------------------

      const existingUserPromises = [];
      existingUsers.forEach((document) => {

        // https://cloud.google.com/firestore/docs/manage-data/add-data#update_elements_in_an_array
        const docRef = doc(db, 'users', document.emailaddress);

        existingUserPromises.push(
          updateDoc(docRef, {
            projectAdmin: arrayUnion(projectId)
          })
        );

      });

      // ----------------------------------------------------------
      // 5. Settle promises & route user to 'prequalifications' page
      // ---------------------------------------------------------

      Promise.all([projectPromise, ...newUserPromises, ...existingUserPromises, ...auditingPromises]).then(() => {

        // ----------------------------------------------------------
        // 6. Write doc to files collection (for NDA)
        // ----------------------------------------------------------

        const fileDoc = {
          fileid: fileDocId,
          filename: formData.disclosureFileName,
          requesttype: 'nondisclosure',
          folderpath: `${projectId}`,
          projectid: projectId,
          organisationid: '',
          createdby: getUser.emailaddress,
          workeremail: '',
          status: 'pending transfer', 
          description: '',
          dateobtained: new Date(),
          expirydate: '',
          signedurl: '',
          signedurlexpirydate: ''
        }

        // Write to 'files' collection 
        writeDocument('files', fileDocId, fileDoc, true)
        .then(() => {

          navigate(`/prequalifications?project=${projectId}`);
          
        })
        .catch((error) => {

          setError(`Failed to submit form, error: ${error}`);
          setPageStatus('error-fatal');

        })

      }).catch((error) => {

        setError(`Failed to submit form, error: ${error}`);
        setPageStatus('error-fatal');
    
      });

    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload --> Add GA to project owners list
    useEffect(() => {

      // Conditions
      if (getUser.emailaddress === undefined) return;

      setFormData({
        'owners': [
          {
            'emailaddress': getUser.emailaddress,
            'type': 'existing',
          }
        ]
      });

    }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      header={
        <PageHeader
          icon={<img src={CreateProjectIcon} alt="Create Project Icon"></img>}
          title={'Create Project'}
        ></PageHeader>
      }
      requiredRoles={ ['globalAdmin'] }
      requiredRolesValues={ [true] }
      status={ pageStatus }
      body={
        <div className='Page-Body-Container'>
          
          <label>Please fill in the details below and click <b style={{fontWeight: "500"}}>Submit</b> to create a new project.</label>

          <form className='CreateProject-Form' onSubmit={(e) => onSubmit(e)}>

            {/* ===================================== */}
            {/*                 Name                  */}
            {/* ===================================== */}

            <div className='CreateProject-Form-Row'>

              <label><b style={{fontWeight: "500"}}>Name *</b> </label>
              <input
                className={formData.nameValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                type='text'
                placeholder='Enter a project name'
                onChange={(e) => setFormData({'name': e.target.value})}
                value={formData.name}
              ></input>

            </div>
            
            {/* ===================================== */}
            {/*            Description                */}
            {/* ===================================== */}

            <div className='CreateProject-Form-Row'>

              <label><b style={{fontWeight: "500"}}>Description *</b> </label>
              <textarea
                className={formData.descriptionValid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')} 
                placeholder='Enter a short description of your project'
                onChange={(e) => setFormData({'description': e.target.value})}
                value={formData.description}
              ></textarea>

            </div>

            {/* ================================================ */}
            {/*          Project Owner(s)                        */}
            {/*  1. Allows GA's to add existing firestore users  */}
            {/*  2. Validates new users with a form              */}
            {/*  3. Saves results to useReducer                  */}
            {/* ================================================ */}

            {
              // ========================================================
              // All 'newOwnerStatus' states ---> 'EXCLUDING new-users'!
              // ========================================================

              formData.newOwnerStatus !== 'new-user' ? (

                <div className='CreateProject-Form-Row'>

                  <label><b style={{fontWeight: "500"}}>Project Owner(s) *</b> </label>

                    {/* ======================================= */}
                    {/*    Email Address Input Field            */}
                    {/* ======================================= */}

                    <div className='flex flex-row gap-2 items-center mb-2'>
                      <input
                        className={formData.owners.length === 0 ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                        type='text'
                        placeholder='Email address'
                        onChange={(e) => handleChangeOwner(e.target.value)}
                        value={formData.newOwner}
                        autoComplete='off'
                      ></input>

                      {/* ======================================= */}
                      {/*    Toggle Add Icon                      */}
                      {/*  1. Enabled                             */}
                      {/*  2. Disabled                            */}
                      {/* ======================================= */}

                      {
                        // 1. Enabled 
                        formData.newOwnerAllowAdd === true ? (

                          <img
                            style={{marginLeft: '10px'}} 
                            className='Input-Icon'
                            src={IconAddTeal} 
                            alt='add-icon'
                            onClick={() => handleAddOwner()}
                          ></img>

                        ): 
                        
                        // 2. Disabled
                        (
                          
                          <img
                            style={{marginLeft: '10px'}} 
                            className='Input-Icon'
                            disabled={true}
                            src={IconAddGrey} 
                            alt='add-icon-disabled'
                          ></img>

                        )
                      }

                    </div>


                  {/* ======================================= */}
                  {/*    1. List of all Owners                */}
                  {/*    2. Error messages                    */}
                  {/* ======================================= */}

                  <div className='CreateProject-Form-ProjectOwners'>

                    <span className='Input-Field-Text-Error-Message'>{formData.newOwnerMessage}</span>

                    {

                      formData?.owners?.map((object, index) => (

                        <div key={index} style={{display: 'flex'}}>
                          <label className='Label-List-Item'>{object.emailaddress}</label>
                          <img 
                            style={{marginLeft: '10px'}} 
                            className='Input-Icon' 
                            src={IconDeleteRed} 
                            alt='delete-icon' 
                            onClick={() => {

                            formData.owners.splice(index, 1);
                            setFormData(formData);

                          }}></img>
                        </div>

                      ))

                    }
                  </div>

                </div>

              ) :
              
              // ========================================================
              //  New Users Form ---> formData.newOwnerStatus === 'new-users'
              // ========================================================

              (

                <div className='CreateProject-Form-Row'>

                  <label><b style={{fontWeight: "500"}}>Project Owner(s) *</b> </label>
                  <div className='New-User-Form'>

                    {/* <-------------------HEADER--------------------> */}
                    <div className='New-User-Header'>
                      <img src={IconInfoRequired} alt='Info-Required-Icon' width='25px'></img>
                      MORE INFORMATION REQUIRED
                    </div>

                    {/* <-----------------EMAIL ADDRESS---------------> */}
                    <div className='New-User-Email'>
                      <p>Email Address</p>
                      <input
                        className='Input-Field-Text'
                        type='text'
                        value={formData.newOwner}
                        disabled
                        autoComplete='off'
                      ></input>
                    </div>

                    {/* <-----------------USER NAME------------------> */}
                    <div className='New-User-Name'>

                      <div>
                        <p>Given Name</p>
                        <input
                          className='Input-Field-Text'
                          type='text'
                          placeholder='Sally'
                          value={formData.newOwnerGivenname}
                          onChange={(e) => setFormData({'newOwnerGivenname': e.target.value})}
                        ></input>
                      </div>

                      <div>
                        <p>Surname</p>
                        <input
                          className='Input-Field-Text'
                          type='text'
                          placeholder='Smith'
                          value={formData.newOwnerSurname}
                          onChange={(e) => setFormData({'newOwnerSurname': e.target.value})}
                        ></input>
                      </div>

                    </div>

                    {/* <-----------------ADD BUTTON---------------> */}
                    <div className='New-User-Buttons'>

                      {/* Disable '+ Add User' button if fields are empty */}
                      <button 
                        className='Primary-Button'
                        style={{fontSize: '13px'}}
                        disabled = {
                          formData.newOwnerGivenname.length > 0 &&
                          formData.newOwnerSurname.length > 0 ? (
                            false
                          ): (
                            true
                          )
                        }
                        onClick={() => {
                          formData.owners.push({
                            'emailaddress': formData.newOwner,
                            'type': 'new',
                            'givenname': formData.newOwnerGivenname.trim(),
                            'surname': formData.newOwnerSurname.trim(),
                          });
                          formData.newOwnerStatus = 'onload';
                          formData.newOwner = '';
                          formData.newOwnerAllowAdd = false;
                          formData.newOwnerGivenname = '';
                          formData.newOwnerSurname = '';
                          setFormData(formData);
                      }}
                      >
                        + Add User
                      </button>

                      {/* Cancel Button */}
                      <button 
                        className='Secondary-Button'
                        style={{fontSize: '13px'}}
                        onClick={() => {
                          formData.newOwnerStatus = 'onload';
                          formData.newOwner = '';
                          formData.newOwnerGivenname = '';
                          formData.newOwnerSurname = '';
                          setFormData(formData);
                        }}
                      >
                        Cancel
                      </button>

                    </div>

                  </div>

                </div>
              
              )
            }

            {/* ===================================== */}
            {/*          Location                     */}
            {/* ===================================== */}

            <div className='CreateProject-Form-Row'>

              <label><b style={{fontWeight: "500"}}>Location *</b> </label>
              <select 
                className={formData.locationValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                onChange={(e) => setFormData({'location': e.target.value})}
                value={formData.location}
              >
                <option hidden value=''>-</option>
                <option value='NSW'>NSW</option>
                <option value='VIC'>VIC</option>
                <option value='TAS'>TAS</option>
                <option value='QLD'>QLD</option>
                <option value='WA'>WA</option>
                <option value='NT'>NT</option>
                <option value='SA'>SA</option>
                <option value='ACT'>ACT</option>
                <option value='NATIONAL'>NATIONAL</option>
              </select>

            </div>

            {/* ===================================== */}
            {/*        Nominated Pass Office          */}
            {/* ===================================== */}

            <div style={{display: 'flex'}} className='CreateProject-Form-Row'>

              <label><b style={{fontWeight: "500"}}>Nominated Pass Office *</b> </label>

              <div>

                <select 
                  className={formData.passOfficeNameValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                  onChange={(e) => setFormData({'passOfficeState': e.target.value})}
                  value={formData.passOfficeState}
                  onClick={(e) => {

                    if (e.target.value.length < 2) return;

                    // Filter the Office selector by the selected state
                    const state = formData.passOfficeData.filter((object) => object.state === e.target.value);

                    setFormData({
                      'passOfficeNameFilter': state[0].offices,
                      'passOfficeName': '',
                      'passOfficeNew': '',
                    });

                  }}
                >
                  <option hidden value=''>-</option>
                  {/* Map each state */}
                  {
                    formData.passOfficeData.map((object, index) => (

                      <option 
                        key={index} 
                        value={object.state} 
                      >
                        {object.state}
                      </option>

                    ))
                  }
                </select>
                
                {/* Map each office from the selected state */}
                <select
                  style={{marginLeft: '10px'}}
                  className={formData.passOfficeNameValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                  onChange={(e) => setFormData({'passOfficeName': e.target.value})}
                  value={formData.passOfficeName}
                >
                  <option value='' hidden>-</option>
                  {
                    formData.passOfficeNameFilter?.map((office, index) => (

                      <option key={index} value={office}>{office}</option>

                    ))
                  }
                </select>

                <div>
                  <label style={{color: '#545454'}}>If you require an alternate pass office, please enter the name of the pass office below.</label>
                  <div>
                    <input
                      style={{marginBottom: '15px'}}
                      className={formData.passOfficeNewValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                      type='text'
                      placeholder='Enter pass office name'
                      onChange={(e) => setFormData({'passOfficeNew': e.target.value})}
                      value={formData.passOfficeNew}
                    ></input>
                  </div>

                </div>

              </div>

            </div>

            {/* ===================================== */}
            {/*           Data Location               */}
            {/* ===================================== */}

            <div className='CreateProject-Form-Row'>

              <label><b style={{fontWeight: "500"}}>Data Location *</b> </label>

              <select 
                className='Input-Field-Select'
                onChange={(e) => setFormData({'dataLocation': e.target.value})}
                value={formData.dataLocation}
              >
                <option value='Australia'>Australia</option>
              </select>
            
            </div>


            {/* ===================================== */}
            {/*        Non-Disclosure Agreement       */}
            {/* ===================================== */}

            <div className='CreateProject-Form-Row'>

              <label><b style={{fontWeight: "500"}}> Non-Disclosure Agreement (Optional) </b> </label>

              <div>
                <p style={{color: '#545454'}}>If you require your workers to sign a non-disclosure agreement for this project, upload a copy of it below.</p>
                
                <UploadNewNDA
                  formData={formData}
                  setFormData={setFormData}
                ></UploadNewNDA>

              </div>


            </div>

            {/* ===================================== */}
            {/*        Buttons                        */}
            {/* ===================================== */}

            <div>

              <button style={{marginTop: '30px'}} className='Primary-Button' type='submit' value='submit'>Submit</button>
              <button 
                style={{marginLeft: '10px', marginTop: '30px'}} 
                className='Secondary-Button' 
                onClick={() => navigate('/home')}
              >Cancel</button>

            </div>

          </form>
          
        </div>
      }
    ></PageComponent> 
  )
}
