//================================================================
//  Page: Worker 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React, { useContext, useState, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

//Contexts
import { GetProjectSelector, GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import TabView from '../../../Components/TabView/TabView';
import ApplicationSendforApprovalModal from '../../../../src/Components/ApplicationApprovalsModals/ApplicationSendforApprovalModal';
import ApplicationRequestMoreInformationModal from '../../../Components/ApplicationApprovalsModals/ApplicationRequestMoreInformationModal';
import ApplicationRejectApplicationModal from '../../../Components/ApplicationApprovalsModals/ApplicationRejectApplicationModal';
import ApplicationApproveApplicationModal from '../../../Components/ApplicationApprovalsModals/ApplicationApproveApplicationModal';
import WorkerProfile from '../Pages/WorkerProfile';
import PrequalificationHistory from './PrequalificationHistory';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import PrequalificationForm from '../Pages/PrequalificationForm';

//Functions
import getDocument from '../../../Library/GetDocument';
import documentListener from '../../../Library/DocumentListener';
import ConvertDate from '../../../Library/ConvertDate';
import CheckUserRole from '../../../Library/checkUserRole';
import QueryDocument from '../../../Library/QueryDocument';

//Images
import ApproveButton from '../../../Components/Images/Approve_Button.svg';
import RejectButton from '../../../Components/Images/Reject_Button.svg';

//CSS
import '../PreQualifications.css';


export default function Worker() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const navigate = useNavigate();
  
  const [searchParms] = useSearchParams();
  const projectid = searchParms.get('project');
  const emailaddress = searchParms.get('emailaddress');
  const currentUser = useContext(GetUser);

  const getProjectSelector = useContext(GetProjectSelector);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-other', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Holds the signed URLs for all uploads
  const [signedUrls, setSignedUrls] = useState({
    'nda': [],
    'existingDCAC': [],
    'agsva': [],
    'auscit': [],
    'npc': [],
    'comments': []
  });

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store prequalification context
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'project': '',
      'worker': undefined,
      'profileUploadsFiles': [],
      'prequalificationDoc': undefined
    }
  );

  // Used to toggle visibility of modals
  const [modalVisibility, setModalVisibility] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'approveModalVisible': false,
      'sendForApprovalModalVisible': false,
      'rejectModalVisible': false,
      'requestMoreInfoVisible': false
    }
  );

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // onChange
  //  1. Get worker & project from searchParms
  //  2. Set up document listener for the worker's prequalification doc
  useEffect(() => {

    // Conditionals
    if (projectid === null || projectid === undefined) return setPageStatus('accessdenied');
    if (emailaddress === undefined || emailaddress === undefined) return setPageStatus('accessdenied');
    if (getProjectSelector.projects.length === 0) return setPageStatus('accessdenied');

    // 1. Set up document listener for the worker's prequalification doc
    function onLoad(preDoc) {

      // 2. Check the user exists
      getDocument('users', emailaddress).then((document) => {

        // Ensure the projectid exists in context ('projects' collection)
        const projectObject = getProjectSelector.projects.filter((object) => object.projectid === projectid)[0];

        if (projectObject === undefined) return setPageStatus('accessdenied');

        // ----------------------------------------------
        //   Get the NDA Signed URL
        // ----------------------------------------------

        const ndaPromise = QueryDocument('files', [
          ['projectid', '==', projectid],
          ['requesttype', '==', 'nondisclosure'],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Existing DCAC Uploads
        // ----------------------------------------------

        const existingDCACPromise = QueryDocument('files', [
          ['workeremail', '==', document.emailaddress],
          ['requesttype', '==', 'existing-dcac'],
          ['projectid', '==', projectid],
          ['organisationid', '==', document.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get AGSVA Uploads
        // ----------------------------------------------

        const agsvaPromise = QueryDocument('files', [
          ['workeremail', '==', document.emailaddress],
          ['requesttype', '==', 'security-clearance'],
          ['projectid', '==', projectid],
          ['organisationid', '==', document.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Aust Citizen Uploads
        // ----------------------------------------------

        const ausCitPromise = QueryDocument('files', [
          ['workeremail', '==', document.emailaddress],
          ['requesttype', '==', 'aus-citizen-uploads'],
          ['projectid', '==', projectid],
          ['organisationid', '==', document.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Non-Disclosable Outcome Uploads
        // ----------------------------------------------

        const npcPromise = QueryDocument('files', [
          ['workeremail', '==', document.emailaddress],
          ['requesttype', '==', 'nondisclosable-outcomes'],
          ['organisationid', '==', document.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Comments Uploads
        // ----------------------------------------------

        const commentsPromise = QueryDocument('files', [
          ['workeremail', '==', document.emailaddress],
          ['requesttype', '==', 'additional-information'],
          ['projectid', '==', projectid],
          ['organisationid', '==', document.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Resolve Promises
        // ----------------------------------------------
        
        // Get the Prequalification Document
        return Promise.all([ndaPromise, existingDCACPromise, agsvaPromise, ausCitPromise, npcPromise, commentsPromise])
        .then((results) => {

          const ndaDoc = results[0];
          const existingDCACDoc = results[1];
          const agsvaDoc = results[2];
          const ausCitDoc = results[3];
          const npcDoc = results[4];
          const commentsDoc = results[5];
          
          // Save all the signed URLs
          signedUrls.nda.push(ndaDoc);
          signedUrls.existingDCAC = existingDCACDoc;
          signedUrls.agsva = agsvaDoc;
          signedUrls.auscit = ausCitDoc;
          signedUrls.npc = npcDoc;
          signedUrls.comments = commentsDoc;

          setSignedUrls(signedUrls);
          setFormData({
            'prequalificationDoc': preDoc,
            'project': projectObject,
            'worker': document
          });

          setPageStatus('onload');
    
        })
        .catch(() => {
          setPageStatus('error-fatal');

        })

      })
      .catch(() => {

        setPageStatus('accessdenied');

      });

    }

    // Handle changes in the prequalification document
    function onChange(document) {

      setFormData({ 'prequalificationDoc': document });

    }

    // Handle errors
    function onError(error) {

      setPageStatus('error-fatal');

    }

    const unsubscribe = documentListener('prequalifications', `${emailaddress}-${projectid}`, onLoad, onChange, onError);

    // Remove document listener if the component 'unMounts'
    return () => {

      unsubscribe();

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectSelector, emailaddress]);
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requiredRoles={['lendleaseUser', 'globalAdmin']}
      requiredRolesValues={[true]}
      status={pageStatus}
      header={
        <div className='Worker-Header-Container'>
          
          {/* Page Header */}
          <div className='PreQualifications-Header'>
            <h5>Pre-Qualifications</h5>
            <button className='Primary-Button' onClick={() => navigate(`/prequalifications?project=${projectid}`)}>Back</button>
          </div>

          {/* Worker Profile (Header) */}
          <div className='Worker-Header'>
            <h4>{`${formData?.worker?.givenname} ${formData?.worker?.surname}`}</h4>
            <p>{formData?.worker?.organisationname}</p>
            <StatusLabel prequalification={formData.prequalificationDoc}></StatusLabel>
          </div>

          {/* Prequalification Status Label */}
          {
            [
              { 'name': 'In Review', 'description': `This application was submitted on ${ConvertDate(formData.prequalificationDoc?.audithistory.filter((item) => item.type === 'Prequalification Submitted')[0]?.actiondate)} and is currently being reviewed for approval.`, 'background': '#EBF4FF', 'status': 'review', 'color': '#194A59'},
              { 'name': 'Requires More Information', 'description': 'This application requires more information, refer to Prequalification History below to determine what information is required.', 'background': '#FFF0D2', 'status': 'moreinfo', 'color': '#E47525'},
              { 'name': 'Pending Security Office Approval', 'description': 'This application was sent to a security officer for approval.', 'background': '#FFF5D2', 'status': 'pendingapproval', 'color': '#E4BA25'},
              { 'name': 'Approved', 'description': `This application was approved on ${ConvertDate(formData.prequalificationDoc?.audithistory.filter((item) => item.type === 'Approved')[0]?.actiondate)}. The DCAC will expire on ${formData.prequalificationDoc?.expirydate}.`, 'background': '#EAF3DA', 'status': 'approved', 'color': '#61B280'},
              { 'name': 'Rejected', 'description': `This application was rejected on ${ConvertDate(formData.prequalificationDoc?.audithistory.filter((item) => item.type === 'Rejected')[0]?.actiondate)}. View Prequalification History below to see the reason for rejection.`, 'background': '#FFE9E9', 'status': 'rejected', 'color': '#D85C5C'},
              { 'name': 'Expired', 'description': `This application expired on ${formData.prequalificationDoc?.expirydate}.`, 'background': '#F0F0F0', 'status': 'expired', 'color': '#C0C0C0'}
            ]
            .filter((style) => style.status === formData.prequalificationDoc?.status)
            .map((object, index) => (

              <div className='Prequalification-Status-Label' key={index} style={{ backgroundColor: object.background, borderLeft: `5px solid ${object.color}` }}>
                <h6>{object.name.toUpperCase()}</h6>
                <p >{object.description}</p>
              </div>

            ))
          }

        </div>
      }
      body={
        <div>

          {/* ------------------------------------------ */}
          {/*  Approve/Reject Buttons                    */}
          {/* ------------------------------------------ */}

          <div className='PreQualifications-Approve-Reject'>
            {
                
              (formData.prequalificationDoc?.status === 'review' || formData.prequalificationDoc?.status === 'pendingapproval') && 
                <>
                  {/* Approve Dropdown  */}

                  <div className='PreQualifications-DropDown'>

                    {/* Approve Button */}
                    <img className='PreQualifications-DropBtn' src={ApproveButton} alt='Approve'></img>

                    {/* Dropdown Menu */}
                    <div className='PreQualifications-DropDown-content'>
                      <a onClick={() => setModalVisibility({ 'sendForApprovalModalVisible': true })} href='#SendforSecurityOfficeApproval'>
                        Send for Security Officer for Approval
                      </a>
                      {
                        CheckUserRole(['globalAdmin'], currentUser?.roles, true) &&
                        <a onClick={() => setModalVisibility({ 'approveModalVisible': true })} href='#ApproveApplication'>
                          Approve Application
                        </a>
                      }
                    </div>
                  </div>

                  {/* Reject Dropdown */}
                  <div className='PreQualifications-DropDown'>

                    {/* Reject Button */}
                    <img className='PreQualifications-DropBtn' src={RejectButton} alt='Reject'></img>

                    {/* Dropdown Menu */}
                    <div className='PreQualifications-DropDown-content'>
                      <a onClick={() => setModalVisibility({ 'requestMoreInfoVisible': true })} href='#RequestMoreInformation'>
                        Request More Information
                      </a>
                      <a onClick={() => setModalVisibility({ 'rejectModalVisible': true })} href='#RejectApplication'>
                        Reject Application
                      </a>
                    </div>
                  </div>

                </>
            }
          </div>

          {/* ------------------------------------------ */}
          {/*  Modals for Application Approval           */}
          {/* ------------------------------------------ */}

          <ApplicationSendforApprovalModal
            modalVisibility={modalVisibility.sendForApprovalModalVisible}
            setModalVisibility={setModalVisibility}
            prequalificationDoc={formData.prequalificationDoc}
            currentUser={currentUser}
          ></ApplicationSendforApprovalModal>

          <ApplicationRequestMoreInformationModal
            modalVisibility={modalVisibility.requestMoreInfoVisible}
            setModalVisibility={setModalVisibility}
            prequalificationDoc={formData.prequalificationDoc}
            currentUser={currentUser}
          ></ApplicationRequestMoreInformationModal>

          <ApplicationRejectApplicationModal
            modalVisibility={modalVisibility.rejectModalVisible}
            setModalVisibility={setModalVisibility}
            prequalificationDoc={formData.prequalificationDoc}
            currentUser={currentUser}
          ></ApplicationRejectApplicationModal>

          {/* This modal is only available for global admin users */}
          {
            CheckUserRole(['globalAdmin'], currentUser?.roles, true) &&
            <ApplicationApproveApplicationModal
              modalVisibility={modalVisibility.approveModalVisible}
              setModalVisibility={setModalVisibility}
              prequalificationDoc={formData.prequalificationDoc}
              currentUser={currentUser}
            ></ApplicationApproveApplicationModal>
          }

          {/* ------------------------------------------ */}
          {/*   Tabular View                             */}
          {/* ------------------------------------------ */}

          <TabView
            defaultView={1}
            oneTitle={'Profile'}
            oneContent={
              <WorkerProfile
                formData={formData}
                setFormData={setFormData}
                setPageStatus={setPageStatus}
              ></WorkerProfile>
            }
            oneRoute={`/prequalifications/worker?project=${projectid}&emailaddress=${emailaddress}&view=profile`}
            twoTitle={'Application'}
            twoContent={
              <PrequalificationForm
                currentUser={formData?.worker}
                prequalification={formData?.prequalificationDoc}
                setPageStatus={setPageStatus}
                projectDoc={formData?.project}
                signedUrls={signedUrls}
              ></PrequalificationForm>
            }
            twoRoute={`/prequalifications/worker?project=${projectid}&emailaddress=${emailaddress}&view=application`}
            threeTitle={'History'}
            threeContent={
              <PrequalificationHistory
                formData={formData}
              ></PrequalificationHistory>
            }
            threeRoute={`/prequalifications/worker?project=${projectid}&emailaddress=${emailaddress}&view=history`}
          ></TabView>

        </div>
      }
    ></PageComponent>
  )
}