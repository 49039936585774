//================================================================
//  Component: Workers.js
//================================================================

//  Purpose: Management of workers across all orgs & projects

//  Properties:

//  Example:
//    <Workers></Workers>    

//================================================================

//Libraries
import React, { useContext, useEffect, useReducer } from 'react';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Functions
import QueryDocument from '../../../../Library/QueryDocument';
import GetDocument from '../../../../Library/GetDocument';

//Components
import InviteWorkerModal from './InviteWorkerModal';
import EditWorkerProjectsModal from './EditWorkerProjectsModal';
import RemoveWorkerModal from './RemoveWorkerModal';

//Images
import EditIcon from '../../../../Components/Images/Icon_Edit_Grey.svg'
import DeleteIcon from '../../../../Components/Images/Icon_Delete_Red.svg';
import DeleteIconDisabled from '../../../../Components/Images/Icon_Delete_Grey.svg';
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';

//CSS
import '../../AppSettings.css';
import QueryListener from '../../../../Library/QueryListener';


export default function Workers() {

    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to save the form inputs (email address, given name and surname)
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            // Used to determine the state of the modal > 'pending', 'onload', 'error-fatal'
            'pageStatus': 'pending',

            // Holds all users and their org & assigned projects
            'allUsers': [],

            // List of all the projects
            'allProjects': [],

            // Invite Modal
            'inviteModalVisible': false,

            // Edit Modal
            'editModalWorker': undefined, // Undefined object will hide the Edit Modal

            // Delete Modal
            'removeModalWorker': undefined, // Undefined object will hide the Delete Modal
        }
    );

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onLoad
    // 1.  Get all organisations, it's workers, their user docs and their projects
    useEffect(() => {

        if (formData.pageStatus !== 'pending') return;
        
        function onLoadChange(organisations){

            // Get all active projects
            const projectPromises = QueryDocument('projects', [['status', '==', 'active']]);

            const userOrgPromises = [];
        
            // Get all the worker's user documents
            organisations.forEach((org) => {

                // Skip organisations without workers
                if (org.workers?.length === 0) return;

                org.workers.forEach((worker) => {
                    userOrgPromises.push(
                        GetDocument('users', worker, true)
                    );

                })

            });

            //------------------------------------------------------
            //  Settle NESTED promised for Orgs & Projects
            //------------------------------------------------------

            Promise.all(userOrgPromises).then((users) => {

                return projectPromises.then((projects) => {

                    //------------------------------------------------------
                    //  Extract User & Project Information
                    //------------------------------------------------------

                    const results = [];
    
                    users.forEach((user) => {
    
                        // Skip users without an organisationid
                        if (user?.organisationid.length === 0) return;
    
                        // Skip inactive users
                        if (user?.status !== 'active') return;

                        const projectResults = [];
                        user?.projects?.forEach((projectid) => {

                            const project = projects.filter((object) => object.projectid === projectid)[0];

                            // Skip inactive projects
                            if (project?.status !== 'active') return;
    
                            projectResults.push({
                                'projectid': project.projectid,
                                'projectname': project.projectname,
                            });
    
                        });

                        // Is the user a worker? 
                        results.push({
                            'givenname': user.givenname,
                            'surname': user.surname,
                            'emailaddress': user.emailaddress,
                            'organisationid': user.organisationid,
                            'organisationname': user.organisationname,
                            'projects': projectResults,
                            'role': user.orgAdmin.length > 0 ? 'Administrator' : 'Worker',
                            'projectAdmin': user.projectAdmin,
                            'globalAdmin': user.roles?.globalAdmin
                        });
                    });
    
                    // Save results to user reducer
                    setFormData({
                        'pageStatus': 'onload',
                        'allUsers': results,
                        'allProjects': projects,
                    });
    
                });

            }).catch((error) => {

                console.log(error);
                setFormData({ 'pageStatus' : 'error-fatal' });
            });   

        }

        function onError(error){

            console.log(error);
            setFormData({ 'pageStatus' : 'error-fatal' });

        }

        const unsubscribe = QueryListener('organisations', [['status', '==', 'active']], onLoadChange, onLoadChange, onError);

        return () =>{
            unsubscribe();
        };

    }, [formData]);


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

        //====================================================
        //  Pending
        //====================================================
        
        if (formData.pageStatus === 'pending') {
            return (
                <div className='Page-Body-Container'>
                    <div className='flex justify-center'>
                        <img alt='loading-circle-icon' src={LoadingIcon}></img>
                    </div>
                </div>
            )
        }

        //====================================================
        //  Error-Fatal
        //====================================================

        else if (formData.pageStatus === 'error-fatal') {

            return (
                <div className='Page-Body-Container'>
                    <div className='flex flex-col text-center gap-2 my-2'>
                        <h4>Oops something went wrong!</h4>
                        
                        <p className='leading-7 mb-2'>
                            An unknown error has occurred while loading this page.
                            <br></br>
                            If the error persists, please speak reach out to your support team. 
                        </p>
        
                        <div>
                            <button className='Primary-Button' onClick={() => window.location.reload() }>Try Again</button>
                        </div>
                    </div>
                </div>
            )
        }

        //====================================================
        //  Onload
        //====================================================

        else if (formData.pageStatus === 'onload') {
            return (
                <div className='Page-Body-Container'>
        
                    <div className='flex flex-row justify-between items-center mb-3'>
                        <label className='text-[18px] font-medium m-0'>Manage Workers</label>
                        <button className='Primary-Button' onClick={() => setFormData({'inviteModalVisible': true})} >+ Invite Worker</button>
                    </div>
        
                    {/* ======================================== */}
                    {/*           Manage Workers Table           */}
                    {/* ======================================== */}
        
                    {
                        formData.allUsers.length > 0 ?
                        (
                            <table className='Table-Container shadow-sm mb-4'>
                                <colgroup>
                                    <col span='1' style={{width: '25%'}}></col>
                                    <col span='1' style={{width: '20%'}}></col>
                                    <col span='1' style={{width: '20%'}}></col>
                                    <col span='1' style={{width: '20%'}}></col>
                                    <col span='1' style={{width: '15%'}}></col>
                                </colgroup>
                                <thead>
                                    <tr className='text-[14px] tracking-wider bg-slate-200'>
                                        <th>WORKER</th>
                                        <th>ORGANISATION</th>
                                        <th>ROLE</th>
                                        <th>PROJECTS</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData.allUsers?.map((worker, index) => (
                
                                            <tr key={index} className='h-[100px] cursor-auto border-b border-[#E0E0E0] break-all'>
                
                                                {/* WORKER */}
                                                <td>
                                                    <div className='flex flex-row font-medium capitalize'>
                                                        {worker?.givenname} {worker?.surname}
                                                    </div>
                                                    {worker?.emailaddress}
                                                </td>
                
                                                {/* ORGANISATION */}
                                                <td>
                                                    {worker?.organisationname}
                                                </td>
                                                
                                                {/* ROLE */}
                                                <td> {worker.role} </td>
                
                                                {/* PROJECTS */}
                                                <td>
                                                    {
                                                        worker.projects?.length === 0 ? 
                                                        (
                                                            <ul className='pl-0'>
                                                                <li>-</li>
                                                            </ul>                                                        
                                                        ) 
                                                        : worker.projects.length > 2 ?
                                                        (       
                                                            <ul className='my-0 pl-0'>
                                                                <li className='list-disc'>{worker.projects[0].projectname}</li>
                                                                <li className='list-disc'>{worker.projects[1].projectname}</li>
                                                                <li className='list-none font-[600] text-[#0972D3]'> ...</li>
                                                            </ul>
                                                        )
                                                        :
                                                        (
                                                            <ul className='pl-0'> 
                                                            {
                                                                worker?.projects?.map((project, index) => (
                                                                    <li className='list-disc' key={index}>
                                                                        {project.projectname}
                                                                    </li>
                                                                ))
                                                            }
                                                            </ul>                                                        
                                                        )
                
                                                    }
                                                </td>
                
                                                {/* BUTTON */}
                                                <td>
                                                    <div className='flex flex-row gap-2 justify-center align-middle'>
                                                        <img className='w-[25px] cursor-pointer' onClick={() => setFormData({'editModalWorker': worker})} src={EditIcon} alt='Edit-Icon'></img> 
                                                        <img className='w-[30px] cursor-pointer' onClick={() => setFormData({'removeModalWorker': worker})} src={worker.emailaddress === getUser?.emailaddress ? DeleteIconDisabled : DeleteIcon} alt='Delete-Icon'></img>
                                                    </div>
                                                </td>
                
                                            </tr>
                
                                        ))
                                    }
                                </tbody>
                            </table>
            
                        )
                        :
                        (
                            <div className='mb-2'>
                                No workers found.
                            </div>
                        )
                    }
        
                    {/* ======================================== */}
                    {/*           Invite Worker Modal            */}
                    {/* ======================================== */}
        
                    <InviteWorkerModal
                        modalState={formData}
                        setModalState={setFormData}
                    ></InviteWorkerModal>
        
                    {/* ======================================== */}
                    {/*           Edit Worker Modal              */}
                    {/* ======================================== */}
        
                    <EditWorkerProjectsModal
                        modalState={formData}
                        setModalState={setFormData}            
                    ></EditWorkerProjectsModal>
        
                    {/* ======================================== */}
                    {/*          Delete Worker Modal             */}
                    {/* ======================================== */}
            
                    <RemoveWorkerModal
                        formData={formData}
                        setFormData={setFormData}
                    ></RemoveWorkerModal>    
        
                </div>
            )
        }


}
